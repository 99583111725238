import React from 'react'
import { Edit as EditIcon } from '@mui/icons-material'
import { Button, ButtonProps, IconButton } from '@mui/material'

interface EditButtonProps
    extends Pick<
        ButtonProps,
        'sx' | 'onClick' | 'variant' | 'color' | 'size' | 'startIcon' | 'endIcon'
    > {
    text?: string
    onlyIcon?: boolean
    noIcon?: boolean
    mr?: number | string
    ml?: number | string
}

export const EditButton: React.FC<EditButtonProps> = props => {
    const { text, onlyIcon, noIcon, mr, ml, sx, onClick, variant, color } = props
    const size = props.size ?? 'small'
    if (onlyIcon) {
        return (
            <IconButton sx={{ mr, ml, ...sx }} color={color} size={size} onClick={onClick}>
                <EditIcon fontSize={size} />
            </IconButton>
        )
    }
    let startIcon = props.startIcon
    if (!startIcon && !props.endIcon && !noIcon) {
        startIcon = <EditIcon fontSize={size} />
    }
    return (
        <Button
            variant={variant}
            sx={{ mr, ml, ...sx }}
            color={color}
            size={size}
            onClick={onClick}
            startIcon={startIcon}
        >
            {text ?? 'Edit'}
        </Button>
    )
}
