import { UrlChanged } from '@a10base/frontend/message-bus/messages.js'
import { parseSearchParams, SearchParams } from '@a10base/frontend/util/url.js'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UrlState {
    pathname: string
    search: string
    historyState?: unknown
    searchParams: SearchParams
}

export const initialUrlState: UrlState = {
    pathname: location.pathname,
    search: location.search,
    historyState: undefined,
    searchParams: parseSearchParams(location.search),
}

const urlSlice = createSlice({
    name: 'url',
    initialState: initialUrlState,
    reducers: {
        updateUrl(state, action: PayloadAction<UrlChanged>) {
            const { pathname, search, historyState, searchParams } = action.payload
            state.pathname = pathname
            state.search = search
            state.historyState = historyState
            state.searchParams = searchParams
        },
    },
})

export const urlReducer = urlSlice.reducer

export const urlActions = urlSlice.actions
