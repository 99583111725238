import * as yup from 'yup'
import { objectStrict } from './common.js'

export const translate = objectStrict({
    content: yup.string().min(1).max(10000).required(),
    type: yup
        .string()
        .oneOf(['plain', 'plain-handlebar', 'html', 'html-handlebar', 'icu'])
        .required(),
    sourceLangCode: yup.string().length(2).optional(),
    targetLangCodes: yup.array(yup.string().length(2).required()).min(1).required(),
}).defined()

export const translateStaticHtmlFile = objectStrict({
    name: yup.string().min(1).max(200).required(),
    sourceLangCode: yup.string().length(2).required(),
    targetLangCode: yup.string().length(2).required(),
}).defined()

export const translateEmailTemplate = objectStrict({
    name: yup.string().min(1).max(200).required(),
    sourceLangCode: yup.string().length(2).required(),
    targetLangCode: yup.string().length(2).required(),
}).defined()

//export type Translate = yup.Asserts<typeof translate>

// export const createTranslationsForKey = objectStrict({
//     ns: yup.string().required(),
//     key: yup.string().required(),
//     contentType: yup
//         .string()
//         .oneOf(['plain', 'plain-handlebar', 'html', 'html-handlebar', 'icu'])
//         .required(),
//     lang: yup.string().length(2).optional(),
// }).defined()
// export type CreateTranslationsForKey = yup.Asserts<typeof createTranslationsForKey>

// export const updateTranslationContent = objectStrict({
//     id: id,
//     content: yup.string().max(30000),
// }).defined()
// export type UpdateTranslationContent = yup.Asserts<typeof updateTranslationContent>

// export const updateTranslationNotes = objectStrict({
//     id: id,
//     notes: yup.string().min(0).max(1024),
// }).defined()
// export type UpdateTranslationNotes = yup.Asserts<typeof updateTranslationNotes>

// export const translateKey = objectStrict({
//     ns: yup.string().required(),
//     key: yup.string().required(),
//     targetLang: yup.string().length(2).optional(),
// }).defined()
// export type TranslateKey = yup.Asserts<typeof translateKey>

// export const markTranslationReviewed = objectStrict({
//     id: id,
//     reviewed: yup.boolean().required(),
// }).defined()
// export type MarkTranslationReviewed = yup.Asserts<typeof markTranslationReviewed>

// export const startMassTranslation = objectStrict({
//     namespace: yup.string().required(),
//     targetLang: yup.string().length(2).required(),
// }).defined()
// export type StartMassTranslation = yup.Asserts<typeof startMassTranslation>

// export const clearTranslationsCache = objectStrict({
//     namespace: yup.string().required(),
//     lang: yup.string().length(2).optional(),
// }).defined()
// export type ClearTranslationsCache = yup.Asserts<typeof clearTranslationsCache>
