import type * as schema from 'zapatos/schema'

import { trpcBase } from '@a10base/frontend/util/index.js'
import {
    useDeleteItemBase,
    useDeleteItemsBase,
    UseItemOptionsRaw,
    useItemRaw,
    useItemsRaw,
    UseItemsRawOptions,
    useUpsertItemsBase,
} from '@a10base/frontend/hooks/item.js'

export function useItemAdmin<T extends schema.TableWithIdAndVersion>(
    table: T,
    id: number | undefined | null,
    options?: UseItemOptionsRaw
): [schema.JSONSelectableForTable<T> | undefined, boolean] {
    const [item, loading] = useItemRaw(table, id, trpcBase.admin.item.getItem.query, options)
    return [item as schema.JSONSelectableForTable<T> | undefined, loading]
}

export function useItemsAdmin<T extends schema.TableWithIdAndVersion>(
    table: T,
    filterString?: string,
    options?: UseItemsRawOptions
): [schema.JSONSelectableForTable<T>[], boolean] {
    const [items, loading] = useItemsRaw(
        table,
        trpcBase.admin.item.getItems.query,
        filterString,
        options
    )
    return [items as schema.JSONSelectableForTable<T>[], loading]
}

export const useUpsertItems = useUpsertItemsBase<schema.TableWithIdAndVersion>

export const useDeleteItem = useDeleteItemBase<schema.TableWithIdAndVersion>

export const useDeleteItems = useDeleteItemsBase<schema.TableWithIdAndVersion>
