import React from 'react'
import { Box, BoxProps } from '@mui/material'

interface TabPanelProps extends BoxProps {
    active: boolean
}

export function TabPanel(props: TabPanelProps) {
    const { active, children, ...boxProps } = props

    return (
        <Box role="tabpanel" hidden={!active} {...boxProps}>
            {active && children}
        </Box>
    )
}
