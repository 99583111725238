import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import {
    Preview as RenderIcon,
    Send as SendEmailIcon,
    Translate as TranslateIcon,
} from '@mui/icons-material'
import _ from 'lodash'

import {
    useAsyncFn,
    useAsyncFn1Param,
    useLoader,
    useStateLS,
} from '@a10base/frontend/hooks/index.js'

import {
    AsyncButton,
    BaseTableColumn,
    LanguageSelector,
    PageTitleRow,
    PopupMenu,
    PopupMenuRef,
    Spinner,
    Table,
    useTableState,
} from '@a10base/frontend/components/index.js'
import { CodeEditor } from '@a10base/frontend/components/CodeEditor.js'
import { trpcBase } from '@a10base/frontend/util/trpc.js'
import { navigateTo, serverData, showNotification } from '@a10base/frontend/util/index.js'
import { first, last } from '@a10base/common/misc.js'

export function EmailTemplatePage() {
    const [filePaths, loading, reload] = useLoader(trpcBase.admin.email.getTemplateFiles.query)
    const tableState = useTableState(50)

    const rows = useMemo(() => {
        const rows: EmailTemplate[] = []
        if (!filePaths) return rows
        const groupedByBasePath = _.groupBy(filePaths, v => {
            const i = v.lastIndexOf('/')
            return i === -1 ? '' : v.slice(0, i)
        })
        for (const [basePath, files] of Object.entries(groupedByBasePath)) {
            if (basePath === '') continue
            const subject = files.some(v => v.endsWith('.subject.txt'))
            const less = files.some(v => v.endsWith('.less'))
            const langs = files.filter(v => v.endsWith('.html')).map(v => v.slice(-7, -5))
            rows.push({ name: basePath, subject, less, langs })
        }
        rows.sort((a, b) => a.name.localeCompare(b.name))
        return rows
    }, [filePaths])

    const columns = useMemo<BaseTableColumn<EmailTemplate>[]>(() => {
        return [
            {
                id: 'name',
                header: 'Name',
                render: v => v.name,
            },
            {
                id: 'subject',
                header: 'Subject',
                render: v => (v.subject ? '✓' : ''),
            },
            {
                id: 'less',
                header: 'Less',
                render: v => (v.less ? '✓' : ''),
            },
            {
                id: 'langs',
                header: 'Languages',
                render: v => v.langs.join(', '),
            },
            {
                id: 'action',
                header: '',
                render: template => <ActionMenuButton template={template} onReload={reload} />,
            },
        ]
    }, [reload])

    return (
        <Stack direction="column" spacing={1} width="100%">
            <PageTitleRow title="Email templates" />
            <Table rows={rows} loadingRows={loading} columns={columns} {...tableState} />
        </Stack>
    )
}

interface EmailTemplate {
    name: string
    subject: boolean
    less: boolean
    langs: string[]
}

interface ActionMenuButtonProps {
    template: EmailTemplate
    onReload: () => void
}
function ActionMenuButton({ template, onReload }: ActionMenuButtonProps) {
    const popupRef = useRef<PopupMenuRef | null>(null)
    const [modal, setModal] = useState<'send' | 'translate' | undefined>(undefined)
    return (
        <>
            <PopupMenu ref={popupRef}>
                <MenuItem>
                    <Button
                        startIcon={<RenderIcon />}
                        onClick={() => navigateTo(template.name)}
                        disabled={template.name.includes('wrapper')}
                    >
                        Render
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button
                        onClick={() => setModal('send')}
                        startIcon={<SendEmailIcon />}
                        size="small"
                        disabled={template.name.includes('wrapper')}
                    >
                        Send test email
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button
                        startIcon={<TranslateIcon />}
                        onClick={() => setModal('translate')}
                        disabled={serverData.config.A10_ENV !== 'local'}
                    >
                        Translate
                    </Button>
                </MenuItem>
            </PopupMenu>
            {modal === 'translate' && (
                <TranslateModal
                    onClose={() => {
                        setModal(undefined)
                        onReload()
                        popupRef.current?.close()
                    }}
                    template={template}
                />
            )}
            {modal === 'send' && (
                <SendModal
                    template={template}
                    onClose={() => {
                        setModal(undefined)
                        onReload()
                        popupRef.current?.close()
                    }}
                />
            )}
        </>
    )
}

interface TranslateModalProps {
    onClose: () => void
    template: EmailTemplate
}
function TranslateModal({ onClose, template }: TranslateModalProps) {
    const [sourceLang, setSourceLang] = useStateLS<string | undefined>(
        'translate-source-lang',
        first(template.langs)
    )
    const [targetLang, setTargetLang] = useStateLS<string | undefined>(
        'translate-target-lang',
        last(template.langs)
    )
    const sourceLangs = serverData.languages.filter(v => template.langs.includes(v.code))

    const translate = useAsyncFn(async () => {
        if (sourceLang && targetLang && sourceLang !== targetLang) {
            await trpcBase.admin.translation.translateEmailTemplate.mutate({
                name: template.name,
                sourceLangCode: sourceLang,
                targetLangCode: targetLang,
            })
        }
    }, onClose)

    return (
        <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Translate "{template.name}"</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1} mt={3}>
                    <LanguageSelector
                        label="Source language"
                        value={sourceLang}
                        onChange={setSourceLang}
                        langs={sourceLangs}
                        showCode
                    />
                    <LanguageSelector
                        label="Target language"
                        value={targetLang}
                        onChange={setTargetLang}
                        showCode
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <AsyncButton
                    {...translate}
                    size="medium"
                    disabled={
                        sourceLang === undefined ||
                        targetLang === undefined ||
                        sourceLang === targetLang
                    }
                    startIcon={<TranslateIcon fontSize="medium" />}
                >
                    Translate
                </AsyncButton>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface SendModalProps {
    template: EmailTemplate
    onClose: () => void
}
const SendModal: React.FC<SendModalProps> = props => {
    const { template, onClose } = props
    const [exampleData, setExampleData] = useState<string>('{}')
    const [, loadingSampleJson] = useLoader(
        () => trpcBase.admin.email.getTemplateJsonSample.query({ name: template.name }),
        setExampleData,
        { cache: { secs: 600, key: `email-template-sample-${template.name}` } }
    )
    const [parsedExampleData, setParsedExampleData] = useState<unknown>({})
    const [lang, setLang] = useState<string>(serverData.config.CLIENT_LANG)
    const [to, setTo] = useStateLS<string>('email-template-send-to', '')

    const send = useAsyncFn1Param(
        trpcBase.admin.email.sendTestEmail.mutate,
        { name: template.name, lang, data: parsedExampleData as object, to },
        () => showNotification({ messageType: 'success', message: 'Sent' })
    )
    useEffect(() => {
        try {
            setParsedExampleData(JSON.parse(exampleData))
        } catch {
            setParsedExampleData(undefined)
        }
    }, [exampleData])

    return (
        <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Send test email</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2} alignItems="flex-start" mt={3}>
                    <FormControl sx={{ minWidth: '300px' }}>
                        <InputLabel id="receiver-selector-label">Receiver</InputLabel>
                        <Select
                            labelId="receiver-selector-label"
                            label="Receiver"
                            value={to}
                            onChange={e => setTo(e.target.value)}
                        >
                            {(serverData.admin?.safeTestEmails || [])
                                .concat(serverData.config.SUPPORT_EMAIL)
                                .map(v => (
                                    <MenuItem key={v} value={v}>
                                        {v}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <LanguageSelector value={lang} onChange={setLang} showCode />
                    <CodeEditor
                        value={exampleData}
                        onChange={setExampleData}
                        disabled={loadingSampleJson}
                        type="json"
                        height="200px"
                        label="Data"
                        invalid={parsedExampleData === undefined}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <AsyncButton
                    {...send}
                    size="medium"
                    disabled={parsedExampleData === undefined || !lang}
                    startIcon={<SendEmailIcon fontSize="medium" />}
                >
                    Send
                </AsyncButton>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface EmailTemplateRenderPageProps {
    templateName: string
}
export function EmailTemplateRenderPage({ templateName }: EmailTemplateRenderPageProps) {
    const [exampleData, setExampleData] = useStateLS<string>(
        `email-template-example-data:${templateName}`,
        '{}'
    )
    const [, loadingSampleJson] = useLoader(
        () => trpcBase.admin.email.getTemplateJsonSample.query({ name: templateName }),
        json => {
            setExampleData(json)
            try {
                setParsedExampleData(JSON.parse(json))
            } catch {
                // Do nothing
            }
        },
        { cache: { secs: 600, key: `email-template-example:${templateName}` } }
    )
    const [rendered, setRendered] = useState<{ html: string; subject: string } | undefined>(
        undefined
    )
    const [parsedExampleData, setParsedExampleData] = useState<unknown>({})
    const [lang, setLang] = useStateLS<string>('email-template-lang', serverData.config.CLIENT_LANG)

    useEffect(() => {
        try {
            setParsedExampleData(JSON.parse(exampleData))
        } catch {
            setParsedExampleData({})
        }
    }, [exampleData])

    const render = useAsyncFn1Param(
        trpcBase.admin.email.render.query,
        { name: templateName, lang, data: parsedExampleData as object },
        setRendered
    )

    // Initial render
    useEffect(() => {
        if (!rendered && templateName && lang && parsedExampleData && !loadingSampleJson) {
            render.callFn()
        }
    }, [rendered, render, loadingSampleJson, templateName, lang, parsedExampleData])

    return (
        <Stack direction="column" spacing={1} width="100%">
            <PageTitleRow title="Email template" subtitle={templateName} />
            <Stack direction="column" spacing={2} alignItems="flex-start" mt={3}>
                <LanguageSelector value={lang} onChange={setLang} showCode />
                <CodeEditor
                    value={exampleData}
                    onChange={setExampleData}
                    disabled={loadingSampleJson}
                    type="json"
                    height="100px"
                    label="Data"
                    invalid={parsedExampleData === undefined}
                />
                <AsyncButton
                    {...render}
                    variant="contained"
                    size="medium"
                    disabled={!lang || !templateName || !parsedExampleData || loadingSampleJson}
                    startIcon={<RenderIcon fontSize="small" />}
                >
                    Render
                </AsyncButton>
                <Spinner spinning={render.processing || loadingSampleJson} />
                {rendered && (
                    <Stack direction="column" spacing={2} mt={4} width="100%">
                        <Stack direction="row" spacing={2} mb={3}>
                            <Typography variant="body1">Subject:</Typography>
                            <Typography variant="body1" fontWeight="600">
                                {rendered.subject}
                            </Typography>
                        </Stack>
                        <iframe
                            title="render"
                            srcDoc={rendered.html}
                            style={{ width: '100%', height: '500px' }}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}
