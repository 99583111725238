import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'

import { formatISODateTime } from '@a10base/common/misc.js'
import { Stack, Typography } from '@mui/material'

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local

// Use formatDatetime for value and min/max

interface DatetimeLocalProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type' | 'value'> {
    value: string | undefined
    label?: string
    onChange: (value: string) => void // ISO datetime
}
export const DatetimeLocal: React.FC<DatetimeLocalProps> = props => {
    const { value, label, onChange, ...inputProps } = props
    const [input, setInput] = useState<string>(formatISODateTime(value))
    const [useFallback, setUseFallback] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        setInput(formatISODateTime(value))
    }, [value])

    useEffect(() => {
        if (inputRef.current) {
            if (inputRef.current.type !== 'datetime-local') {
                setUseFallback(true)
            }
        }
    }, [])

    const onChangeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.target.value
            setInput(inputValue)
            const d = new Date(inputValue)
            if (!Number.isNaN(d.getTime())) {
                onChange(d.toISOString())
            }
            // if (inputValue === '') {
            //     onChange('')
            // }
        },
        [onChange]
    )

    const inputStyle: CSSProperties = {
        padding: '8px',
        ...inputProps?.style,
    }

    return (
        <>
            {!useFallback && (
                <Stack direction="column">
                    {label && (
                        <Typography variant="body2" mb={0}>
                            {label}
                        </Typography>
                    )}
                    <input
                        {...inputProps}
                        style={inputStyle}
                        type="datetime-local"
                        value={input}
                        onChange={onChangeHandler}
                    />
                </Stack>
            )}
            {useFallback && (
                <input
                    {...inputProps}
                    style={inputStyle}
                    placeholder={formatISODateTime(new Date())}
                    type="text"
                    value={input}
                    onChange={onChangeHandler}
                />
            )}
        </>
    )
}
