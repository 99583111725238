import React from 'react'
import clsx from 'clsx'
import GaugeChart from 'react-gauge-chart'

import {
    formatInterestPercentRange,
    formatPaybackTime,
    scoringOptions,
} from '@a10yll/common/util/index.js'
import { ScoringInput } from '@a10yll/common/yup/scoring.js'
import {
    Button,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slider,
} from '@mui/material'

interface ScoringFormProps {
    input: ScoringInput
    score: number | undefined
    onOpenApplicationForm?: () => void
    onChange: (value: ScoringInput) => void
}

export const ScoringForm = (props: ScoringFormProps) => {
    const { input, score, onOpenApplicationForm, onChange } = props

    function updateInput(field: keyof ScoringInput, value: unknown) {
        onChange({ ...input, [field]: value })
    }

    return (
        <div className="scoring-form">
            <div className="column">
                <FieldWrapper label="Luottoluokitus" sublabel="Rating alfa">
                    <Select
                        fullWidth
                        value={input.credit_score}
                        onChange={e => updateInput('credit_score', Number(e.target.value))}
                    >
                        {input.credit_score === -1 && (
                            <MenuItem key={-1} value={-1}>
                                Valitse
                            </MenuItem>
                        )}
                        {scoringOptions.creditScore.map(v => (
                            <MenuItem key={v.value} value={v.value}>
                                {v.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FieldWrapper>
                <FieldWrapper label="Liikevaihto">
                    <Select
                        fullWidth
                        value={input.revenue}
                        onChange={e => updateInput('revenue', Number(e.target.value))}
                    >
                        {input.revenue === -1 && (
                            <MenuItem key={-1} value={-1}>
                                Valitse
                            </MenuItem>
                        )}
                        {scoringOptions.revenue.map(v => (
                            <MenuItem key={v.value} value={v.value}>
                                {v.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FieldWrapper>
                <FieldWrapper label="Rahoitustarve">
                    <Select
                        fullWidth
                        value={input.loan_amount}
                        onChange={e => updateInput('loan_amount', Number(e.target.value))}
                    >
                        {input.loan_amount === -1 && (
                            <MenuItem key={-1} value={-1}>
                                Valitse
                            </MenuItem>
                        )}
                        {scoringOptions.loanAmount.map(v => (
                            <MenuItem key={v.value} value={v.value}>
                                {v.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FieldWrapper>
            </div>

            <div className="column">
                <FieldWrapper label="Lainan vakuudet" sublabel="Esim. kiinteistö tai maa-alue">
                    <RadioGroup
                        value={input.collateral}
                        onChange={e => updateInput('collateral', Number(e.target.value))}
                    >
                        <FormControlLabel value={1} control={<Radio />} label="Haluan käyttää" />
                        <FormControlLabel value={0} control={<Radio />} label="En käytä" />
                    </RadioGroup>
                </FieldWrapper>

                <FieldWrapper label="Lainan korko- ja kulutaso">
                    <Slider
                        value={interestRateToStep(input.interest)}
                        step={1}
                        min={interestRateToStep(scoringOptions.interest[0].value)}
                        max={interestRateToStep(scoringOptions.interest.at(-1)!.value)}
                        onChange={(_event, value) =>
                            updateInput('interest', interestStepToRate(value as number))
                        }
                    />
                    <div className="slider-value">{formatInterestPercentRange(input.interest)}</div>
                </FieldWrapper>

                <FieldWrapper label="Takaisinmaksuaika">
                    <Slider
                        value={input.payback_time}
                        step={1}
                        min={scoringOptions.paybackTime[0].value}
                        max={scoringOptions.paybackTime.at(-1)!.value}
                        onChange={(_event, value) => updateInput('payback_time', value as number)}
                    />
                    <div className="slider-value">{formatPaybackTime(input.payback_time)}</div>
                </FieldWrapper>
            </div>

            <div className="column">
                <FieldWrapper label="Lainamahdollisuudet">
                    <ScoreIndicator score={score} onOpenApplicationForm={onOpenApplicationForm} />
                </FieldWrapper>
            </div>
        </div>
    )
}

interface FieldWrapperProps {
    label: string
    sublabel?: string
    children: React.ReactNode
    className?: string
}
function FieldWrapper(props: FieldWrapperProps) {
    return (
        <div className={clsx('field-wrapper', props.className)}>
            <div className="field-label">{props.label}</div>
            {props.sublabel && <div className="field-sublabel">{props.sublabel}</div>}
            {props.children}
        </div>
    )
}

interface ScoreIndicatorProps {
    score: number | undefined // 0 - 4
    onOpenApplicationForm?: () => void
}
const ScoreIndicator = React.memo(function ScoreIndicator(props: ScoreIndicatorProps) {
    const { score, onOpenApplicationForm } = props
    // const detailsRef = React.useRef<HTMLDivElement | null>(null)

    // useLayoutEffect(() => {
    //     let animations: Animation[] = []
    //     if (detailsRef.current && score !== undefined) {
    //         const details = detailsRef.current
    //         animations.push(
    //             details.animate([{ opacity: '0' }, { opacity: '1' }], {
    //                 duration: 500,
    //                 easing: 'ease-in-out',
    //             })
    //         )
    //     }
    //     return () => {
    //         for (const animation of animations) {
    //             animation.cancel()
    //         }
    //         animations = []
    //     }
    // }, [score])

    return (
        <div className="score-indicator">
            <GaugeChart.default
                id="gauge"
                nrOfLevels={5}
                percent={score === undefined ? 0.5 : score / 4}
                hideText
                colors={['#ff5353', '#49f649']}
                animDelay={0}
                arcWidth={0.3}
            />
            <div>
                <p className="text">{scoreText(score)}</p>
                {score !== undefined && score >= 1 && onOpenApplicationForm !== undefined && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onOpenApplicationForm}
                        sx={{ borderRadius: '30px', fontSize: '0.9rem', fontWeight: 'bold' }}
                    >
                        Lähetä lainahakemus ehdoillasi
                    </Button>
                )}
            </div>
        </div>
    )
})

function scoreText(score: number | undefined) {
    if (score === undefined) {
        return (
            <span>
                Anna kaikki tarvittavat taustatiedot, niin laskemme yrityksenne mahdollisuudet saada
                laina
            </span>
        )
    }
    switch (score) {
        case 0:
            return (
                <span>
                    Yrityksellänne <strong>ei ole mahdollisuuksia</strong> saada lainaa annetuilla
                    tiedoilla
                </span>
            )
        case 1:
            return (
                <span>
                    Yrityksellänne on <strong>pienet mahdollisuudet</strong> saada lainaa annetuilla
                    tiedoilla
                </span>
            )
        case 2:
            return (
                <span>
                    Yrityksellänne on <strong>kohtalaiset mahdollisuudet</strong> saada lainaa
                    annetuilla tiedoilla
                </span>
            )
        case 3:
            return (
                <span>
                    Yrityksellänne on <strong>hyvät mahdollisuudet</strong> saada lainaa annetuilla
                    tiedoilla
                </span>
            )
        case 4:
            return (
                <span>
                    Yrityksellänne on <strong>erinomaiset mahdollisuudet</strong> saada lainaa
                    annetuilla tiedoilla
                </span>
            )
        default:
            return (
                <span>
                    Anna kaikki tarvittavat taustatiedot, niin laskemme yrityksenne mahdollisuudet
                    saada laina
                </span>
            )
    }
}

export const scoreColor: Record<string, string> = {
    '-1': '#e5e7eb',
    '0': '#e83e38',
    '1': '#e8a238',
    '2': '#e8e838',
    '3': '#b6e838',
    '4': '#38e884',
}

function interestRateToStep(interest: number) {
    if (interest <= 10) {
        return 1
    }
    if (interest <= 20) {
        return 2
    }
    return 3
}

function interestStepToRate(step: number) {
    if (step === 1) {
        return 10
    }
    if (step === 2) {
        return 20
    }
    return 30
}
