import React, { Ref, useImperativeHandle, useMemo } from 'react'
import {
    ButtonBase,
    IconButton,
    IconButtonProps,
    Menu,
    MenuProps,
    CSSObject,
    ToggleButton,
} from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'

interface PopupMenuProps {
    children: React.ReactNode
    disabled?: boolean
    icon?: React.ReactNode
    menuProps?: Partial<MenuProps>
    iconButtonProps?: Partial<IconButtonProps>
}
export interface PopupMenuRef {
    close: () => void
}

export const PopupMenu = React.forwardRef(function PopupMenu(
    props: PopupMenuProps,
    ref: Ref<PopupMenuRef>
) {
    const { children, disabled, icon, menuProps, iconButtonProps } = props

    const popup = usePopupMenu()

    useImperativeHandle(
        ref,
        () => ({
            close: () => popup.closeMenu(),
        }),
        [popup]
    )

    return (
        <>
            <IconButton {...iconButtonProps} {...popup.triggerButtonProps} disabled={disabled}>
                {icon || <MoreHoriz fontSize="small" />}
            </IconButton>
            <Menu {...menuProps} {...popup.menuProps}>
                {children}
            </Menu>
        </>
    )
})

interface PopupMenuCustomProps {
    children: React.ReactNode
    disabled?: boolean
    buttonContent: React.ReactNode
    buttonSx?: CSSObject
    menuProps?: Partial<MenuProps>
}
export const PopupMenuCustom = React.forwardRef(function PopupMenu(
    props: PopupMenuCustomProps,
    ref: Ref<PopupMenuRef>
) {
    const { children, disabled, buttonContent, buttonSx, menuProps } = props

    const popup = usePopupMenu()

    useImperativeHandle(
        ref,
        () => ({
            close: () => popup.closeMenu(),
        }),
        [popup]
    )

    return (
        <>
            <ButtonBase
                {...popup.triggerButtonProps}
                disabled={disabled}
                sx={{
                    '&:hover': {
                        opacity: '0.8',
                    },
                    ...buttonSx,
                }}
            >
                {buttonContent}
            </ButtonBase>
            <Menu {...menuProps} {...popup.menuProps}>
                {children}
            </Menu>
        </>
    )
})

interface ToggleButtonPopupMenuProps {
    value?: string
    children: React.ReactNode
    disabled?: boolean
    buttonContent: React.ReactNode
    buttonSx?: CSSObject
    menuProps?: Partial<MenuProps>
}
export const ToggleButtonPopupMenu = React.forwardRef(function PopupMenu(
    props: ToggleButtonPopupMenuProps,
    ref: Ref<PopupMenuRef>
) {
    const { value, children, disabled, buttonContent, buttonSx, menuProps } = props

    const popup = usePopupMenu()

    useImperativeHandle(
        ref,
        () => ({
            close: () => popup.closeMenu(),
        }),
        [popup]
    )
    return (
        <>
            <ToggleButton
                value={value ?? ''}
                {...popup.triggerButtonProps}
                disabled={disabled}
                sx={buttonSx}
            >
                {buttonContent}
            </ToggleButton>
            <Menu {...menuProps} {...popup.menuProps}>
                {children}
            </Menu>
        </>
    )
})

export function usePopupMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    return useMemo(() => {
        const open = Boolean(anchorEl)
        const closeMenu = () => {
            setAnchorEl(null)
        }
        return {
            triggerButtonProps: {
                onClick: (event: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl(event.currentTarget)
                },
            },
            menuProps: {
                anchorEl,
                open,
                onClose: closeMenu,
            },
            closeMenu,
        }
    }, [anchorEl])
}
