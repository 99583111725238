import React, { useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { Sync as SyncIcon } from '@mui/icons-material'

import { useIsMountedRef } from '../hooks/index.js'

interface SpinnerProps {
    spinning: boolean | undefined
    text?: string
    fontSize?: 'inherit' | 'large' | 'medium' | 'small'
    ml?: number | string
    mr?: number | string
}

export const Spinner = React.memo(function spinner(props: SpinnerProps) {
    const { spinning, text, fontSize, mr, ml } = props

    if (!spinning) {
        return null
    }
    const spinner = <SyncIcon fontSize={fontSize} className="spinning" />

    if (text) {
        return (
            <Stack direction="row" spacing={1} alignItems="center" ml={ml} mr={mr}>
                {spinner}{' '}
                <Typography variant="body1" fontSize={fontSize} ml={1}>
                    {text}
                </Typography>
            </Stack>
        )
    }
    return spinner
})

interface DelayedSpinnerProps extends SpinnerProps {
    delayMs: number
}

export const DelayedSpinner: React.FC<DelayedSpinnerProps> = props => {
    const { delayMs, ...spinnerProps } = props
    const [delayOver, setDelayOver] = useState<boolean>(false)
    const isMounted = useIsMountedRef()

    useEffect(() => {
        if (spinnerProps.spinning) {
            setDelayOver(false)
            const timeout = setTimeout(() => isMounted.current && setDelayOver(true), delayMs)
            return () => clearTimeout(timeout)
        }
        return undefined
    }, [delayMs, isMounted, spinnerProps.spinning])

    if (!delayOver) {
        return null
    }

    return <Spinner {...spinnerProps} />
}
