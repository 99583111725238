import { parseFilename } from './misc.js'

export const documentExtensions = [
    'abw',
    'djvu',
    'doc',
    'docm',
    'docx',
    'dot',
    'dotx',
    'html',
    'hwp',
    'lwp',
    'md',
    'odt',
    'pages',
    'pdf',
    'rst',
    'rtf',
    'sdw',
    'tex',
    'txt',
    'wpd',
    'wps',
    'zabw',
    'azw',
    'azw3',
    'azw4',
    'cbc',
    'cbr',
    'cbz',
    'chm',
    'epub',
    'fb2',
    'htm',
    'htmlz',
    'lit',
    'lrf',
    'mobi',
    'pdb',
    'pml',
    'prc',
    'rb',
    'snb',
    'tcr',
    'txtz',
    'dps',
    'key',
    'odp',
    'pot',
    'potx',
    'pps',
    'ppsx',
    'ppt',
    'pptm',
    'pptx',
    'sda',
    'csv',
    'et',
    'numbers',
    'ods',
    'sdc',
    'xls',
    'xlsm',
    'xlsx',
    'dwg',
    'dxf',
    'ai',
    'cdr',
    'cgm',
    'emf',
    'sk',
    'sk1',
    'svg',
    'svgz',
    'vsd',
    'wmf',
]
export const documentExtensionsWithDot = documentExtensions.map(ext => `.${ext}`)

export const spreadSheetExtensions = ['csv', 'et', 'numbers', 'ods', 'sdc', 'xls', 'xlsm', 'xlsx']
export const spreadSheetExtensionsWithDot = spreadSheetExtensions.map(ext => `.${ext}`)

export const imageExtensions = [
    '3fr',
    'arw',
    'avif',
    'bmp',
    'cr2',
    'cr3',
    'crw',
    'dcr',
    'dng',
    'eps',
    'erf',
    'gif',
    'heic',
    'icns',
    'ico',
    'jpeg',
    'jpg',
    'mos',
    'mrw',
    'nef',
    'odd',
    'orf',
    'pef',
    'png',
    'ppm',
    'ps',
    'psd',
    'raf',
    'raw',
    'rw2',
    'tif',
    'tiff',
    'webp',
    'x3f',
    'xcf',
    'xps',
]
export const imageExtensionsWithDot = imageExtensions.map(ext => `.${ext}`)

export const videoExtensions = [
    '3g2',
    '3gp',
    '3gpp',
    'avi',
    'cavs',
    'dv',
    'dvr',
    'flv',
    'm2ts',
    'm4v',
    'mkv',
    'mod',
    'mov',
    'mp4',
    'mpeg',
    'mpg',
    'mts',
    'mxf',
    'ogg',
    'rm',
    'rmvb',
    'swf',
    'ts',
    'vob',
    'webm',
    'wmv',
    'wtv',
]
export const videoExtensionsWithDot = videoExtensions.map(ext => `.${ext}`)

export const audioExtensions = [
    'aac',
    'ac3',
    'aif',
    'aifc',
    'aiff',
    'amr',
    'au',
    'caf',
    'flac',
    'm4a',
    'm4b',
    'mp3',
    'oga',
    'sfark',
    'voc',
    'wav',
    'weba',
    'wma',
]
export const audioExtensionsWithDot = audioExtensions.map(ext => `.${ext}`)

export const pdfConvertableExtensionsWithDot =
    documentExtensionsWithDot.concat(imageExtensionsWithDot)

export const archiveExtensions = ['zip']

function filenameMatchExtension(
    fileNameWithExtension: string,
    extensionsWithoutDots: string[]
): boolean {
    const [, ext] = parseFilename(fileNameWithExtension)
    return fileExtensionMatch(ext, extensionsWithoutDots)
}
export function fileExtensionMatch(ext: string, extensionsWithoutDots: string[]): boolean {
    const extWithoutDot = (ext.startsWith('.') ? ext.substring(1) : ext).toLowerCase()
    return extensionsWithoutDots.some(e => e === extWithoutDot)
}

export function isDocumentFileName(fileNameWithExtension: string): boolean {
    return filenameMatchExtension(fileNameWithExtension, documentExtensions)
}
export function isDocumentFileExtension(extension: string): boolean {
    return fileExtensionMatch(extension, documentExtensions)
}

export function isSpreadSheetFileName(fileNameWithExtension: string): boolean {
    return filenameMatchExtension(fileNameWithExtension, spreadSheetExtensions)
}
export function isSpreadSheetFileExtension(extension: string): boolean {
    return fileExtensionMatch(extension, spreadSheetExtensions)
}

export function isImageFileName(fileNameWithExtension: string): boolean {
    return filenameMatchExtension(fileNameWithExtension, imageExtensions)
}
export function isImageFileExtension(extension: string): boolean {
    return fileExtensionMatch(extension, imageExtensions)
}

export function isVideoFileName(fileNameWithExtension: string): boolean {
    return filenameMatchExtension(fileNameWithExtension, videoExtensions)
}
export function isVideoFileExtension(extension: string): boolean {
    return fileExtensionMatch(extension, videoExtensions)
}

export function isAudioFileName(fileNameWithExtension: string): boolean {
    return filenameMatchExtension(fileNameWithExtension, audioExtensions)
}
export function isAudioFileExtension(extension: string): boolean {
    return fileExtensionMatch(extension, audioExtensions)
}

export function isArchiveFileExtension(extension: string): boolean {
    return fileExtensionMatch(extension, archiveExtensions)
}

export function isSupportedFileForConversion(extension: string): boolean {
    return (
        isDocumentFileExtension(extension) ||
        isImageFileExtension(extension) ||
        isVideoFileExtension(extension)
    )
}

export function isConvertibleToPdf(extension: string): boolean {
    return isDocumentFileExtension(extension) || isImageFileExtension(extension)
}
