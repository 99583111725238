import React, { useCallback, useEffect, useState } from 'react'
import {
    CSSObject,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    TextFieldProps,
} from '@mui/material'
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material'

import { isEnter } from '@a10base/frontend/util/misc.js'
import { Spinner } from './Spinner.js'

interface SearchBoxProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
    loading?: boolean
    showSearchButton?: boolean
    value?: string
    searchOnChange?: boolean
    searchOnBlur?: boolean
    sx?: CSSObject
    onChange?: (input: string) => void
    onSearch?: (input: string) => void
}

export const SearchBox: React.FC<SearchBoxProps> = props => {
    const {
        value,
        loading,
        searchOnBlur,
        searchOnChange,
        showSearchButton,
        sx,
        onChange,
        onSearch,
        ...textFieldProps
    } = props
    const [input, setInput] = useState<string>(value ?? '')

    useEffect(() => {
        setInput(value ?? '')
    }, [value])

    // const inputRef = useRef<HTMLInputElement | null>(null)

    const onClear = useCallback(() => {
        onChange?.('')
        onSearch?.('')
        setInput('')
    }, [onChange, onSearch])

    const onKeyUp = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (isEnter(event) && onSearch) {
                event.preventDefault()
                onSearch(input)
                // if (inputRef.current) {
                //     inputRef.current.focus()
                // }
            }
        },
        [onSearch, input]
    )

    const onChangeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value
            setInput(newValue)
            onChange?.(newValue)
            if (searchOnChange && onSearch) {
                onSearch(newValue)
            }
        },
        [onChange, onSearch, searchOnChange]
    )

    const onBlurHandler = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            if (searchOnBlur && onSearch) {
                onSearch(event.target.value)
            }
        },
        [onSearch, searchOnBlur]
    )

    return (
        <Stack direction="row" flexWrap="nowrap" alignItems="center" sx={sx}>
            <TextField
                {...textFieldProps}
                type="text"
                value={input}
                onChange={onChangeHandler}
                onKeyUp={onKeyUp}
                onBlur={onBlurHandler}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {!loading && input && (
                                <IconButton onClick={onClear} aria-label="clear">
                                    <ClearIcon />
                                </IconButton>
                            )}
                            {showSearchButton && onSearch && !loading && (
                                <IconButton onClick={() => onSearch(input)} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            )}
                            {loading && <Spinner spinning={true} fontSize="small" />}
                        </InputAdornment>
                    ),
                }}
            />
        </Stack>
    )
}
