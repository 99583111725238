import React from 'react'
import { Info as InfoIcon } from '@mui/icons-material'
import { IconButton, IconButtonProps, Popover } from '@mui/material'

// https://mui.com/material-ui/react-popover/

interface InfoButtonProps extends Pick<IconButtonProps, 'sx' | 'color' | 'size'> {
    children?: React.ReactNode
    icon?: React.ReactNode
    mr?: number | string
    ml?: number | string
}

export const InfoButton: React.FC<InfoButtonProps> = props => {
    const { children, icon, mr, ml, sx, color } = props
    const size = props.size ?? 'small'
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <IconButton onClick={handleClick} sx={{ mr, ml, ...sx }} color={color} size={size}>
                {icon ?? <InfoIcon fontSize={size} />}
            </IconButton>
            <Popover
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        </>
    )
}
