import { TRPCClientError } from '@trpc/client'

import { A10ErrorData, isA10Error } from '@a10base/common/a10-error.js'
import { getValue, hasValue } from '@a10base/common/misc.js'

export function getA10ErrorData(error: unknown): A10ErrorData | undefined {
    if (error instanceof TRPCClientError && hasValue(error.shape, 'a10ErrorData')) {
        return getValue(error.shape, 'a10ErrorData') as A10ErrorData
    } else if (isA10Error(error)) {
        return error.a10ErrorData
    }
    return undefined
}
