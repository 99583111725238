import { BaseJwtData } from './types/jwt.js'

// IMPORTANT: Role is just a bit mask.
// Larger value does NOT mean more permissions so you shouldn't EVER compare them with '<' or '>'.

// Bit masks for company user roles
// ********************************
// export const companyUserX = 0x0000_0001 // 1
// export const companyUserX = 0x0000_0002 // 2
// export const companyUserX = 0x0000_0004 // 4
// export const companyUserX = 0x0000_0008 // 8

// export const companyUserX = 0x0000_0010 // 16
// export const companyUserX = 0x0000_0020 // 32
// export const companyUserX = 0x0000_0040 // 64
// export const companyUserX = 0x0000_0080 // 128

// export const companyUserX = 0x0000_0100 // 256
// export const companyUserX = 0x0000_0200 // 512
// export const companyUserX = 0x0000_0400 // 1024
// export const companyUserX = 0x0000_0800 // 2048

// export const companyUserX = 0x0000_1000 // 4096
// export const companyUserX = 0x0000_2000 // 8192
// export const companyUserX = 0x0000_4000 // 16384
// export const companyUserX = 0x0000_8000 // 32768
// export const allCompanyUsers = 0x0000_ffff // 65535

// Bit masks for person roles
// ********************************
export const translatorRole = 0x0001_0000
// export const personRoleX = 0x0002_0000
// export const personRoleX = 0x0004_0000
// export const personRoleX = 0x0008_0000

// export const personRoleX = 0x0010_0000
// export const personRoleX = 0x0020_0000
// export const personRoleX = 0x0040_0000
// export const personRoleX = 0x0080_0000

// export const personRoleX = 0x0100_0000
// export const personRoleX = 0x0200_0000
// export const personRoleX = 0x0400_0000
// export const personRoleX = 0x0800_0000

// export const personRoleX = 0x1000_0000
// export const personRoleX = 0x2000_0000

export const a10Admin = 0x4000_0000 // 1073741824

export function isA10Admin(jwt: BaseJwtData | undefined): boolean {
    if (jwt === undefined) {
        return false
    }
    return jwt.pRole !== undefined && (jwt.pRole & a10Admin) > 0
}

export function isA10AdminRole(pRole: number | undefined): boolean {
    return pRole !== undefined && (pRole & a10Admin) > 0
}

export function roleIsOneOf(
    role: number | undefined | null,
    roles: number | undefined | null
): boolean {
    if (role === undefined || role === null || roles === undefined || roles === null) {
        return false
    }
    return (role & roles) > 0
}
