import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'

import {
    contextReducer,
    notificationReducer,
    urlReducer,
    itemReducer,
} from '@a10base/frontend/redux/reducers/index.js'
import { authReducer, presenceReducer } from './reducers/index.js'

// Documentation: https://redux-toolkit.js.org/tutorials/typescript

export const store = configureStore({
    reducer: {
        context: contextReducer,
        auth: authReducer,
        notification: notificationReducer,
        item: itemReducer,
        url: urlReducer,
        presence: presenceReducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppThunk = ThunkAction<void, RootState, any, Action<string>>
