/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction, Action } from '@reduxjs/toolkit'

import type * as schema from 'zapatos/schema'
import {
    deleteItemRaw,
    deleteItemsRaw,
    itemSlice,
    upsertItemRaw,
    upsertItemsRaw,
} from '@a10base/frontend/redux/reducers/item-slice.js'

type AppThunk = ThunkAction<void, any, any, Action<string>>

export function upsertItems<T extends schema.TableWithIdAndVersion>(
    table: T,
    items: schema.JSONSelectableForTable<T>[]
): AppThunk {
    return upsertItemsRaw(table, items)
}

export function upsertItem<T extends schema.TableWithIdAndVersion>(
    table: T,
    item: schema.JSONSelectableForTable<T>
): AppThunk {
    return upsertItemRaw(table, item)
}

export function deleteItem<T extends schema.TableWithIdAndVersion>(table: T, id: number): AppThunk {
    return deleteItemRaw(table, id)
}

export function deleteItems<T extends schema.TableWithIdAndVersion>(
    table: T,
    ids: number[]
): AppThunk {
    return deleteItemsRaw(table, ids)
}

export const itemActions = {
    clearTable: itemSlice.actions.clearTable,
    clearAllTables: itemSlice.actions.clearAllTables,
    upsertItem: upsertItem,
    upsertItems: upsertItems,
    deleteItem: deleteItemRaw,
    deleteItems: deleteItemsRaw,
}
