import React, { useCallback, useEffect, useState } from 'react'
import { Box, IconButton, Stack, CSSObject } from '@mui/material'
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material'

import { BackButton } from './button/BackButton.js'
import { subscribeToMessageBase } from '../message-bus/message-bus.js'
import { UrlChanged } from '../message-bus/messages.js'
import { useIsSmallScreen } from '../hooks/misc.js'

export const APP_BAR_HEIGHT = 64
export const SIDE_BAR_WIDTH = 230
export const SIDE_BAR_WIDTH_NARROW = 70

interface AppSkeletonProps {
    appBarLeft?: React.ReactNode
    appBarRigth?: React.ReactNode
    appBarSx?: CSSObject
    sideBarSx?: CSSObject
    menu: (onlyIcons: boolean) => React.ReactNode
    children: React.ReactNode
}

export function AppSkeleton(props: AppSkeletonProps) {
    // const theme = useTheme()
    // const smallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const smallScreen = useIsSmallScreen()
    const [menuOpen, setMenuOpen] = useState<boolean>(!smallScreen)

    const toggleMenu = useCallback(() => {
        setMenuOpen(v => !v)
    }, [])

    useEffect(() => {
        return subscribeToMessageBase('url-changed', (message: UrlChanged) => {
            if (message.pathname !== message.prevPathname) {
                if (smallScreen) {
                    setMenuOpen(false)
                }
            }
        })
    }, [smallScreen])

    const showOnlyIconsInMenu = !menuOpen && !smallScreen
    const menuWidth = smallScreen
        ? menuOpen
            ? '100%'
            : '0px'
        : menuOpen
          ? `${SIDE_BAR_WIDTH}px`
          : `${SIDE_BAR_WIDTH_NARROW}px`
    const contentWidth = `calc(100% - ${menuWidth})`

    return (
        <Stack direction="row" width="100%" height="100%">
            <SideBar
                smallScreen={smallScreen}
                menuWidth={menuWidth}
                menuOpen={menuOpen}
                toggleMenu={toggleMenu}
                sx={props.sideBarSx}
            >
                {props.menu(showOnlyIconsInMenu)}
            </SideBar>
            <Stack direction="column" width={contentWidth} minWidth={contentWidth}>
                <AppBar
                    smallScreen={smallScreen}
                    toggleMenu={toggleMenu}
                    left={props.appBarLeft}
                    right={props.appBarRigth}
                    sx={props.appBarSx}
                />
                <MainContent>{props.children}</MainContent>
            </Stack>
        </Stack>
    )
}

interface SideBarProps {
    children: React.ReactNode
    menuWidth: string
    smallScreen: boolean
    menuOpen: boolean
    sx?: CSSObject
    toggleMenu: () => void
}
function SideBar({ children, menuWidth, smallScreen, menuOpen, sx, toggleMenu }: SideBarProps) {
    return (
        <Stack
            direction="column"
            alignItems="flex-start"
            sx={{
                width: menuWidth,
                minWidth: menuWidth,
                minHeight: '100vh',
                backgroundColor: '#eaf1fb',
                overflowY: 'auto',
                overflowX: 'hidden',
                ...sx,
            }}
        >
            <ToggleMenuButton
                onClick={toggleMenu}
                cross={menuOpen && smallScreen}
                sx={{ alignSelf: smallScreen ? 'flex-end' : 'flex-start' }}
            />
            {children}
        </Stack>
    )
}

interface AppBarProps {
    smallScreen: boolean
    right?: React.ReactNode
    left?: React.ReactNode
    sx?: CSSObject
    toggleMenu: () => void
}
function AppBar({ smallScreen, right, left, sx, toggleMenu }: AppBarProps) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                position: 'sticky',
                top: 0,
                width: '100%',
                height: `${APP_BAR_HEIGHT}px`,
                minHeight: `${APP_BAR_HEIGHT}px`,
                backgroundColor: '#f6f8fc',
                color: 'grey',
                zIndex: 1000,
                px: '20px',
                ...sx,
            }}
        >
            <Stack direction="row" alignItems="center">
                <BackButton />
                {left}
            </Stack>
            <Stack direction="row" alignItems="center">
                {right}
                {smallScreen && <ToggleMenuButton onClick={toggleMenu} />}
            </Stack>
        </Stack>
    )
}

interface MainContentProps {
    children: React.ReactNode
}
function MainContent({ children }: MainContentProps) {
    return (
        <Box
            sx={{
                flexGrow: 1,
                backgroundColor: '#FFF',
                overflowY: 'auto',
                padding: '20px',
                maxWidth: '1200px',
                width: '100%',
            }}
        >
            {children}
        </Box>
    )
}

interface ToggleMenuButtonProps {
    sx?: CSSObject
    cross?: boolean
    onClick: () => void
}
function ToggleMenuButton({ sx, cross, onClick }: ToggleMenuButtonProps) {
    return (
        <Box
            sx={{
                height: APP_BAR_HEIGHT,
                alignSelf: 'flex-start',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx,
            }}
        >
            <IconButton
                color="inherit"
                aria-label="open menu"
                onClick={onClick}
                sx={{ ml: '8px', mr: '8px' }}
            >
                {cross ? <CloseIcon fontSize="medium" /> : <MenuIcon fontSize="medium" />}
            </IconButton>
        </Box>
    )
}
