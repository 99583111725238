import React, { ReactNode, useCallback, useMemo, useRef } from 'react'

import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TableRow,
    TableRowProps,
} from '@mui/material'

import { getValue } from '@a10base/common/misc.js'

export interface BaseTableColumn<T> {
    id: string
    header?: ReactNode
    skip?: boolean
    align?: 'left' | 'right' | 'center'
    noPadding?: boolean
    render?: (row: T) => ReactNode | string
    cellProps?: (row: T) => TableCellProps
}

export interface BaseTableProps<T> {
    columns: BaseTableColumn<T>[] // Use useMemo
    rows: T[]
    selectedRows?: T[]
    loadingRows?: boolean
    size?: 'small' | 'medium'
    uniqueRowId?: (row: T) => number | string
    onSelectRows?: (selectedRows: T[]) => void // Must use useCallback!
    rowProps?: (item: T) => TableRowProps
}

export function BaseTable<T>(props: BaseTableProps<T>) {
    const { columns, rows, selectedRows, size, uniqueRowId, onSelectRows, rowProps } = props

    const uniqueRowIdRef = useRef(uniqueRowId ?? getRowId)
    uniqueRowIdRef.current = uniqueRowId ?? getRowId

    const toggleRowSelection = useCallback(
        (row: T) => {
            if (onSelectRows && selectedRows !== undefined) {
                const updatedSelectedRows = selectedRows.includes(row)
                    ? selectedRows.filter(r => r !== row)
                    : selectedRows.concat(row)
                onSelectRows(updatedSelectedRows)
            }
        },
        [onSelectRows, selectedRows]
    )

    const toggleAllRowsSelection = useCallback(() => {
        if (onSelectRows && selectedRows !== undefined) {
            onSelectRows(selectedRows.length > 0 ? [] : rows)
        }
    }, [onSelectRows, rows, selectedRows])

    const headerRow = useMemo(
        () => (
            <TableRow sx={{ backgroundColor: '#f3f3f3' }}>
                {onSelectRows && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={
                                rows.length > 0 &&
                                selectedRows !== undefined &&
                                selectedRows.length > 0 &&
                                selectedRows.length < rows.length
                            }
                            checked={
                                rows.length > 0 &&
                                selectedRows !== undefined &&
                                selectedRows.length === rows.length
                            }
                            onChange={toggleAllRowsSelection}
                        />
                    </TableCell>
                )}
                {columns
                    .filter(col => !col.skip)
                    .map(col => (
                        <TableCell
                            key={col.id}
                            align={col.align}
                            padding={col.noPadding ? 'none' : 'normal'}
                        >
                            {col.header ?? col.id}
                        </TableCell>
                    ))}
            </TableRow>
        ),
        [columns, onSelectRows, rows.length, selectedRows, toggleAllRowsSelection]
    )

    const bodyRows = useMemo(() => {
        const selectedRowIds = new Set((selectedRows || []).map(uniqueRowIdRef.current))
        const isSelectable = onSelectRows !== undefined
        return rows.map((row, index) => {
            const rowId = uniqueRowIdRef.current(row)
            const selected = isSelectable && selectedRowIds.has(rowId)
            const extraRowProps = rowProps ? rowProps(row) : {}
            return (
                <TableRow
                    key={index}
                    hover
                    selected={selected}
                    {...extraRowProps}
                    sx={{
                        ...extraRowProps.sx,
                        '&:last-child td, &:last-child th': {
                            border: 0,
                        },
                    }}
                >
                    {isSelectable && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                checked={selected}
                                onChange={() => toggleRowSelection(row)}
                            />
                        </TableCell>
                    )}
                    {columns
                        .filter(col => !col.skip)
                        .map(col => (
                            <TableCell
                                key={col.id}
                                align={col.align}
                                padding={col.noPadding ? 'none' : 'normal'}
                                {...(col.cellProps ? col.cellProps(row) : {})}
                            >
                                {col.render ? col.render(row) : String(getValue(row, col.id))}
                            </TableCell>
                        ))}
                </TableRow>
            )
        })
    }, [columns, onSelectRows, rowProps, rows, selectedRows, toggleRowSelection])

    return (
        <TableContainer>
            <Table size={size}>
                <TableHead>{headerRow}</TableHead>
                <TableBody>{bodyRows}</TableBody>
            </Table>
        </TableContainer>
    )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRowId(row: any): number {
    return row.id
}
