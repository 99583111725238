import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { Store } from '@reduxjs/toolkit'

import { serverData } from './util/server-data.js'
import { setPathAfterLogin } from './util/path-after-login.js'

export function renderApp(Application: React.FC, store: Store): void {
    setPathAfterLogin(location.pathname + location.search)
    const container = document.getElementById('app') as HTMLElement
    container.innerHTML = '' // Clear loading indicators (this is not necessary)
    const root = createRoot(container)
    root.render(
        <Provider store={store}>
            <IntlProvider locale={serverData.config.CLIENT_LANG} messages={{}}>
                <Application />
            </IntlProvider>
        </Provider>
    )
}
