import React from 'react'
// https://mui.com/x/react-date-pickers/getting-started/
// import { LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { RouteDefinition } from '@a10base/frontend/components/index.js'
import { AdminDashboardPage } from './components/index.js'
import { ForgottenPasswordPage, LoginPage, SetPasswordPage } from '../app/pages/index.js'
import {
    NotificationList,
    NotificationEdit,
    PublicAssetsPage,
    LogsPage,
    StaticHtmlPage,
    EmailTemplatePage,
    EmailTemplateRenderPage,
} from '@a10base/frontend/admin/pages/index.js'
import { AdminMiscToolsPage, CodeEditorPage, ScoringPage, TestCasePage } from './pages/index.js'

export const routes: RouteDefinition[] = [
    {
        pathTemplate: '',
        render: () => <AdminDashboardPage />,
    },
    {
        pathTemplate: 'login',
        render: () => <LoginPage hideSignupLink />,
        noWrapper: true,
    },
    {
        pathTemplate: 'forgotten-password',
        render: () => <ForgottenPasswordPage hideSignupLink />,
        noWrapper: true,
    },
    {
        pathTemplate: 'set-password',
        render: () => <SetPasswordPage />,
        noWrapper: true,
    },
    {
        pathTemplate: 'code',
        render: () => <CodeEditorPage />,
    },
    {
        pathTemplate: 'test-cases',
        render: () => <TestCasePage />,
    },
    {
        pathTemplate: 'scorings',
        render: () => <ScoringPage />,
    },
    {
        pathTemplate: 'html-pages',
        render: () => <StaticHtmlPage />,
    },
    {
        pathTemplate: 'email-templates',
        render: () => <EmailTemplatePage />,
    },
    {
        pathTemplate: 'email-templates/:name',
        render: m => <EmailTemplateRenderPage templateName={m.getString()} />,
    },
    {
        pathTemplate: 'notifications',
        render: () => <NotificationList />,
    },
    {
        pathTemplate: 'notifications/:id',
        render: m => <NotificationEdit id={m.getInt()} />,
    },
    // {
    //     pathTemplate: 'test-cases',
    //     render: () => <TestCaseList />,
    // },
    // {
    //     pathTemplate: 'test-cases/:id',
    //     render: m => <TestCaseEdit id={m.getInt()} />,
    // },
    {
        pathTemplate: 'public-assets',
        render: () => <PublicAssetsPage />,
    },
    {
        pathTemplate: 'misc',
        render: () => <AdminMiscToolsPage />,
    },
    {
        pathTemplate: 'logs',
        render: () => <LogsPage />,
    },
]
