import _ from 'lodash'

// Filters are strings of the form "column op value", where op is one of =, !=, <, <=, >, >=, @>.
// They are connected with AND except when there are multiple filters with same column name and '=' operator then the connector is OR.

import { isNotNil } from './misc.js'

// TODO: add unit tests

/* eslint-disable @typescript-eslint/no-explicit-any */
export const VALID_FILTER_OPERATORS: { [op: string]: boolean } = {
    '=': true,
    '!=': true,
    '<': true,
    '<=': true,
    '>': true,
    '>=': true,
    '@>': true, // array contains
}

export type ItemFilterValue = string | boolean | number | null

export interface ItemFilter {
    column: string
    op: string
    value: ItemFilterValue
}

export function parseItemFilter(filter: string): ItemFilter | undefined {
    for (let i = 1; i < filter.length; i++) {
        const c = filter[i]
        if (c === '<' || c === '>' || c === '=' || c === '!' || c === '@') {
            const nextChar = filter[i + 1]
            const opLen = nextChar === '=' || nextChar === '>' ? 2 : 1
            const column = filter.substring(0, i).trim()
            const op = filter.substring(i, i + opLen)
            const value = filter.substring(i + opLen, filter.length).trim()
            const parsedFilter: ItemFilter = { column, op, value }
            if (!VALID_FILTER_OPERATORS[parsedFilter.op]) {
                throw new Error(`Invalid operator in filter string: ${filter}`)
            }
            if (value === 'undefined') {
                return undefined
            }
            if (value === 'null' && op !== '=') {
                return undefined
            }
            if (value === 'true') {
                parsedFilter.value = true
            } else if (value === 'false') {
                parsedFilter.value = false
            } else if (value === 'null') {
                parsedFilter.value = null
            } else if (
                (value.startsWith("'") && value.endsWith("'")) ||
                (value.startsWith('"') && value.endsWith('"'))
            ) {
                parsedFilter.value = value.substring(1, value.length - 1)
            } else if (value.length > 0) {
                const num = Number(parsedFilter.value)
                if (!isNaN(num)) {
                    parsedFilter.value = num
                } else {
                    parsedFilter.value = value
                }
            }
            return parsedFilter
        }
    }
}

export function parseItemFiltersString(filters: string): ItemFilter[] {
    return filters.split(' & ').map(parseItemFilter).filter(isNotNil)
}

export function splitFiltersString(filters: string): string[] {
    return filters.split(' & ').map(v => v.trim())
}

type ItemValue = string | number | boolean | null

const filterFns: {
    [op: string]: (items: any[], column: string, value: ItemValue) => any[]
} = {
    '=': (items: any[], column: string, value: ItemValue) => items.filter(v => v[column] === value),
    '!=': (items: any[], column: string, value: ItemValue) =>
        items.filter(v => v[column] !== value),
    '<': (items: any[], column: string, value: ItemValue) =>
        items.filter(v => value !== null && v[column] < value),
    '<=': (items: any[], column: string, value: ItemValue) =>
        items.filter(v => value !== null && v[column] <= value),
    '>': (items: any[], column: string, value: ItemValue) =>
        items.filter(v => value !== null && v[column] > value),
    '>=': (items: any[], column: string, value: ItemValue) =>
        items.filter(v => value !== null && v[column] >= value),
    '@>': (items: any[], column: string, value: ItemValue) =>
        items.filter(v => {
            if (typeof value === 'string' && Array.isArray(v[column])) {
                const parsedValue = JSON.parse(value)
                return Array.isArray(parsedValue) && parsedValue.every(x => v[column].includes(x))
            }
            return false
        }),
}

export function filterItems<T = unknown>(items: T[], filters: ItemFilter[]): T[] {
    const { normalFilters, multiColumnEqualityFilters } = groupItemFilters(filters)
    let filteredItems: T[] = items
    for (const filter of normalFilters) {
        filteredItems = filterFns[filter.op](filteredItems, filter.column, filter.value)
    }
    for (const eqFilters of multiColumnEqualityFilters) {
        const values = eqFilters.map(v => v.value)
        filteredItems = filteredItems.filter(item =>
            values.includes((item as any)[eqFilters[0].column])
        )
    }
    return filteredItems
}

export function groupItemFilters(filters: ItemFilter[]): {
    normalFilters: ItemFilter[]
    multiColumnEqualityFilters: ItemFilter[][]
} {
    const normalFilters: ItemFilter[] = []
    const equalityFilters: ItemFilter[] = []

    for (const filter of filters) {
        if (filter.op === '=') {
            equalityFilters.push(filter)
        } else {
            normalFilters.push(filter)
        }
    }

    const multiColumnEqualityFilters: ItemFilter[][] = []
    if (equalityFilters.length > 0) {
        const grouped = _.groupBy(equalityFilters, v => v.column)
        for (const column of Object.keys(grouped)) {
            if (grouped[column].length > 1) {
                multiColumnEqualityFilters.push(grouped[column])
            } else {
                normalFilters.push(grouped[column][0])
            }
        }
    }

    return { normalFilters, multiColumnEqualityFilters }
}

export function orderItems<T = unknown>(
    items: T[],
    orderBy: string,
    orderDir?: 'ASC' | 'DESC'
): T[] {
    const comparator = (a: T, b: T) => {
        const aValue = (a as any)[orderBy]
        const bValue = (b as any)[orderBy]
        const dir = orderDir === 'DESC' ? -1 : 1
        if (aValue === bValue) {
            return 0
        }
        if (aValue === null) {
            return -dir
        }
        if (bValue === null) {
            return dir
        }
        if (aValue < bValue) {
            return -dir
        }
        return dir
    }
    return [...items].sort(comparator)
}

// E.g. getFilters('x=1', bar && `foo=${bar}`) => ['x=1'] if bar is falsy, ['x=1', 'foo=bar'] if bar is truthy
export function getFilters(...filters: unknown[]): string[] {
    return filters
        .filter(v => !!v)
        .map(v => String(v))
        .filter(v => v.length > 0)
}
