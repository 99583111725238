import * as yup from 'yup'
import { id, isoDateTimeString, objectStrict } from './common.js'

export const createGlobalNotification = objectStrict({
    type: yup.string().oneOf(['info', 'alert']).required(),
    show_in_app: yup.boolean().required(),
    show_in_site: yup.boolean().required(),
    translations: yup.object().required(),
    valid_from: isoDateTimeString.required(),
    valid_to: isoDateTimeString.required(),
}).defined()
export type CreateGlobalNotification = yup.Asserts<typeof createGlobalNotification>

export const updateGlobalNotificationData = objectStrict({
    type: yup.string().oneOf(['info', 'alert']).optional(),
    show_in_app: yup.boolean().optional(),
    show_in_site: yup.boolean().optional(),
    valid_from: isoDateTimeString.optional(),
    valid_to: isoDateTimeString.optional(),
    translations: yup.object().optional(),
}).defined()
export type UpdateGlobalNotificationData = yup.Asserts<typeof updateGlobalNotificationData>

export const updateGlobalNotification = objectStrict({
    id: id,
    data: updateGlobalNotificationData,
}).defined()
