import React, { useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { Translate as TranslateIcon } from '@mui/icons-material'

import { useAsyncFn, useLoader, useStateLS } from '@a10base/frontend/hooks/index.js'

import {
    AsyncButton,
    BaseTableColumn,
    ExternalLink,
    LanguageSelector,
    PopupMenu,
    PopupMenuRef,
    Table,
    useTableState,
} from '@a10base/frontend/components/index.js'
import { trpcBase } from '@a10base/frontend/util/trpc.js'
import { serverData } from '@a10base/frontend/util/server-data.js'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
} from '@mui/material'
import { first, last } from '@a10base/common/misc.js'

export function StaticHtmlPage() {
    const [filePaths, loading, reload] = useLoader(() =>
        trpcBase.admin.misc.getStaticHtmlFiles.query({ target: 'www' })
    )
    const tableState = useTableState(50)

    const rows = useMemo(() => {
        const rows: HtmlPage[] = []
        if (!filePaths) return rows
        const groupedByBasePath = _.groupBy(filePaths, v => {
            const i = v.lastIndexOf('/')
            return i === -1 ? '' : v.slice(0, i)
        })
        for (const [basePath, files] of Object.entries(groupedByBasePath)) {
            if (basePath === '') continue
            const js = files.some(v => v.endsWith('.js'))
            const less = files.some(v => v.endsWith('.less'))
            const langs = files.filter(v => v.endsWith('.html')).map(v => v.slice(-7, -5))
            rows.push({ name: basePath, js, less, langs })
        }
        rows.sort((a, b) => a.name.localeCompare(b.name))
        return rows
    }, [filePaths])

    const columns = useMemo<BaseTableColumn<HtmlPage>[]>(() => {
        return [
            {
                id: 'name',
                header: 'Name',
                render: v => v.name,
            },
            {
                id: 'js',
                header: 'js',
                render: v => (v.js ? '✓' : ''),
            },
            {
                id: 'less',
                header: 'less',
                render: v => (v.js ? '✓' : ''),
            },
            {
                id: 'langs',
                header: 'Languages',
                render: v => v.langs.join(', '),
            },
            {
                id: 'action',
                header: '',
                render: page => <ActionMenuButton page={page} onReload={reload} />,
            },
        ]
    }, [reload])

    return (
        <div>
            <h1>Html pages</h1>
            <Table rows={rows} loadingRows={loading} columns={columns} {...tableState} />
        </div>
    )
}

interface HtmlPage {
    name: string
    js: boolean
    less: boolean
    langs: string[]
}

interface ActionMenuButtonProps {
    page: HtmlPage
    onReload: () => void
}
function ActionMenuButton({ page, onReload }: ActionMenuButtonProps) {
    const popupRef = useRef<PopupMenuRef | null>(null)
    const [modal, setModal] = useState<'translate' | undefined>(undefined)

    const { SITE_URL, A10_ENV, CLIENT_LANG } = serverData.config
    let renderUrl = `${SITE_URL}/${page.name}?forcedLang=${CLIENT_LANG}&noCache=true`
    if (A10_ENV === 'local') {
        renderUrl = renderUrl + '&temp-target=www'
    }

    return (
        <>
            <PopupMenu ref={popupRef}>
                <MenuItem>
                    <ExternalLink href={renderUrl} onClick={popupRef.current?.close}>
                        Render
                    </ExternalLink>
                </MenuItem>
                <MenuItem>
                    <Button
                        startIcon={<TranslateIcon />}
                        onClick={() => setModal('translate')}
                        disabled={serverData.config.A10_ENV !== 'local' || page.langs.length === 0}
                    >
                        Translate
                    </Button>
                </MenuItem>
            </PopupMenu>
            {modal === 'translate' && (
                <TranslateModal
                    onClose={() => {
                        setModal(undefined)
                        onReload()
                        popupRef.current?.close()
                    }}
                    page={page}
                />
            )}
        </>
    )
}

interface TranslateModalProps {
    onClose: () => void
    page: HtmlPage
}
function TranslateModal({ onClose, page }: TranslateModalProps) {
    const [sourceLang, setSourceLang] = useStateLS<string | undefined>(
        'translate-source-lang',
        first(page.langs)
    )
    const [targetLang, setTargetLang] = useStateLS<string | undefined>(
        'translate-target-lang',
        last(page.langs)
    )
    const sourceLangs = serverData.languages.filter(v => page.langs.includes(v.code))

    const translate = useAsyncFn(async () => {
        if (sourceLang && targetLang && sourceLang !== targetLang) {
            await trpcBase.admin.translation.translateStaticHtmlFile.mutate({
                name: page.name,
                sourceLangCode: sourceLang,
                targetLangCode: targetLang,
            })
        }
    }, onClose)

    return (
        <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Translate "{page.name}"</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1} mt={3}>
                    <LanguageSelector
                        label="Source language"
                        value={sourceLang}
                        onChange={setSourceLang}
                        langs={sourceLangs}
                        showCode
                    />
                    <LanguageSelector
                        label="Target language"
                        value={targetLang}
                        onChange={setTargetLang}
                        showCode
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <AsyncButton
                    {...translate}
                    size="medium"
                    disabled={
                        sourceLang === undefined ||
                        targetLang === undefined ||
                        sourceLang === targetLang
                    }
                    startIcon={<TranslateIcon fontSize="medium" />}
                >
                    Translate
                </AsyncButton>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
