import React from 'react'
import { Stack, Typography } from '@mui/material'

interface FormErrorSummaryProps {
    errors: Map<string, string> | null
    mt?: number | string
    mb?: number | string
}

export const FormErrorSummary = React.memo(function FormErrorSummary({
    errors,
    mt,
    mb,
}: FormErrorSummaryProps) {
    if (!errors || errors.size === 0) {
        return null
    }
    const errorMessages = Object.values(errors)
    return (
        <Stack
            sx={{
                borderWidth: '1px',
                borderColor: 'error',
                borderRadius: '5px',
                p: '10px',
                mt: mt ?? '5px',
                mb: mb ?? '5px',
            }}
        >
            {errorMessages.map((error, index) => (
                <Typography key={index} variant="body2" color="error" component="p" mt="-5px">
                    {error}
                </Typography>
            ))}
        </Stack>
    )
})
