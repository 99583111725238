import React from 'react'
import { Typography } from '@mui/material'

interface FormInputErrorProps {
    errorMsg: string | undefined
}

export const FormInputError = React.memo(function FormInputError(props: FormInputErrorProps) {
    if (!props.errorMsg) {
        return null
    }
    return (
        <Typography variant="body2" color="error" component="div" mt="-15px">
            {props.errorMsg}
        </Typography>
    )
})
