import React, { useCallback } from 'react'

import { TableProps } from './Table.js'
import { BaseTable, BaseTableColumn } from './BaseTable.js'
import { SearchBox } from '../SearchBox.js'
import { Stack, TablePagination } from '@mui/material'
import { NoData } from '../NoData.js'

export interface AsyncTableProps<T> extends Omit<TableProps<T>, 'columns' | 'getSearchableValues'> {
    columns: BaseTableColumn<T>[] // Use useMemo
    searchable?: boolean
}

export function AsyncTable<T>(props: AsyncTableProps<T>) {
    const {
        columns,
        searchable,
        rows,
        loadingRows,
        page,
        pageSize,
        searchText,
        searchInputPlaceholder,
        emptyTablePlaceholder,
        sx,
        uniqueRowId,
        onChangeSearchText,
        onChangePage,
        onChangePageSize,
        onSelectRows,
        ...baseTableProps
    } = props
    const onSearchHandler = useCallback(
        (newSearchText: string) => {
            if (onChangeSearchText) {
                onSelectRows?.([])
                onChangeSearchText(newSearchText)
                onChangePage(0)
            }
        },
        [onChangeSearchText, onSelectRows, onChangePage]
    )

    const handlePageChange = useCallback(
        (_event: unknown, newPage: number) => {
            onChangePage(newPage)
        },
        [onChangePage]
    )

    const handleRowsPerPageChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const rowsPerPage = parseInt(event.target.value, 10) ?? 10
            onChangePageSize?.(rowsPerPage)
            onChangePage(0)
        },
        [onChangePage, onChangePageSize]
    )

    return (
        <Stack
            direction="column"
            spacing={1}
            padding={sx?.padding ?? 2}
            border={sx?.border ?? '1px solid #f1f1f1'}
            borderRadius={sx?.borderRadius ?? '5px'}
            width={sx?.width ?? '100%'}
            className={loadingRows ? 'table-loading' : ''}
            sx={sx}
        >
            {searchable && (
                <SearchBox
                    value={searchText}
                    onSearch={onSearchHandler}
                    placeholder={searchInputPlaceholder}
                    loading={loadingRows}
                    fullWidth
                    showSearchButton
                    sx={{ maxWidth: '400px' }}
                    size="small"
                />
            )}
            {(page > 0 || rows.length > 0) && (
                <BaseTable
                    columns={columns}
                    rows={rows}
                    uniqueRowId={uniqueRowId}
                    onSelectRows={onSelectRows}
                    loadingRows={loadingRows}
                    {...baseTableProps}
                />
            )}
            {(page > 0 || rows.length >= pageSize) && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={-1}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    showLastButton={false}
                />
            )}
            {page === 0 &&
                rows.length === 0 &&
                !loadingRows &&
                (emptyTablePlaceholder ?? <NoData />)}
        </Stack>
    )
}
