import { getErrorMsg } from '@a10base/common/misc.js'

export type LogAttributeObject = Record<string, unknown>

export const MAX_LOG_ATTRIBUTE_NESTING_DEPTH = 4 // { a: 1 } is depth 0, { a: { b: 1 } } is depth 1
export const MAX_LOG_ATTRIBUTE_COUNT_PER_NESTING_LEVEL = 80

export function convertToLoggableObject(attributes: unknown): LogAttributeObject {
    const value = convertToLoggableObjectValue(attributes)
    return typeof value !== 'object' ? { value } : (value as LogAttributeObject)
}

export function convertToLoggableObjectValue(attribute: unknown, nestingDepth = 0): unknown {
    const attributeType = typeof attribute
    if (
        attributeType === 'string' ||
        attributeType === 'number' ||
        attributeType === 'boolean' ||
        attribute === null ||
        attribute === undefined ||
        attributeType === 'bigint'
    ) {
        return attribute
    }

    if (Array.isArray(attribute)) {
        if (nestingDepth > MAX_LOG_ATTRIBUTE_NESTING_DEPTH) {
            return ['__too_deeply_nested']
        }
        return attribute
            .slice(0, MAX_LOG_ATTRIBUTE_COUNT_PER_NESTING_LEVEL)
            .map(v => convertToLoggableObjectValue(v, nestingDepth + 1))
    }

    if (attribute instanceof Set) {
        if (nestingDepth > MAX_LOG_ATTRIBUTE_NESTING_DEPTH) {
            return ['__too_deeply_nested']
        }
        return [...attribute.values()]
            .slice(0, MAX_LOG_ATTRIBUTE_COUNT_PER_NESTING_LEVEL)
            .map(v => convertToLoggableObjectValue(v, nestingDepth + 1))
    }

    if (attribute instanceof Date) {
        return attribute.toISOString()
    }

    if (attributeType === 'function') {
        return '[~fn~]'
    }

    if (attributeType === 'object') {
        if (nestingDepth > MAX_LOG_ATTRIBUTE_NESTING_DEPTH) {
            return { __too_deeply_nested: true }
        }

        const entries = getObjectEntries(attribute)
        const isError = attribute instanceof Error

        const attributeObj: Record<string, unknown> = {}
        let attributeCount = 0
        for (const [key, value] of entries) {
            attributeCount += 1
            if (attributeCount > MAX_LOG_ATTRIBUTE_COUNT_PER_NESTING_LEVEL) {
                attributeObj.__too_many_attributes = true
                break
            }
            const updatedKey = key === 'message' && isError ? 'error_msg' : key
            attributeObj[updatedKey] = convertToLoggableObjectValue(value, nestingDepth + 1)
        }
        return attributeObj
    }

    try {
        return JSON.stringify(attribute)
    } catch (error: unknown) {
        // Do nothing
        return {
            message: 'convertToLoggableObjectValue: JSON.stringify(attribute) failed',
            error: getErrorMsg(error),
        }
    }
}

function getObjectEntries(obj: unknown): Array<[string, unknown]> {
    if (obj instanceof Map) {
        return Array.from(obj.entries())
    } else if (obj instanceof Error) {
        const entries: Array<[string, unknown]> = []
        // Get all own properties including non-enumerable ones
        const allProps = new Set([...Object.getOwnPropertyNames(obj), ...Object.keys(obj)])
        allProps.forEach(prop => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            entries.push([prop, (obj as any)[prop]])
        })
        return entries
    } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj)
    } else {
        throw new TypeError('Parameter must be a plain object, Error object, or Map object')
    }
}
