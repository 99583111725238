import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import { JwtData } from '@a10yll/common/index.js'

export interface AuthState {
    jwt: JwtData | undefined
    loading: boolean
}

export const initialAuthState: AuthState = {
    jwt: undefined,
    loading: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        setJwt(state, action: PayloadAction<JwtData | undefined>) {
            const newJwt = action.payload
            if (!_.isEqual(state.jwt, newJwt)) {
                state.jwt = newJwt
            }
        },
    },
})

export const authReducer = authSlice.reducer
export const authActions = authSlice.actions
