export interface LanguageDetails {
    name: string
    code: string
    nativeName: string
}

export const languages: LanguageDetails[] = [
    { name: 'Chinese', code: 'zh', nativeName: '中文' },
    { name: 'Spanish', code: 'es', nativeName: 'Español' },
    { name: 'English', code: 'en', nativeName: 'English' },
    { name: 'Hindi', code: 'hi', nativeName: 'हिन्दी' },
    { name: 'Arabic', code: 'ar', nativeName: 'العربية' },
    { name: 'Portuguese', code: 'pt', nativeName: 'Português' },
    { name: 'Bengali', code: 'bn', nativeName: 'বাংলা' },
    { name: 'Russian', code: 'ru', nativeName: 'Русский' },
    { name: 'Japanese', code: 'ja', nativeName: '日本語' },
    { name: 'Lahnda', code: 'pa', nativeName: 'پنجابی / पंजाबी' },
    { name: 'Javanese', code: 'jv', nativeName: 'ꦧꦱꦗꦮ' },
    { name: 'Korean', code: 'ko', nativeName: '한국어' },
    { name: 'French', code: 'fr', nativeName: 'Français' },
    { name: 'German', code: 'de', nativeName: 'Deutsch' },
    { name: 'Telugu', code: 'te', nativeName: 'తెలుగు' },
    { name: 'Marathi', code: 'mr', nativeName: 'मराठी' },
    { name: 'Turkish', code: 'tr', nativeName: 'Türkçe' },
    { name: 'Tamil', code: 'ta', nativeName: 'தமிழ்' },
    { name: 'Vietnamese', code: 'vi', nativeName: 'Tiếng Việt' },
    { name: 'Urdu', code: 'ur', nativeName: 'اردو' },
    { name: 'Persian', code: 'fa', nativeName: 'فارسی' },
    { name: 'Italian', code: 'it', nativeName: 'Italiano' },
    { name: 'Malay', code: 'ms', nativeName: 'Bahasa Melayu' },
    { name: 'Thai', code: 'th', nativeName: 'ไทย' },
    { name: 'Gujarati', code: 'gu', nativeName: 'ગુજરાતી' },
    { name: 'Kannada', code: 'kn', nativeName: 'ಕನ್ನಡ' },
    { name: 'Polish', code: 'pl', nativeName: 'Polski' },
    { name: 'Ukrainian', code: 'uk', nativeName: 'Українська' },
    { name: 'Pashto', code: 'ps', nativeName: 'پښتو' },
    { name: 'Romanian', code: 'ro', nativeName: 'Română' },
    { name: 'Dutch', code: 'nl', nativeName: 'Nederlands' },
    { name: 'Greek', code: 'el', nativeName: 'Ελληνικά' },
    { name: 'Hungarian', code: 'hu', nativeName: 'Magyar' },
    { name: 'Czech', code: 'cs', nativeName: 'Čeština' },
    { name: 'Swedish', code: 'sv', nativeName: 'Svenska' },
    { name: 'Bulgarian', code: 'bg', nativeName: 'Български' },
    { name: 'Serbian', code: 'sr', nativeName: 'Српски' },
    { name: 'Danish', code: 'da', nativeName: 'Dansk' },
    { name: 'Finnish', code: 'fi', nativeName: 'Suomi' },
    { name: 'Norwegian', code: 'no', nativeName: 'Norsk' },
    { name: 'Slovak', code: 'sk', nativeName: 'Slovenčina' },
    { name: 'Hebrew', code: 'he', nativeName: 'עברית' },
    { name: 'Croatian', code: 'hr', nativeName: 'Hrvatski' },
    { name: 'Lithuanian', code: 'lt', nativeName: 'Lietuvių' },
    { name: 'Slovene', code: 'sl', nativeName: 'Slovenščina' },
    { name: 'Estonian', code: 'et', nativeName: 'Eesti' },
    { name: 'Latvian', code: 'lv', nativeName: 'Latviešu' },
    { name: 'Malayalam', code: 'ml', nativeName: 'മലയാളം' },
    { name: 'Sinhala', code: 'si', nativeName: 'සිංහල' },
    { name: 'Azerbaijani', code: 'az', nativeName: 'Azərbaycan dili' },
    { name: 'Armenian', code: 'hy', nativeName: 'Հայերեն' },
]

export const isoCodeToLang = languages.reduce(
    (acc, lang) => {
        acc[lang.code] = lang
        return acc
    },
    {} as Record<string, LanguageDetails>
)

export function getLanguage(isoCode?: string): LanguageDetails | undefined {
    return isoCode ? isoCodeToLang[isoCode] : undefined
}
