import _ from 'lodash'

import { JwtResponse, BaseJwtData } from '@a10base/common/index.js'
import {
    subscribeToMessageBase,
    publishMessageBase,
    JwtTokenRefeshed,
} from '../message-bus/index.js'
import { logger } from './client-logger.js'
import * as api from './api-util.js'
import { getServerData } from './server-data.js'

const JWT_LOADING_INTERVAL = 15 * 60 * 1000 // 15 minutes
const nop = () => null
export class JwtStore<JWT extends BaseJwtData = BaseJwtData> {
    private initialJwtSet = false
    private loadingPromise: Promise<void> | undefined
    public token: string | undefined
    public jwt: JWT | undefined
    private lastLoadTime = Date.now()

    constructor() {
        subscribeToMessageBase('jwt-token-refeshed', (message: JwtTokenRefeshed) => {
            this.token = message.token
            if (message.token === undefined) {
                this.clearJwt()
            } else {
                this.loadJwt().catch(nop)
            }
        })
        setInterval(() => {
            const isTimeToReload = Date.now() - this.lastLoadTime > JWT_LOADING_INTERVAL
            if (isTimeToReload && navigator.onLine) {
                this.lastLoadTime = Date.now()
                this.loadJwt().catch(nop)
            }
        }, 1000)
    }

    // This is not done in the constructor because we want to control when the first 'jwt-updated' message is sent
    setInitialJwtFromServerData(): void {
        const serverData = getServerData<JWT>()
        this.setJwt(serverData.jwt, serverData.jwtToken)
    }

    loadJwt(): Promise<void> {
        if (!this.loadingPromise) {
            logger.debug('Loading JWT')
            this.loadingPromise = api
                .get<JwtResponse<JWT>>('/api/auth/jwt')
                .then(resp => this.setJwt(resp.jwt, resp.token))
                .catch(error => {
                    logger.error('Failed to load JWT', error)
                })
                .finally(() => {
                    this.loadingPromise = undefined
                })
        }
        return this.loadingPromise
    }

    setJwt(jwt: JWT | undefined, token: string | undefined): void {
        if (!this.initialJwtSet || !_.isEqual(this.jwt, jwt)) {
            logger.debug('Jwt updated', { jwt, token })
            const oldJwt = this.jwt
            this.jwt = jwt
            this.token = token
            this.initialJwtSet = true
            publishMessageBase({
                type: 'jwt-updated',
                jwt,
                oldJwt,
                token,
            })
        }
    }

    clearJwt(): void {
        logger.debug('Clearing jwt')
        this.setJwt(undefined, undefined)
    }
}
