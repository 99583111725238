import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import {
    Publish as PublishIcon,
    Edit as RenameIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material'

import {
    AsyncButton,
    CreateNewButton,
    InputModal,
    PageTitleRow,
    PopupMenu,
    PopupMenuRef,
    RemoveButton,
    SaveButton,
    Spinner,
} from '@a10base/frontend/components/index.js'
import { CodeEditor as CodeEditorBase } from '@a10base/frontend/components/CodeEditor.js'
import { useAsyncFn1, useAsyncFn2 } from '@a10base/frontend/hooks/async-function.js'
import { trpc } from '../../../util/index.js'
import { showNotification } from '@a10base/frontend/util/flash-notifications.js'
import { useItemsAdmin, useUpsertItems } from '../../../hooks/index.js'
import { useAppDispatch, itemActions } from '../../../redux/index.js'
import { useStateLS } from '@a10base/frontend/hooks/misc.js'
import {
    Code,
    createScoringFunction,
    scoringOptions,
    validateScoringFunction,
} from '@a10yll/common/index.js'
import { getErrorMsg, getErrorStack } from '@a10base/common/misc.js'

export function CodeEditorPage() {
    const [selectedCodeId, setSelectedCodeId] = useStateLS<number | undefined>(
        'selected-code-id',
        undefined
    )
    const [codes, loadingCodes] = useItemsAdmin('code', '')
    const selectedCode = codes?.find(c => c.id === selectedCodeId)
    const [code, setCode] = useState<string>(selectedCode?.code ?? '')
    const [dirty, setDirty] = useState(false)
    const [modal, setModal] = useState<'create' | undefined>(undefined)
    const upsert = useUpsertItems('code')

    // Initial code selection
    useEffect(() => {
        if (!selectedCode && codes && codes.length > 0) {
            const code = codes.find(c => c.published) ?? codes[0]
            setSelectedCodeId(code.id)
            setCode(code.code)
        }
    }, [codes, selectedCode, setSelectedCodeId])

    // Update dirty state
    useEffect(() => {
        setDirty(code.length !== selectedCode?.code?.length || code !== selectedCode?.code)
    }, [code, selectedCode])

    const updateCodeAction = useAsyncFn2(
        useCallback(
            async (id: number, code: string) => {
                try {
                    const socringFn = createScoringFunction(code)
                    validateScoringFunction(socringFn)
                } catch (error) {
                    showNotification(
                        {
                            messageType: 'error',
                            message: getErrorMsg(error),
                            details: getErrorStack(error),
                        },
                        30
                    )
                    return
                }
                const updated = await trpc.admin.code.update.mutate({ id, code })
                upsert(updated)
                showNotification({ messageType: 'success', message: 'Saved' }, 5)
            },
            [upsert]
        )
    )

    const createAction = useAsyncFn1(
        useCallback(
            async (name: string) => {
                const created = await trpc.admin.code.create.mutate({
                    name,
                    code: emptyScoringFunctionCode,
                })
                upsert(created)
                setSelectedCodeId(created.id)
                setCode(created.code)
                setModal(undefined)
            },
            [setSelectedCodeId, upsert]
        )
    )

    const selectCode = (id: number) => {
        const code = codes.find(c => c.id === id)
        if (code) {
            setSelectedCodeId(id)
            setCode(code.code)
        }
    }

    const processing = updateCodeAction.processing || createAction.processing

    if (loadingCodes) {
        return <Spinner spinning text="Loading" />
    }
    return (
        <Stack direction="column" spacing={1}>
            <PageTitleRow title="Code">
                <CreateNewButton
                    onClick={() => setModal('create')}
                    variant="outlined"
                    color="primary"
                    size="small"
                    plusIcon
                    disabled={processing}
                    ml={2}
                >
                    New version
                </CreateNewButton>
            </PageTitleRow>
            {selectedCode && (
                <Stack direction="row" spacing={1}>
                    <FormControl sx={{ minWidth: '300px' }}>
                        <InputLabel id="code-versions">Code version</InputLabel>
                        <Select
                            labelId="code-versions"
                            label="Code versions"
                            value={selectedCode.id}
                            onChange={e => selectCode(Number(e.target.value))}
                        >
                            {(codes || []).map(v => (
                                <MenuItem key={v.id} value={v.id}>
                                    {v.name} {v.published ? '[published]' : ''}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <MenuButton
                        codes={codes}
                        selectedCode={selectedCode}
                        setSelectedCodeId={setSelectedCodeId}
                    />
                </Stack>
            )}
            <CodeEditorBase
                type="js"
                value={code}
                onChange={value => setCode(value)}
                height="500px"
                dirty={dirty}
            />
            {selectedCode && (
                <Stack direction="row">
                    <SaveButton
                        callFn={() => updateCodeAction.callFn(selectedCode.id, code)}
                        processing={updateCodeAction.processing}
                        disabled={processing || !dirty}
                    />
                </Stack>
            )}
            {modal === 'create' && (
                <InputModal
                    {...createAction}
                    size="xs"
                    label="Version name"
                    minLength={2}
                    maxLength={128}
                    required
                    saveButtonText="Create"
                    title="New code version"
                    onCancel={() => setModal(undefined)}
                />
            )}
        </Stack>
    )
}

interface MenuButtonProps {
    codes: Code[]
    selectedCode: Code | undefined
    setSelectedCodeId: (id: number | undefined) => void
}
function MenuButton({ codes, selectedCode, setSelectedCodeId }: MenuButtonProps) {
    const popupRef = useRef<PopupMenuRef | null>(null)
    const dispatch = useAppDispatch()
    const upsert = useUpsertItems('code')
    const [modal, setModal] = useState<'rename' | undefined>(undefined)

    const deleteAction = useAsyncFn1(
        useCallback(
            async (id: number) => {
                await trpc.admin.code.remove.mutate({ id })
                setSelectedCodeId(codes.find(c => c.published)?.id)
                dispatch(itemActions.deleteItem('code', id))
                showNotification({ messageType: 'success', message: 'Deleted' }, 5)
                popupRef.current?.close()
            },
            [codes, dispatch, setSelectedCodeId]
        )
    )

    const publishAction = useAsyncFn1(
        useCallback(
            async (id: number) => {
                const updated = await trpc.admin.code.publish.mutate({ id })
                upsert(updated)
                showNotification({ messageType: 'success', message: 'Published' }, 5)
                popupRef.current?.close()
            },
            [upsert]
        )
    )

    const renameAction = useAsyncFn2(
        useCallback(
            async (id: number, name: string) => {
                const updated = await trpc.admin.code.update.mutate({ id, name })
                upsert(updated)
                showNotification({ messageType: 'success', message: 'Renamed' }, 5)
                setModal(undefined)
                popupRef.current?.close()
            },
            [upsert]
        )
    )

    const processing = deleteAction.processing || publishAction.processing

    if (!selectedCode) {
        return null
    }

    return (
        <>
            <PopupMenu ref={popupRef} icon={<SettingsIcon />}>
                <MenuItem>
                    <AsyncButton
                        callFn={() => publishAction.callFn(selectedCode.id)}
                        processing={publishAction.processing}
                        disabled={processing || selectedCode?.published}
                        startIcon={<PublishIcon />}
                    >
                        Publish
                    </AsyncButton>
                </MenuItem>
                <MenuItem>
                    <Button
                        onClick={() => setModal('rename')}
                        disabled={processing}
                        startIcon={<RenameIcon />}
                    >
                        Rename
                    </Button>
                </MenuItem>
                <MenuItem>
                    <RemoveButton
                        callFn={() => deleteAction.callFn(selectedCode.id)}
                        processing={deleteAction.processing}
                        disabled={processing}
                    />
                </MenuItem>
            </PopupMenu>
            {modal === 'rename' && (
                <InputModal
                    callFn={name => renameAction.callFn(selectedCode.id, name)}
                    processing={renameAction.processing}
                    initialValue={selectedCode.name ?? undefined}
                    onCancel={() => setModal(undefined)}
                    label="New name"
                    maxLength={128}
                    minLength={2}
                    size="xs"
                    saveButtonText="Rename"
                    title="Rename code version"
                />
            )}
        </>
    )
}

const emptyScoringFunctionCode = `
// Credit score constants (you can use these with the constant 'creditScore')
const NA = 5;   // Not known, assume AA
const C = 1;
const B = 2;
const A = 3;
const A_PLUS = 4;
const AA = 5;
const AA_PLUS = 6;
const AAA = 7; 

// Outcome constants (return one of these from the function 'calculateChances')
const NO_CHANCES = 0;
const SMALL_CHANCES = 1;
const REASONABLE_CHANCES = 2;
const GOOD_CHANCES = 3;
const VERY_GOOD_CHANCES = 4;

function calculateChances(userInput) {
  // Extract user input (use these variables to calculate chances)
  //
  // creditScore: ${scoringOptions.creditScore.map(v => `${v.value} (${v.label})`).join(', ')}
  const creditScore = userInput.credit_score;
  // revenue: ${scoringOptions.revenue.map(v => `${v.value} (${v.label})`).join(', ')}
  const revenue = userInput.revenue;
  // loanAmount: ${scoringOptions.loanAmount.map(v => `${v.value}`).join(', ')}
  const loanAmount = userInput.loan_amount;
  const collateral = userInput.collateral;  // 0 (no), 1 (yes)
  const paybackTime = userInput.payback_time;  // ${scoringOptions.paybackTime[0].value}...${scoringOptions.paybackTime.at(-1)!.value} (years)
  // interest: ${scoringOptions.interest.map(v => `${v.value} (${v.label})`).join(', ')}
  const interest = userInput.interest;

  // Your logic goes here
  // ...
  return NO_CHANCES;
}
`
