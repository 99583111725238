import { Chip, CSSObject, Tooltip } from '@mui/material'
import React from 'react'

import type * as schema from 'zapatos/schema'

interface EmailVerificationStatusProps {
    status: schema.email_verification | null | undefined
    size?: 'small' | 'medium'
}

export const EmailVerificationStatus: React.FC<EmailVerificationStatusProps> = ({
    status,
    size,
}) => {
    const sx: CSSObject = size === 'small' ? { height: '18px', fontSize: '0.5rem' } : {}
    if (!status) {
        return (
            <Tooltip title="Email confirmation not send yet.">
                <Chip label="Pending" color="warning" size="small" variant="outlined" sx={sx} />
            </Tooltip>
        )
    }
    if (status === 'sent') {
        return (
            <Tooltip title="Email address has not been confirmed yet.">
                <Chip label="Sent" color="info" size="small" variant="outlined" sx={sx} />
            </Tooltip>
        )
    }
    if (status === 'verified') {
        return (
            <Tooltip title="Email address has been confirmed.">
                <Chip label="Confirmed" color="success" size="small" variant="outlined" sx={sx} />
            </Tooltip>
        )
    }
    if (status === 'error') {
        return (
            <Tooltip title="Email address has not been confirmed. An error has occured.">
                <Chip label="Error" color="error" size="small" variant="outlined" sx={sx} />
            </Tooltip>
        )
    }
}
