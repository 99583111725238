/* eslint-disable @typescript-eslint/no-explicit-any */

export function rawFileUpload(
    file: File,
    uploadPath: string,
    onReady: (response: any) => void,
    onError: (e: ProgressEvent<XMLHttpRequestEventTarget>) => void,
    onProgress?: (percent: number) => void
): XMLHttpRequest {
    const request = new XMLHttpRequest()
    request.responseType = 'json'
    request.open('POST', uploadPath)

    // Progress handler
    if (onProgress) {
        request.upload.addEventListener('progress', function (e) {
            const percent = Math.floor((e.loaded / e.total) * 100)
            onProgress(percent)
        })
    }

    // Ready handler
    request.addEventListener('load', () => {
        onReady(request.response)
    })

    // Error handler
    request.addEventListener('error', onError)

    const formData = new FormData()
    formData.append('files', file)

    // send POST request to server
    request.send(formData)

    return request
}

export async function rawFileUploadPromise(
    file: File,
    uploadPath: string,
    onProgress?: (percent: number) => void
): Promise<any> {
    const result = new Promise<any>((resolve, reject) => {
        rawFileUpload(
            file,
            uploadPath,
            resolve,
            () => reject(new Error('Upload failed')),
            onProgress
        )
    })
    return await result
}
