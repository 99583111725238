import React from 'react'
import { Box, Popover as MuiPopover } from '@mui/material'

// https://mui.com/material-ui/react-popover/

interface PopoverProps {
    children: React.ReactNode
    popoverContent: React.ReactNode
}

export function Popover(props: PopoverProps) {
    const { children, popoverContent } = props
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Box onClick={handleClick} role="button" sx={{ cursor: 'pointer' }}>
                {children}
            </Box>
            <MuiPopover
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {popoverContent}
            </MuiPopover>
        </>
    )
}
