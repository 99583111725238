/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import _ from 'lodash'

import { runningInBrowser } from '@a10base/common/running-in-browser.js'
import { convertToLoggableObject } from '@a10base/common/loggable-object.js'

export function reportClientError(message: string, attributes?: unknown): void {
    if (!runningInBrowser) {
        return
    }

    const extra = attributes ? convertToLoggableObject(attributes) : {}

    if (_.isError(attributes)) {
        const msg = attributes.message
        if (msg === 'Failed to fetch' || msg === 'ResizeObserver loop limit exceeded') {
            console.log(message, attributes)
            return
        }
    }
    const __reportClientError = (window as any).__reportClientError
    if (__reportClientError === undefined) {
        console.log('__reportClientError is undefined')
        return
    }

    __reportClientError(message, extra)
}
