import { runningInBrowser } from '@a10base/common/running-in-browser.js'
import { logger } from './client-logger.js'

export function setCookieValue(name: string, value: string | undefined, daysToLive = 30): void {
    if (runningInBrowser) {
        logger.debug('Setting cookie', { name, value, daysToLive })
        const cookie = `${name}=${value ? encodeURIComponent(value) : ''}; max-age=${
            daysToLive * 24 * 60 * 60
        }; path=/;`
        document.cookie = cookie
    }
}

export function getCookieValue(name: string): string | undefined {
    if (runningInBrowser) {
        const cookies = document.cookie.split(';')
        for (const cookie of cookies) {
            const pair = cookie.split('=')
            if (name === pair[0].trim()) {
                return decodeURIComponent(pair[1])
            }
        }
    }
    return undefined
}
