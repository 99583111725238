import { JwtData, Person } from '@a10yll/common/index.js'
import { useAppSelector } from '../redux/redux-hooks.js'

export function useJwt(): JwtData | undefined {
    return useAppSelector(state => state.auth.jwt)
}

export function usePerson(pid: number | undefined | null): Person | undefined {
    const person = useAppSelector(state => (pid ? state.item.items.person[pid] : undefined))
    return person as unknown as Person | undefined
}
