import React from 'react'

import { UseAsyncFn } from '@a10base/frontend/hooks/index.js'
import { Button, ButtonProps, IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { Spinner } from '../Spinner.js'

export interface AsyncButtonProps
    extends UseAsyncFn,
        Pick<
            ButtonProps,
            | 'children'
            | 'sx'
            | 'disabled'
            | 'size'
            | 'startIcon'
            | 'endIcon'
            | 'variant'
            | 'color'
            | 'fullWidth'
        > {
    tooltip?: string
    mb?: number | string
    mt?: number | string
    mr?: number | string
    ml?: number | string
}
export const AsyncButton: React.FC<AsyncButtonProps> = props => {
    const {
        tooltip,
        processing,
        callFn,
        sx,
        mb,
        mt,
        mr,
        ml,
        children,
        disabled,
        variant,
        color,
        fullWidth,
    } = props
    const size = props.size ?? 'small'
    const startIcon =
        !props.endIcon && processing ? <Spinner spinning={true} fontSize={size} /> : props.startIcon
    const endIcon =
        props.endIcon && !startIcon && processing ? (
            <Spinner spinning={true} fontSize={size} />
        ) : (
            props.endIcon
        )
    const buttonElem = (
        <Button
            fullWidth={fullWidth}
            disabled={disabled || processing}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={callFn}
            sx={{ mr, ml, mb, mt, ...sx }}
            size={size}
            variant={variant}
            color={color}
        >
            {children}
        </Button>
    )
    return tooltip ? <Tooltip title={tooltip}>{buttonElem}</Tooltip> : buttonElem
}

export interface AsyncIconButtonProps
    extends UseAsyncFn,
        Pick<IconButtonProps, 'children' | 'sx' | 'disabled' | 'size' | 'color'> {
    tooltip?: string
    mb?: number | string
    mt?: number | string
    mr?: number | string
    ml?: number | string
}
export const AsyncIconButton: React.FC<AsyncIconButtonProps> = props => {
    const { tooltip, processing, callFn, mb, mt, mr, ml, sx, children, disabled, color } = props
    const size = props.size ?? 'small'
    const buttonElem = (
        <IconButton
            disabled={disabled || processing}
            onClick={callFn}
            size={size}
            color={color}
            sx={{ mr, ml, mb, mt, ...sx }}
        >
            {processing ? <Spinner spinning={true} fontSize={size} /> : children}
        </IconButton>
    )
    return tooltip ? <Tooltip title={tooltip}>{buttonElem}</Tooltip> : buttonElem
}
