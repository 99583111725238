import React, { useRef, useState } from 'react'
import CodeMirror_ from '@uiw/react-codemirror'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CodeMirror = CodeMirror_ as any // Didn't work without this
import { javascript as cmJs } from '@codemirror/lang-javascript'
import { less as cmLess } from '@codemirror/lang-less'
import { html as cmHtml } from '@codemirror/lang-html'
import { json as cmJson } from '@codemirror/lang-json'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material'
import { Close, OpenInFull } from '@mui/icons-material'

interface CodeEditorProps {
    type: 'less' | 'js' | 'html' | 'plain-text' | 'json'
    value: string
    dirty?: boolean
    onChange: (value: string) => void
    label?: string
    maxWidth?: string
    height?: string
    disabled?: boolean
    invalid?: boolean
}
export function CodeEditor(props: CodeEditorProps) {
    const { type, value, dirty, onChange, label, maxWidth, height, disabled, invalid } = props
    const [fullScreen, setFullScreen] = useState(false)
    const extensions = useRef(getExtensions(type))

    const border = invalid ? '2px solid red' : dirty ? '2px solid yellow' : '2px solid lightgrey'

    if (fullScreen) {
        return (
            <Dialog fullScreen open={fullScreen} onClose={() => setFullScreen(false)}>
                {label && <DialogTitle>{label}</DialogTitle>}
                <DialogContent>
                    <CodeMirror
                        value={value}
                        height="80vh"
                        extensions={extensions.current}
                        onChange={onChange}
                        editable={!disabled}
                        style={{ border }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<Close />} onClick={() => setFullScreen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    return (
        <div style={{ position: 'relative', alignSelf: 'stretch' }}>
            {label && (
                <Typography mb={0} variant="body2">
                    {label}
                </Typography>
            )}
            <CodeMirror
                value={value}
                height={height ?? '250px'}
                maxWidth={maxWidth}
                extensions={extensions.current}
                onChange={onChange}
                editable={!disabled}
                style={{ border, fontSize: '12px' }}
            />
            <IconButton
                onClick={() => setFullScreen(true)}
                sx={{
                    position: 'absolute',
                    top: label ? '20px' : 0,
                    right: 0,
                    marginRight: '5px',
                    marginTop: '5px',
                }}
            >
                <OpenInFull />
            </IconButton>
        </div>
    )
}

function getExtensions(type: 'less' | 'js' | 'html' | 'plain-text' | 'json') {
    switch (type) {
        case 'less':
            return [cmLess()]
        case 'js':
            return [cmJs()]
        case 'html':
            return [cmHtml()]
        case 'json':
            return [cmJson()]
        default:
            return undefined
    }
}
