import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    Ref,
    CSSProperties,
} from 'react'

import { logger } from '../util/client-logger.js'

interface ThrottledTextareaProps {
    value: string
    throttleMs: number
    className?: string
    disabled?: boolean
    placeholder?: string
    maxLength?: number
    sx?: CSSProperties
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void // useMemo!
    onThrottledChange: (input: string, focused: boolean) => void // useMemo ja tän kanssa oikeesti tarkkana ettei tää jatkuvasti muutu!
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void // useMemo!
    onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void // useMemo!
    onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void // useMemo!
}

// TODO: change name
export interface JizzTextareaRef {
    focus: () => void
}

// https://gist.github.com/Venryx/7cff24b17867da305fff12c6f8ef6f96

export const ThrottledTextarea = React.forwardRef(function JizzTextareaFn(
    props: ThrottledTextareaProps,
    ref: Ref<JizzTextareaRef>
) {
    const {
        value,
        throttleMs,
        className,
        disabled,
        placeholder,
        maxLength,
        sx,
        onChange,
        onThrottledChange,
        onBlur,
        onFocus,
        onKeyDown,
    } = props
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    const lastThrottledText = useRef<string>(value)
    const lastThrottledTs = useRef<number>(0)
    const isFocused = useRef<boolean>(false)

    const onChangeRef = useRef(onChange)
    onChangeRef.current = onChange

    const onThrottledChangeRef = useRef(onThrottledChange)
    onThrottledChangeRef.current = onThrottledChange

    const onBlurRef = useRef(onBlur)
    onBlurRef.current = onBlur

    const onFocusRef = useRef(onFocus)
    onFocusRef.current = onFocus

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (textareaRef.current) {
                textareaRef.current.focus()
            }
        },
    }))

    const triggerThrottledChangeIfInputChanged = useCallback(() => {
        if (textareaRef.current) {
            const text = textareaRef.current.value
            if (text !== lastThrottledText.current) {
                lastThrottledText.current = text
                lastThrottledTs.current = new Date().getTime()
                logger.debug(`Call onThrottledChange with value "${text}"`)
                onThrottledChangeRef.current(text, isFocused.current || false)
            }
        }
    }, [])

    useEffect(() => {
        let intervalHandle: number | undefined = undefined
        if (!disabled) {
            intervalHandle = setInterval(
                triggerThrottledChangeIfInputChanged,
                throttleMs
            ) as unknown as number
        }
        return () => {
            if (intervalHandle !== undefined) {
                clearInterval(intervalHandle)
            }
        }
    }, [throttleMs, disabled, triggerThrottledChangeIfInputChanged])

    useEffect(() => {
        return triggerThrottledChangeIfInputChanged
    }, [triggerThrottledChangeIfInputChanged])

    const onBlurHandler = useCallback((event: React.FocusEvent<HTMLTextAreaElement>) => {
        onBlurRef.current?.(event)
        isFocused.current = false
    }, [])

    const onFocusHandler = useCallback((event: React.FocusEvent<HTMLTextAreaElement>) => {
        onFocusRef.current?.(event)
        isFocused.current = true
    }, [])

    const onChangeHandler = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChangeRef.current(event)
            if (lastThrottledTs.current + throttleMs < new Date().getTime()) {
                triggerThrottledChangeIfInputChanged()
            }
        },
        [throttleMs, triggerThrottledChangeIfInputChanged]
    )

    return (
        <textarea
            value={value}
            ref={textareaRef}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onKeyDown={onKeyDown}
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            style={sx}
        ></textarea>
    )
})
