/* eslint-disable @typescript-eslint/no-explicit-any */
import type * as schema from 'zapatos/schema'

import {
    JwtUpdated,
    OnlineStatusChanged,
    RemoveFlashNotification,
    ShowFlashNotification,
    UrlChanged,
    VisibilityChanged,
    WindowResized,
    WsMessage,
    WsReadyStateChanged,
} from '@a10base/frontend/message-bus/index.js'

import { authActions } from './reducers/auth-slice.js'
import { contextActions } from '@a10base/frontend/redux/reducers/context-slice.js'
import { notificationActions } from '@a10base/frontend/redux/reducers/flash-notification-slice.js'
import { urlActions } from '@a10base/frontend/redux/reducers/url-slice.js'
import { AsyncMessage } from '@a10yll/common/index.js'
import { getNumber, getValueDeep, unixTime } from '@a10base/common/index.js'
import { itemActions } from './item-actions.js'
import { store } from './store.js'
import { MyUserPresenceChanged, subscribeToMessage } from '../message-bus/index.js'
import { presenceActions } from './reducers/index.js'
import { getClientId } from '@a10base/frontend/util/client-id.js'
import { logger } from '@a10base/frontend/util/index.js'

export function routeMessageBusToRedux(): void {
    subscribeToMessage('jwt-updated', (message: JwtUpdated) => {
        store.dispatch(authActions.setJwt(message.jwt))
    })

    subscribeToMessage('online-status-changed', (message: OnlineStatusChanged) => {
        store.dispatch(contextActions.setOnline(message.online))
    })

    subscribeToMessage('visibility-changed', (message: VisibilityChanged) => {
        store.dispatch(contextActions.setVisible(message.visible))
    })

    subscribeToMessage('window-resized', (message: WindowResized) => {
        store.dispatch(
            contextActions.setWindowSize({ height: message.height, width: message.width })
        )
    })

    subscribeToMessage('show-flash-notification', (message: ShowFlashNotification) => {
        store.dispatch(
            notificationActions.addNotification({
                id: message.id,
                type: message.messageType,
                message: message.message,
                details: message.details,
            })
        )
    })

    subscribeToMessage('remove-flash-notification', (message: RemoveFlashNotification) => {
        store.dispatch(notificationActions.removeNotification(message.id))
    })

    subscribeToMessage('url-changed', (message: UrlChanged) => {
        store.dispatch(urlActions.updateUrl(message))
    })

    subscribeToMessage('ws-ready-state-changed', (message: WsReadyStateChanged) => {
        store.dispatch(contextActions.setWsReadyState(message.newState))
    })

    subscribeToMessage('my-user-presence-changed', (message: MyUserPresenceChanged) => {
        store.dispatch(
            presenceActions.setPresence({
                clientId: getClientId(),
                ts: message.active ? unixTime() : 0,
            })
        )
    })

    subscribeToMessage('ws-message', ({ message }: WsMessage<AsyncMessage>) => {
        switch (message.type) {
            case 'user-presence':
                store.dispatch(
                    presenceActions.setPresence({
                        clientId: message.data.clientId,
                        ts: message.data.ts,
                    })
                )
                break
            case 'cdc':
                {
                    const table = message.data.table as schema.TableWithIdAndVersion
                    const row = message.data.row as any
                    const id = getNumber(row, 'id')
                    if (!id) {
                        logger.warn('Received CDC message without id:', message)
                        return
                    }
                    if (message.data.op === 'c') {
                        store.dispatch(itemActions.upsertItem(table, row))
                    } else if (message.data.op === 'u') {
                        // Updates are always deltas so we need to merge the changes with existing item.
                        // If there is no existing item, we need to fetch the full item.
                        const state = store.getState()
                        const existingItem = getValueDeep(state.item.items, [table, String(id)])
                        if (existingItem) {
                            // Merge with existing item
                            store.dispatch(
                                itemActions.upsertItem(table, { ...existingItem, ...row })
                            )
                        } else {
                            // Do nothing, the item will be fetched when needed
                        }
                    } else if (message.data.op === 'd') {
                        store.dispatch(itemActions.deleteItem(table, id))
                    }
                }
                break
            case 'logout-client':
                // TODO
                break
        }
    })
}
