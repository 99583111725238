import * as yup from 'yup'
import { id, isoDateTimeString, objectStrict } from './common.js'

export const changeUser = objectStrict({
    userId: id,
}).defined()
export type ChangeUser = yup.Asserts<typeof changeUser>

export const clientError = objectStrict({
    message: yup.string().trim().max(4000).required(),
    error: yup.string().trim().max(4000),
    stack: yup.string().trim().max(10000),
    pathname: yup.string().trim().max(512),
    ts: isoDateTimeString.required(),
    online: yup.boolean(),
    extra: yup.mixed().nullable(),
    source: yup.string().max(32).required(),
}).defined()
// export type ClientError = Omit<yup.Asserts<typeof clientError>, 'ts'> & { ts: schema.TimestampTzString }
export type ClientError = yup.Asserts<typeof clientError>

export const clientErrors = objectStrict({
    errors: yup.array(clientError.required()).required(),
}).defined()
export interface ClientErrors {
    errors: ClientError[]
}

export const createTaskQueueTask = objectStrict({
    type: yup.string().required(),
    dataJson: yup.string().required(),
    priority: yup.number().integer(),
}).defined()
export type CreateTaskQueueTask = yup.Asserts<typeof createTaskQueueTask>

export const changeLanguage = objectStrict({
    lang: yup.string().length(2).required(),
    redirectTo: yup.string().max(512).optional(),
}).defined()
export type ChangeLanguage = yup.Asserts<typeof changeLanguage>

export const lockPerson = objectStrict({
    lockReason: yup.string().max(256),
}).defined()
export type LockPerson = yup.Asserts<typeof lockPerson>

export const getItems = objectStrict({
    table: yup.string().required(),
    filters: yup.array(yup.string().max(128)),
    search: yup.string().max(64),
    orderBy: yup.string().max(64),
    orderDir: yup.string().oneOf(['DESC', 'ASC']),
    limit: yup.number().max(1000),
    offset: yup.number(),
})
