import { WebToMobileMessage } from '../types/mobile-app.js'
import { reportClientError } from './client-error-reporter.js'
import { logger } from './client-logger.js'
import { getStrFromLocalStorage } from './localstorage.js'
import { serverData } from './server-data.js'

export function sendMessageToMobileApp(message: WebToMobileMessage): void {
    if (serverData.config.RUNS_IN_MOBILE_APP) {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const w = window as any
            if (w && w.ReactNativeWebView?.postMessage) {
                w.ReactNativeWebView.postMessage(JSON.stringify(message))
            } else {
                if (getStrFromLocalStorage('simulate-mobile-app') !== 'simulate') {
                    throw new Error(`window.ReactNativeWebView.postMessage is not defined`)
                }
            }
        } catch (error) {
            logger.warn('Failed to send message to mobile app', error)
            reportClientError(`Failed to send message to mobile app`, error)
        }
    }
}
