import { useCallback, useEffect, useRef, useState } from 'react'

import { parseNumber } from '@a10base/common/misc.js'
import { useThrottled3 } from '@a10base/frontend/hooks/index.js'
import { TableProps } from './Table.js'
import { SearchParams, updateUrlQuery } from '@a10base/frontend/util/index.js'

export type UseTableState = Pick<
    TableProps<unknown>,
    'page' | 'searchText' | 'pageSize' | 'onChangePage' | 'onChangeSearchText' | 'onChangePageSize'
>

export function useUrlTableState(
    searchParams: SearchParams,
    paramPrefix: string,
    defaultPageSize = 25
): UseTableState {
    const pageKey = `${paramPrefix}-page`
    const searchKey = `${paramPrefix}-search`
    const pageSizeKey = `${paramPrefix}-page-size`

    const pageStr = searchParams.first[pageKey]
    const searchText = searchParams.first[searchKey]
    const pageSizeStr = searchParams.first[pageSizeKey]

    const onChangePage = useCallback(
        (newPage: number) => {
            updateUrlQuery({ [pageKey]: String(newPage) })
        },
        [pageKey]
    )

    const onChangeSearchText = useCallback(
        (newSearchText: string) => {
            updateUrlQuery({ [searchKey]: newSearchText, [pageKey]: '0' })
        },
        [searchKey, pageKey]
    )

    const onChangePageSize = useCallback(
        (newPageSize: number) => {
            updateUrlQuery({ [pageSizeKey]: String(newPageSize), [pageKey]: '0' })
        },
        [pageSizeKey, pageKey]
    )

    return {
        page: parseNumber(pageStr) ?? 0,
        searchText,
        pageSize: parseNumber(pageSizeStr) ?? defaultPageSize,
        onChangePage,
        onChangeSearchText,
        onChangePageSize,
    }
}

export function useTableState(initialPageSize = 10, initialSearchText = ''): UseTableState {
    const [page, setPage] = useState(0)
    const [searchText, setSearchText] = useState(initialSearchText)
    const [pageSize, setPageSize] = useState(initialPageSize)

    const onChangeSearchText = useCallback((newSearchText: string) => {
        setSearchText(newSearchText)
        setPage(0)
    }, [])

    const onChangePageSize = useCallback((newPageSize: number) => {
        setPageSize(newPageSize)
        setPage(0)
    }, [])

    return {
        page,
        searchText,
        pageSize,
        onChangePage: setPage,
        onChangeSearchText,
        onChangePageSize,
    }
}

export interface UseAsyncTable<T> {
    rows: T[]
    loadingRows: boolean
    reloadRows: () => void
}

export function useAsyncTable<T>(
    loader: (offset: number, limit: number, searchText?: string) => Promise<T[]>, // useCallback
    options: { page: number; pageSize: number; searchText?: string }
): UseAsyncTable<T> {
    const loaderRef = useRef(loader)
    loaderRef.current = loader
    const [rows, setRows] = useState<T[]>([])
    const [loadingRows, setLoadingRows] = useState(false)
    const [refesh, setRefresh] = useState<number>(0)

    const loadRows = useCallback((offset: number, limit: number, searchText?: string) => {
        setLoadingRows(true)
        loaderRef
            .current(offset, limit, searchText)
            .then(rows => {
                setRows(rows) //.slice(0, limit - 1))
            })
            .catch(() => null)
            .finally(() => setLoadingRows(false))
    }, [])

    const throttledLoadRows = useThrottled3(loadRows, 1000, { trailing: true, leading: true })

    const reloadRows = useCallback(() => {
        setRefresh(v => v + 1)
    }, [])

    useEffect(() => {
        setLoadingRows(true)
        const offset = options.page * options.pageSize
        const limit = options.pageSize // + 1
        throttledLoadRows(offset, limit, options.searchText)
    }, [options.page, options.pageSize, options.searchText, throttledLoadRows, loader, refesh])

    return { rows, loadingRows, reloadRows }
}
