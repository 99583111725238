/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction, ThunkAction, Action } from '@reduxjs/toolkit'

import type * as schema from 'zapatos/schema'

import { getNumber } from '@a10base/common/misc.js'
import { publishMessageBase } from '@a10base/frontend/message-bus/message-bus.js'
import { serverData } from '@a10base/frontend/util/server-data.js'
import { ItemDicts, ItemWithIdAndVersion } from '@a10base/common/index.js'

type AppThunk = ThunkAction<void, any, any, Action<string>>

export interface TableState {
    items: ItemDicts
}

const initialState: TableState = {
    items: serverData.tablesWithIdAndVersion.reduce(
        (accu, table) => ({
            ...accu,
            [table]: serverData.initialItems?.[table] ?? {},
        }),
        {}
    ) as ItemDicts,
}

export const itemSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        __upsertItems(
            state,
            action: PayloadAction<{
                table: string
                items: ItemWithIdAndVersion[]
            }>
        ) {
            const { table, items } = action.payload
            for (const item of items) {
                const items = state.items[table]
                const existingItem = items[item.id]
                if (shouldUpdateItem(existingItem, item)) {
                    items[item.id] = item
                }
            }
        },
        __deleteItems(
            state,
            action: PayloadAction<{
                table: string
                ids: number[]
            }>
        ) {
            const { table, ids } = action.payload
            if (state.items[table]) {
                ids.forEach(id => {
                    delete state.items[table][id]
                })
            }
        },
        clearTable(state, action: PayloadAction<string>) {
            const table = action.payload
            state.items[table] = {}
        },
        clearAllTables(state) {
            for (const key in state.items) {
                state.items[key] = {}
            }
        },
    },
})

// This fixes the typings of '__upsertItems' and handles some special cases.
// NOTE: Use always this instead of '__upsertItems'!
export function upsertItemsRaw<Table extends string, Item extends ItemWithIdAndVersion>(
    table: Table,
    items: Item[]
): AppThunk {
    return dispatch => {
        dispatch(itemSlice.actions.__upsertItems({ table, items }))
        publishMessageBase({ type: 'items-upserted-to-store', table, items })
    }
}

export function upsertItemRaw<Table extends string, Item extends ItemWithIdAndVersion>(
    table: Table,
    item: Item
): AppThunk {
    return dispatch => {
        dispatch(itemSlice.actions.__upsertItems({ table, items: [item] }))
        publishMessageBase({ type: 'items-upserted-to-store', table, items: [item] })
    }
}

export function deleteItemsRaw<Table extends string = schema.TableWithIdAndVersion>(
    table: Table,
    ids: number[]
): AppThunk {
    return dispatch => {
        dispatch(itemSlice.actions.__deleteItems({ table, ids }))
        publishMessageBase({ type: 'items-deleted-from-store', table, ids })
    }
}

export function deleteItemRaw<Table extends string = schema.TableWithIdAndVersion>(
    table: Table,
    id: number
): AppThunk {
    return dispatch => {
        dispatch(itemSlice.actions.__deleteItems({ table, ids: [id] }))
        publishMessageBase({ type: 'items-deleted-from-store', table, ids: [id] })
    }
}

export const itemReducer = itemSlice.reducer

function shouldUpdateItem(
    oldItem: ItemWithIdAndVersion | undefined,
    newItem: ItemWithIdAndVersion
): boolean {
    if (oldItem?.version === undefined) {
        return true
    }
    if (oldItem.version < newItem.version) {
        return true
    }
    if (oldItem.version === newItem.version) {
        // censor service adds __censored:number to items that it has censored (larger value <=> more censoring)
        const oldCensorLevel = getNumber(oldItem, '__censored') ?? 0
        const newCensorLevel = getNumber(newItem, '__censored') ?? 0
        if (newCensorLevel < oldCensorLevel) {
            return true
        }
    }
    return false
}
