import * as yup from 'yup'
import { objectStrict } from '@a10base/common/yup/index.js'

export const emailLogin = objectStrict({
    email: yup.string().trim().max(128).email().required(),
    password: yup.string().min(6).max(128).required(),
}).defined()
export type EmailLogin = yup.Asserts<typeof emailLogin>

export const emailLoginMfa = objectStrict({
    token: yup.string().min(10).max(128).required(),
    code: yup.string().min(4).max(8).required(),
}).defined()
export type EmailLoginMfa = yup.Asserts<typeof emailLoginMfa>

export const setPassword = objectStrict({
    password: yup.string().min(8).max(128).required(),
}).defined()
export type SetPassword = yup.Asserts<typeof setPassword>

export const changePassword = objectStrict({
    oldPassword: yup.string().max(128).required(),
    newPassword: yup.string().min(8).max(128).required(),
}).defined()
export type ChangePassword = yup.Asserts<typeof changePassword>

export const forgottenPassword = objectStrict({
    email: yup.string().trim().max(128).email().required(),
}).defined()
export type ForgottenPassword = yup.Asserts<typeof forgottenPassword>
