import type { KeyboardEvent } from 'react'

export function rectsOverlapY(r1: DOMRect, r2: DOMRect): boolean {
    // rects overlap vertically if one rect has its 'top point' inside the other
    return (r1.top >= r2.top && r1.top <= r2.bottom) || (r2.top >= r1.top && r2.top <= r1.bottom)
}

export function isEnter(event: KeyboardEvent<HTMLElement>): boolean {
    return event.key === 'Enter'
}

export function isMetaEnter(event: KeyboardEvent<HTMLElement>): boolean {
    return event.key === 'Enter' && isMetaKey(event)
}

export function isMetaKey(event: KeyboardEvent<HTMLElement>): boolean {
    return event.ctrlKey || event.metaKey || event.shiftKey
}

export function isEscape(event: KeyboardEvent<HTMLElement>): boolean {
    return event.key === 'Esc' || event.key === 'Escape'
}

export function isDown(event: KeyboardEvent<HTMLElement>): boolean {
    return event.key === 'Down' || event.key === 'ArrowDown'
}

export function isUp(event: KeyboardEvent<HTMLElement>): boolean {
    return event.key === 'Up' || event.key === 'ArrowUp'
}

export function encodeHtmlCode(html: string): string {
    return html.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
}

export function cn(...classes: (string | undefined | boolean | null)[]): string {
    return classes.filter(Boolean).join(' ')
}
