import React, { ReactElement } from 'react'
import { Button } from '@mui/material'

import { lastX } from '@a10base/common/misc.js'
import { ExternalLink } from './ExternalLink.js'
import { serverData } from '../util/server-data.js'
import { navigateTo } from '../util/history.js'

// Renders text with some decorations:
// 1. replaces \n with <br />
// 2. replaces urls ('http....') with <a> or <Link> tags.

const urlRegExp =
    /(?:http:\/\/localhost:\d{4,6}[^\s]*)|(?:(?:(?:https?:\/\/[^.\s]+)|(?:www))(?:[.][^.\s]+)+)/gim

interface TextElement {
    text?: string
    element: ReactElement | string
}

interface DecoratedTextProps {
    text: string | null | undefined
    decorateUrls?: boolean
}
export const DecoratedText = React.memo(function DecoratedText({
    text,
    decorateUrls,
}: DecoratedTextProps) {
    const elements: TextElement[] = []
    if (text === undefined || text === null) {
        return null
    }
    text.split('\n').forEach((line, index, lines) => {
        elements.push({ text: line, element: <span>{line}</span> })
        if (index < lines.length - 1) {
            elements.push({ element: <br /> })
        }
    })
    if (decorateUrls && (text.includes('http') || text.includes('www'))) {
        const matches = text.match(urlRegExp) || []
        for (const match of matches) {
            const matchElement: TextElement = {
                element: match.includes(serverData.config.APP_URL) ? (
                    <Button
                        variant="text"
                        onClick={() => navigateTo(lastX(match.split(serverData.config.APP_URL)))}
                    >
                        {match}
                    </Button>
                ) : (
                    <ExternalLink href={match}>{match}</ExternalLink>
                ),
            }
            for (let i = 0; i < elements.length; ++i) {
                const text = elements[i].text
                if (text && text.includes(match)) {
                    const newElements: TextElement[] = []
                    text.split(match).forEach((part, index, parts) => {
                        newElements.push({ text: part, element: part })
                        if (index < parts.length - 1) {
                            newElements.push(matchElement)
                        }
                    })
                    elements.splice(i, 1, ...newElements)
                    i += newElements.length
                }
            }
        }
    }

    return (
        <React.Fragment>
            {elements.map((v, index) => (
                <span key={index}>{v.element}</span>
            ))}
        </React.Fragment>
    )
})

// TODO: tässä parempi:

// interface MessageBodyProps {
//     body: string
//   }
//   export const MessageBody = ({ body }: MessageBodyProps) => {
//     const elements = React.useMemo(() => {
//       return splitMessage(body).map((part, index) => {
//         switch (part.type) {
//           case 'text':
//             return <span key={index}>{part.text}</span>
//           case 'newLine':
//             return <br key={index} />
//           case 'url':
//             return (
//               <a key={index} href={part.url}>
//                 {part.url}
//               </a>
//             )
//         }
//       })
//     }, [body])

//     return <>{elements}</>
//   }

//   interface TextElement {
//     type: 'text'
//     text: string
//   }
//   interface NewLineElement {
//     type: 'newLine'
//   }
//   interface UrlElement {
//     type: 'url'
//     url: string
//   }
//   type MessageElement = TextElement | NewLineElement | UrlElement

//   function splitMessage(message: string): MessageElement[] {
//     const elements: MessageElement[] = []
//     const urls = message.match(/(?:https?:\/\/[^\s]+)/gim) ?? []
//     for (const url of urls) {
//       const index = message.indexOf(url)
//       const text = message.slice(0, index)
//       elements.push(...splitTextWithNewlines(text))
//       elements.push({ type: 'url', url })
//       message = message.slice(index + url.length)
//     }
//     elements.push(...splitTextWithNewlines(message))
//     return elements
//   }

//   function splitTextWithNewlines(text: string): Array<TextElement | NewLineElement> {
//     const elements: Array<TextElement | NewLineElement> = []
//     const lines = text.split('\n')
//     for (const line of lines) {
//       if (line.length > 0) {
//         elements.push({ type: 'text', text: line })
//       }
//       elements.push({ type: 'newLine' })
//     }
//     if (text.endsWith('\n')) {
//       elements.pop() // Remove unnecessary last newLine
//     }
//     return elements
//   }
