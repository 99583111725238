import { logger } from '../util/client-logger.js'
import { MessageBusMessageBase } from './messages.js'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MessageBusSubscriber = (message: any) => void

const subscribers: Record<string, MessageBusSubscriber[] | undefined> = {}

export function subscribeToMessageBase<T extends { type: string }>(
    type: T['type'],
    subscriber: MessageBusSubscriber
) {
    logger.debug('subscribeToMessage', { type })
    let subs = subscribers[type]
    if (!subs) {
        subs = []
        subscribers[type] = subs
    }
    subs.push(subscriber)
    if (subs.length > 10) {
        logger.warn('subscribeToMessage: too many subscribers', { type, count: subs.length })
    }
    return () => {
        unsubscribeFromMessageBase(type, subscriber)
    }
}

export function unsubscribeFromMessageBase<T extends { type: string } = MessageBusMessageBase>(
    type: T['type'],
    subscriber: MessageBusSubscriber
) {
    logger.debug('unsubscribeFromMessage', { type })
    if (!subscribers[type]) {
        return
    }
    subscribers[type] = subscribers[type].filter(s => s !== subscriber)
}

export function publishMessageBase<T extends { type: string } = MessageBusMessageBase>(message: T) {
    const subs = subscribers[message.type]
    logger.debug('publishMessage', { message, subscribers: (subs ?? []).length })
    if (!subs || subs.length === 0) {
        return
    }
    if (subs.length === 1) {
        subs[0](message)
    } else {
        subs.forEach(notify => notify(message))
    }
}
