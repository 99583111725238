import _ from 'lodash'

export interface A10BaseErrorData {
    message: string
    data?: {
        stack?: string
        query?: string
        details?: string
        [key: string]: unknown
    }
}

export interface GeneralA10Error extends A10BaseErrorData {
    type: 'general'
}
export interface BadRequestError extends A10BaseErrorData {
    type: 'bad-request'
}
export interface ValidationError extends A10BaseErrorData {
    type: 'validation'
}
export interface StaleResourceError extends A10BaseErrorData {
    type: 'stale-resource'
}
export interface NotFoundError extends A10BaseErrorData {
    type: 'not-found'
}
export interface AlreadyExistsError extends A10BaseErrorData {
    type: 'already-exists'
}
export interface AuthError extends A10BaseErrorData {
    type: 'auth'
}
export interface ForbiddenError extends A10BaseErrorData {
    type: 'forbidden'
}
export interface LoginError extends A10BaseErrorData {
    type: 'login'
}
export interface SignupError extends A10BaseErrorData {
    type: 'signup'
}
export interface JwtMissingError extends A10BaseErrorData {
    type: 'jwt-missing'
}
export interface JwtExpiredError extends A10BaseErrorData {
    type: 'jwt-expired'
}
export interface JwtError extends A10BaseErrorData {
    type: 'jwt'
}
export interface TooManyRequest extends A10BaseErrorData {
    type: 'too-many-requests'
}

export type A10ErrorData =
    | GeneralA10Error
    | BadRequestError
    | ValidationError
    | StaleResourceError
    | NotFoundError
    | AlreadyExistsError
    | AuthError
    | ForbiddenError
    | LoginError
    | SignupError
    | JwtMissingError
    | JwtExpiredError
    | JwtError
    | TooManyRequest

export interface A10ErrorResponse {
    error: A10ErrorData
}

export class A10Error extends Error {
    a10ErrorData: A10ErrorData
    isA10Error: true
    constructor(errorData: A10ErrorData) {
        super(errorData.message)
        this.a10ErrorData = errorData
        this.isA10Error = true
    }
}

export function isA10Error(error: unknown): error is A10Error {
    return _.isObject(error) && (error as never)['isA10Error'] === true
}
