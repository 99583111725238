import React from 'react'
import { Button, ButtonProps, IconButton } from '@mui/material'
import { Cancel as CancelIcon } from '@mui/icons-material'

interface CancelButtonProps
    extends Pick<ButtonProps, 'sx' | 'size' | 'variant' | 'disabled' | 'color'> {
    onCancel: () => void
    text?: string
    onlyIcon?: boolean
    mr?: number | string
    ml?: number | string
}

export const CancelButton: React.FC<CancelButtonProps> = props => {
    const { onCancel, onlyIcon, sx, variant, disabled, text, mr, ml } = props
    const size = props.size ?? 'small'
    const color = props.color ?? 'primary'
    if (onlyIcon) {
        return (
            <IconButton
                size={size}
                color={color}
                sx={{ mr, ml, ...sx }}
                disabled={disabled}
                onClick={onCancel}
            >
                <CancelIcon fontSize={size} />
            </IconButton>
        )
    }
    return (
        <Button
            size={size}
            variant={variant}
            color={color}
            sx={{ mr, ml, ...sx }}
            disabled={disabled}
            onClick={onCancel}
        >
            {text ?? 'Cancel'}
        </Button>
    )
}
