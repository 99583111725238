import React from 'react'
import {
    CSSObject,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material'

interface KeyValue {
    key: string
    value: React.ReactNode
}

interface KeyValueTableProps {
    rows: KeyValue[] // Use useMemo
    sx?: CSSObject
}

export function KeyValueTable({ rows, sx }: KeyValueTableProps) {
    const theme = useTheme()
    return (
        <Table sx={{ width: sx?.width ?? 'inherit', ...sx }}>
            <TableBody>
                {rows.map((row, index) => {
                    const valueStr = getStringRepresentation(row.value)
                    return (
                        <TableRow
                            key={index}
                            sx={{
                                '&:nth-of-type(odd)': {
                                    backgroundColor: theme.palette.grey[50],
                                },
                            }}
                        >
                            <TableCell sx={{ borderWidth: 0 }}>
                                <Typography variant="body2" color={theme.palette.grey[600]}>
                                    {row.key}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ borderWidth: 0 }}>
                                {valueStr !== undefined ? (
                                    <Typography variant="body1">{valueStr}</Typography>
                                ) : (
                                    row.value
                                )}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

function getStringRepresentation(value: React.ReactNode): string | undefined {
    if (typeof value === 'string') {
        return value
    }
    if (typeof value === 'number') {
        return String(value)
    }
    if (typeof value === 'boolean') {
        return value ? 'true' : 'false'
    }
    return undefined
}
