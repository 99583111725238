/* eslint-disable @typescript-eslint/no-explicit-any */
import { diff_match_patch, patch_obj } from 'diff-match-patch'

// https://www.npmjs.com/package/diff-match-patch

const dmp = new diff_match_patch()

export type Patch = patch_obj

// type Diff = Array<[number, string]>

// export function makeDiffs(text1: string, text2: string): Diff[] {
//     return dmp.diff_main(text1, text2)
// }

// export function diffPrettyHtml(diffs: Diff[]): string {
//     return dmp.diff_prettyHtml(diffs)
// }

export function makePatches(text1: string, text2: string): Patch[] {
    return dmp.patch_make(text1, text2) as unknown as Patch[]
}

export function patchesToTextPatch(patches: Patch[]): string {
    return dmp.patch_toText(patches as any)
}

export function textPatchToPatches(textPatch: string): Patch[] {
    return dmp.patch_fromText(textPatch) as unknown as Patch[]
}

export function applyPatches(text: string, patches: Patch[]): string {
    return dmp.patch_apply(patches as any, text)[0]
}

export function applyTextPatch(text: string, textPatch: string): string {
    const patches = textPatchToPatches(textPatch)
    return dmp.patch_apply(patches as any, text)[0]
}
