import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'

import { serverData } from '../util/server-data.js'

interface CurrencySelectorProps {
    value: string | undefined | null
    onChange: (currencyCode: string) => void
}
export function CurrencySelector({ value, onChange }: CurrencySelectorProps) {
    return (
        <FormControl sx={{ minWidth: '300px' }}>
            <InputLabel id="currency-selector-label">Currency</InputLabel>
            <Select
                labelId="currency-selector-label"
                label="Currency"
                value={value ?? ''}
                onChange={e => onChange(e.target.value)}
                fullWidth
            >
                {serverData.currencies.map(currency => (
                    <MenuItem key={currency.id} value={currency.code} sx={{ p: 1 }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            {currency.name}
                            <Typography variant="body1" color="grey">
                                {currency.code}
                            </Typography>
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
