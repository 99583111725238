import React, { useMemo } from 'react'
import { Button, IconButton, Stack } from '@mui/material'
import { Info as InfoIcon, Warning as WarningIcon, Close as CloseIcon } from '@mui/icons-material'

import { serverData, trpcBase } from '@a10base/frontend/util/index.js'
import { useInterval, useItemsRaw, useStateLS } from '@a10base/frontend/hooks/index.js'
import { GlobalNotification, jsonbToObjectX, TranslationDict } from '@a10base/common/index.js'
import { isAfter, isBefore } from 'date-fns'

export const GlobalNotificationBar: React.FC = () => {
    const checkInterval = useInterval(15 * 60 * 1000, 'GlobalNotificationBar: check')
    const [notifications] = useItemsRaw(
        'global_notification',
        () => trpcBase.misc.getGlobalNotifications.query(),
        `hacky way to force refresh ${checkInterval}`,
        { ignoreError: true }
    ) as [GlobalNotification[], boolean]

    const [hiddenNotificationIds, setHiddenNotificationIds] = useStateLS<number[]>(
        'hidden-global-notifications',
        []
    )
    const visibleNotifications = useMemo(() => {
        const now = new Date()
        return notifications.filter(
            n =>
                !hiddenNotificationIds.includes(n.id) &&
                n.show_in_app &&
                isBefore(n.valid_from, now) &&
                isAfter(n.valid_to, now)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hiddenNotificationIds, notifications, checkInterval])

    if (visibleNotifications.length === 0) {
        return null
    }

    return (
        <Stack
            direction="column"
            spacing={0.5}
            sx={{
                width: '100vw',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 9999,
                backgroundColor: 'white',
            }}
        >
            {visibleNotifications.map((notification, index) => (
                <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingX={2}
                    paddingY={1}
                    sx={{
                        backgroundColor: getBackgroundColor(notification),
                        boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {notification.type === 'info' ? (
                            <InfoIcon color="success" />
                        ) : (
                            <WarningIcon color="error" />
                        )}
                        <div dangerouslySetInnerHTML={{ __html: getMessage(notification) }}></div>
                        <Button
                            onClick={() =>
                                setHiddenNotificationIds(v => v.concat(notification.id).slice(-50))
                            }
                            variant="outlined"
                            color="primary"
                            size="small"
                        >
                            Ok
                        </Button>
                    </Stack>
                    <IconButton
                        onClick={() =>
                            setHiddenNotificationIds(v => v.concat(notification.id).slice(-50))
                        }
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    )
}

function getMessage(notification: GlobalNotification): string {
    const translations = jsonbToObjectX<TranslationDict>(notification.translations)
    let message = translations[serverData.config.CLIENT_LANG]
    if (!message) {
        message = translations[0]
    }
    return message ?? ''
}

function getBackgroundColor(notification: GlobalNotification): string {
    return notification.type === 'info' ? '#f0f9eb' : '#fef0f0'
}
