import safeJsonStringify from 'safe-json-stringify'

class Logger {
    public debugMode = false
    public debugConsoleOpen = localStorage.getItem('DEBUG_CONSOLE') === 'true'
    private latestLogMessages: Array<{ level: string; params: unknown; time: number }> = []

    constructor() {
        // do nothing
    }

    private log(level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR', ...params: unknown[]): void {
        if (level === 'ERROR') {
            // eslint-disable-next-line no-console
            console.error(level, ...params)
        } else {
            // eslint-disable-next-line no-console
            console.log(level, ...params)
        }

        if (this.debugMode) {
            this.latestLogMessages.unshift({ level, params, time: new Date().getTime() })
            this.latestLogMessages = this.latestLogMessages.slice(0, 100)
            if (this.debugConsoleOpen) {
                const debugConsoleElem = document.getElementById('debug-console')
                if (debugConsoleElem) {
                    debugConsoleElem.innerHTML = this.latestLogMessages
                        .map(
                            v => `<p>${v.time} ${v.level}: ${safeJsonStringify(v.params || {})}<p>`
                        )
                        .join('\n')
                }
            }
        }
    }
    debug(...params: unknown[]): void {
        if (this.debugMode) {
            this.log('DEBUG', ...params)
        }
    }
    info(...params: unknown[]): void {
        this.log('INFO', ...params)
    }
    warn(...params: unknown[]): void {
        this.log('WARN', ...params)
    }
    error(...params: unknown[]): void {
        this.log('ERROR', ...params)
    }
    setDebugMode(value: boolean): void {
        if (this.debugMode !== value) {
            // eslint-disable-next-line no-console
            console.log(`Debug mode = ${value}`)
            this.debugMode = value
        }
    }
    getLatestLogMessages(): Array<{ level: string; params: unknown }> {
        return this.latestLogMessages
    }
}

export const logger: Logger = new Logger()
