/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useRef, useState } from 'react'
import { useIsMountedRef } from './misc.js'
import { handleErrorAndNotify } from '../util/flash-notifications.js'

// Miksi nämä on tehty ja mihin näitä käytetään?:
//

export type AsyncFunction0<R> = () => Promise<R> | R
export type AsyncFunction1<P, R> = (p: P) => Promise<R> | R
export type AsyncFunction2<P1, P2, R> = (p1: P1, p2: P2) => Promise<R> | R
export type AsyncFunction3<P1, P2, P3, R> = (p1: P1, p2: P2, p3: P3) => Promise<R> | R

type AsyncFnInvoker<R> = (
    asyncFn: (...args: any[]) => Promise<R> | R,
    args: any[],
    onSuccess?: (result: R) => void,
    onError?: (error: unknown) => void, // use 'nop' to suppress error notification
    onFinally?: () => void
) => void
function useAsyncFnInvoker<R>(): [AsyncFnInvoker<R>, boolean] {
    const isMounted = useIsMountedRef()
    const [processing, setProcessing] = useState<boolean>(false)

    const asyncFnInvoker = useCallback<AsyncFnInvoker<R>>(
        (asyncFn, args, onSuccess, onError, onFinally) => {
            setProcessing(true)
            Promise.resolve(asyncFn(...args))
                .then(result => {
                    // NOTE: setProcessing(false) is not done in .finally(...) because we want to set this before calling onSuccess
                    // (reason: onSuccess might focus on some control that is disabled when processing function and if onSuccess is called before setProcessing(false) then focusing will fail.)
                    if (isMounted.current) {
                        setProcessing(false)
                    }
                    onSuccess?.(result)
                })
                .catch((error: unknown) => {
                    if (isMounted.current) {
                        setProcessing(false)
                    }
                    if (onError) {
                        onError(error)
                    } else {
                        handleErrorAndNotify(error)
                    }
                })
                .finally(onFinally)
        },
        [isMounted]
    )
    return [asyncFnInvoker, processing]
}

export interface UseAsyncFn {
    callFn: () => void
    processing: boolean
}
export function useAsyncFn<R>(
    fn: AsyncFunction0<R>,
    onSuccess?: (result: R) => void,
    onError?: (error: unknown) => void // use 'nop' to suppress error notification
): UseAsyncFn {
    const [asyncFnInvoker, processing] = useAsyncFnInvoker<R>()

    const refs = useRef<any>({ fn, onSuccess, onError })
    refs.current = { fn, onSuccess, onError }

    const callFn = useCallback(() => {
        const { fn, onSuccess, onError } = refs.current
        asyncFnInvoker(fn, [], onSuccess, onError)
    }, [asyncFnInvoker])
    return {
        callFn,
        processing,
    }
}

export interface UseAsyncFn1<P> {
    callFn: (p: P) => void
    processing: boolean
    param?: P
}
export function useAsyncFn1<P, R>(
    fn: AsyncFunction1<P, R>,
    onSuccess?: (result: R) => void,
    onError?: (error: unknown) => void // use 'nop' to suppress error notification
): UseAsyncFn1<P> {
    const [asyncFnInvoker, processing] = useAsyncFnInvoker<R>()

    const refs = useRef<any>({ fn, onSuccess, onError })
    refs.current = { fn, onSuccess, onError }

    const callFn = useCallback(
        (p: P) => {
            const { fn, onSuccess, onError } = refs.current
            refs.current.param = p
            asyncFnInvoker(fn, [p], onSuccess, onError, () => {
                refs.current.param = undefined
            })
        },
        [asyncFnInvoker]
    )
    return { callFn, processing, param: refs.current.param }
}

export interface UseAsyncFn2<P1, P2> {
    callFn: (p1: P1, p2: P2) => void
    processing: boolean
    param1?: P1
    param2?: P2
}
export function useAsyncFn2<P1, P2, R>(
    fn: AsyncFunction2<P1, P2, R>,
    onSuccess?: (result: R) => void,
    onError?: (error: unknown) => void // use 'nop' to suppress error notification
): UseAsyncFn2<P1, P2> {
    const [asyncFnInvoker, processing] = useAsyncFnInvoker<R>()

    const refs = useRef<any>({ fn, onSuccess, onError })
    refs.current = { fn, onSuccess, onError }

    const callFn = useCallback(
        (p1: P1, p2: P2) => {
            const { fn, onSuccess, onError } = refs.current
            refs.current.param1 = p1
            refs.current.param2 = p2
            asyncFnInvoker(fn, [p1, p2], onSuccess, onError, () => {
                refs.current.param1 = undefined
                refs.current.param2 = undefined
            })
        },
        [asyncFnInvoker]
    )
    return { callFn, processing, param1: refs.current.param1, param2: refs.current.param2 }
}

export interface UseAsyncFn3<P1, P2, P3> {
    callFn: (p1: P1, p2: P2, p3: P3) => void
    processing: boolean
    param1?: P1
    param2?: P2
    param3?: P3
}
export function useAsyncFn3<P1, P2, P3, R>(
    fn: AsyncFunction3<P1, P2, P3, R>,
    onSuccess?: (result: R) => void,
    onError?: (error: unknown) => void // use 'nop' to suppress error notification
): UseAsyncFn3<P1, P2, P3> {
    const [asyncFnInvoker, processing] = useAsyncFnInvoker<R>()

    const refs = useRef<any>({ fn, onSuccess, onError })
    refs.current = { fn, onSuccess, onError }

    const callFn = useCallback(
        (p1: P1, p2: P2, p3: P3) => {
            const { fn, onSuccess, onError } = refs.current
            refs.current.param1 = p1
            refs.current.param2 = p2
            refs.current.param3 = p3
            asyncFnInvoker(fn, [p1, p2, p3], onSuccess, onError, () => {
                refs.current.param1 = undefined
                refs.current.param2 = undefined
                refs.current.param3 = undefined
            })
        },
        [asyncFnInvoker]
    )
    return {
        callFn,
        processing,
        param1: refs.current.param1,
        param2: refs.current.param2,
        param3: refs.current.param3,
    }
}

export function useAsyncFn1Param<P, R>(
    fn: AsyncFunction1<P, R>,
    param: P,
    onSuccess?: (result: R) => void,
    onError?: (error: unknown) => void // use 'nop' to suppress error notification
): UseAsyncFn {
    const [asyncFnInvoker, processing] = useAsyncFnInvoker<R>()

    const refs = useRef<any>({ fn, param, onSuccess, onError })
    refs.current = { fn, param, onSuccess, onError }

    const callFn = useCallback(() => {
        const { fn, param, onSuccess, onError } = refs.current
        asyncFnInvoker(fn, [param], onSuccess, onError)
    }, [asyncFnInvoker])
    return { callFn, processing }
}

export function useAsyncFn2Param<P1, P2, R>(
    fn: AsyncFunction2<P1, P2, R>,
    param1: P1,
    param2: P2,
    onSuccess?: (result: R) => void,
    onError?: (error: unknown) => void // use 'nop' to suppress error notification
): UseAsyncFn {
    const [asyncFnInvoker, processing] = useAsyncFnInvoker<R>()

    const refs = useRef<any>({ fn, param1, param2, onSuccess, onError })
    refs.current = { fn, param1, param2, onSuccess, onError }

    const callFn = useCallback(() => {
        const { fn, param1, param2, onSuccess, onError } = refs.current
        asyncFnInvoker(fn, [param1, param2], onSuccess, onError)
    }, [asyncFnInvoker])
    return { callFn, processing }
}

export function useAsyncFn3Param<P1, P2, P3, R>(
    fn: AsyncFunction3<P1, P2, P3, R>,
    param1: P1,
    param2: P2,
    param3: P3,
    onSuccess?: (result: R) => void,
    onError?: (error: unknown) => void // use 'nop' to suppress error notification
): UseAsyncFn {
    const [asyncFnInvoker, processing] = useAsyncFnInvoker<R>()

    const refs = useRef<any>({ fn, param1, param2, param3, onSuccess, onError })
    refs.current = { fn, param1, param2, param3, onSuccess, onError }

    const callFn = useCallback(() => {
        const { fn, param1, param2, param3, onSuccess, onError } = refs.current
        asyncFnInvoker(fn, [param1, param2, param3], onSuccess, onError)
    }, [asyncFnInvoker])
    return { callFn, processing }
}
