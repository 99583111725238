import { v4 as uuidv4 } from 'uuid'

import { getCookieValue, setCookieValue } from './cookie.js'

let clientId: string | undefined

export function getClientId(): string {
    if (!clientId) {
        const id = getCookieValue('client-id')
        if (id) {
            clientId = id
        } else {
            clientId = uuidv4()
            setCookieValue('client-id', clientId, 100 * 365)
        }
    }
    return clientId
}
