import _ from 'lodash'

import { A10Error, A10ErrorData } from '@a10base/common/a10-error.js'
import { publishMessageBase } from '../message-bus/index.js'
import { logger } from './client-logger.js'
import { omit } from '@a10base/common/misc.js'

export function getDefaultHeaders(): Record<string, string> {
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        //'X-SESSION-UUID': String(serverData.config.SESSION_UUID),
    }
    return headers
}

export async function getResponseJson<Resp = unknown>(response: Response): Promise<Resp> {
    const responseJson = (await response.json()) as Resp
    if (!response.ok) {
        const a10ErrorData = _.get(responseJson, 'error')
        if (a10ErrorData !== undefined && _.has(a10ErrorData, 'type')) {
            throw new A10Error(a10ErrorData as A10ErrorData)
        } else {
            throw new Error(`API request failed: "${response.statusText}" (${response.status})`)
        }
    } else {
        return responseJson
    }
}

export async function get<Resp = unknown>(url: string): Promise<Resp> {
    const response = await fetchA10(url, { method: 'GET' })
    return await getResponseJson<Resp>(response)
}

export async function getTrpc<Resp = unknown>(
    methodUrl: string,
    input: unknown
): Promise<Resp | null> {
    const response = await get<{ result: { data: Resp } }>(
        `${methodUrl}?input=${encodeURIComponent(JSON.stringify(input))}`
    )
    return response?.result?.data ?? null
}

export async function httpDelete<Body = unknown, Resp = unknown>(
    url: string,
    data?: Body
): Promise<Resp> {
    let body: string | undefined = undefined
    if (data) {
        body = JSON.stringify(data)
    }
    const response = await fetchA10(url, {
        method: 'DELETE',
        body,
    })
    return await getResponseJson<Resp>(response)
}

export async function post<Body = unknown, Resp = unknown>(
    url: string,
    data?: Body,
    additionalHeaders?: HeadersInit
): Promise<Resp> {
    let body: string | undefined = undefined
    if (data) {
        body = JSON.stringify(data)
    }
    const response = await fetchA10(url, { method: 'POST', headers: additionalHeaders, body })
    return await getResponseJson<Resp>(response)
}

export async function patch<Body = unknown, Resp = unknown>(
    url: string,
    data: Body
): Promise<Resp> {
    const response = await fetchA10(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
    })
    return await getResponseJson<Resp>(response)
}

export function addQueryParam(
    path: string,
    paramName: string,
    paramValue?: string | number
): string {
    if (paramValue !== undefined) {
        const paramPart = `${paramName}=${encodeURIComponent(paramValue)}`
        if (path.indexOf('?') >= 0) {
            return `${path}&${paramPart}`
        } else {
            return `${path}?${paramPart}`
        }
    }
    return path
}

export function addVersionParam(path: string, version?: number): string {
    return addQueryParam(path, 'version', version)
}

export function fetchA10(input: string | URL | Request, init?: RequestInit): Promise<Response> {
    const combinedHeaders = combineHeaders(
        new Headers(getDefaultHeaders()),
        new Headers(init?.headers)
    )
    logger.debug('API request', { input: typeof input === 'string' ? decodeURI(input) : input })
    return fetch(input, { ...omit(init || {}, ['headers']), headers: combinedHeaders })
        .then(response => {
            if (response.headers.has('X-JWT-REFRESHED')) {
                const newJwtToken = response.headers.get('X-JWT-REFRESHED') || undefined
                publishMessageBase({ type: 'jwt-token-refeshed', token: newJwtToken })
            }
            return response
        })
        .catch(error => {
            publishMessageBase({ type: 'api-error', error })
            throw error
        })
        .finally(() => {
            // publishMessageBase({ type: 'api-request', id: requestId, method, loading: false })
        })
}

function combineHeaders(h1: Headers, h2: Headers): Headers {
    const newHeaders = new Headers(h1)
    h2.forEach((value, key) => {
        if (!newHeaders.has(key.toLowerCase())) {
            newHeaders.set(key.toLowerCase(), value)
        }
    })
    return newHeaders
}
