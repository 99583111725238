import type { AsyncMessageBase, BaseJwtData } from '@a10base/common/types/index.js'
import { reportClientError } from './client-error-reporter.js'
import { logger } from './client-logger.js'
import { publishMessageBase, subscribeToMessageBase } from '../message-bus/message-bus.js'
import { JwtUpdated, OnlineStatusChanged } from '../message-bus/messages.js'
import { showNotification } from './flash-notifications.js'
import { getClientId } from './client-id.js'

const reconnectDelayMs = 5 * 1000
const readyStateCheckIntervalMs = 1 * 1000
const pingIntervalMs = 30 * 1000

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MessageHandler = (message: any, jwt: BaseJwtData | undefined) => void
export type ReadyStateChangeHandler = (previousState: number | undefined, newState: number) => void

export class WebSocketClient {
    public webSocket: WebSocket | undefined
    private currentReadyState: number | undefined // 0 = CONNECTING, 1 = OPEN, 2 = CLOSING, 3 = CLOSED
    private wsUrl: string
    private nextReconnect = 0
    private readyStateCheckIntervalHandle: number | undefined
    private pingIntervalHandle: number | undefined
    private latestJwtToken: string | undefined
    private clientIdSent = false

    constructor(wsUrl: string) {
        this.wsUrl = wsUrl

        subscribeToMessageBase('online-status-changed', (message: OnlineStatusChanged) => {
            if (message.online) {
                this.nextReconnect = 0
                if (this.webSocket) {
                    this.reconnect()
                }
            }
        })

        subscribeToMessageBase('jwt-updated', (message: JwtUpdated) => {
            this.latestJwtToken = message.token
            sendWsMessageBase(this.webSocket, {
                type: 'client-jwt-token-updated',
                data: { token: message.token },
            })
        })
    }

    private setReadyState(readyState: number): void {
        if (this.currentReadyState !== readyState) {
            logger.debug(`ws: readyState changed ${this.currentReadyState} --> ${readyState}`)
            publishMessageBase({
                type: 'ws-ready-state-changed',
                previousState: this.currentReadyState,
                newState: readyState,
            })
            this.currentReadyState = readyState
        }
    }

    private reconnect(): void {
        logger.info(`ws: reconnecting "${this.wsUrl}" ...`)

        if (this.isConnectionActive()) {
            logger.debug('ws: no need to reconnect: connection is already active')
            return
        }
        this.disconnect()
        this.connect()
    }

    private isConnectionActive(): boolean {
        return (
            this.webSocket !== undefined &&
            (this.webSocket.readyState === WebSocket.OPEN ||
                this.webSocket.readyState === WebSocket.CONNECTING)
        )
    }

    disconnect(): void {
        logger.info('ws: disconnecting ...')
        if (this.pingIntervalHandle) {
            clearInterval(this.pingIntervalHandle)
            this.pingIntervalHandle = undefined
        }
        if (this.readyStateCheckIntervalHandle) {
            clearInterval(this.readyStateCheckIntervalHandle)
            this.readyStateCheckIntervalHandle = undefined
        }
        if (this.webSocket) {
            this.webSocket.close(1000, 'Disconnecting')
            this.webSocket = undefined
        }
    }

    connect(): void {
        logger.info(`ws: connecting "${this.wsUrl}" ...`)

        if (this.isConnectionActive()) {
            logger.debug('ws: no need to connect: connection is already active')
            return
        }

        try {
            this.webSocket = new WebSocket(this.wsUrl)

            this.webSocket.onopen = event => {
                logger.info('ws: connected', event)
                this.clientIdSent = false
                if (this.latestJwtToken) {
                    sendWsMessageBase(this.webSocket, {
                        type: 'ping',
                        data: { clientId: getClientId() },
                    })
                    sendWsMessageBase(this.webSocket, {
                        type: 'client-jwt-token-updated',
                        data: { token: this.latestJwtToken },
                    })
                }
            }

            this.webSocket.onerror = event => {
                if (this.currentReadyState === WebSocket.OPEN) {
                    // This event fires too often (for example when connecting to wlan) --> not sending these to server
                    // reportClientError(`ws 'error' event.`, event)
                    logger.info(`ws 'error' event`, event)
                }
            }

            this.webSocket.onclose = event => {
                logger.info('ws: close event', event)
            }

            this.webSocket.onmessage = (event: MessageEvent<string>) => {
                logger.debug(`ws: message event ${event.data}`)
                this.handleMessage(event.data)
            }

            this.setupPinging()
            this.setupReconnecting()
        } catch (error: unknown) {
            logger.warn(`Failed to connect to ws service`, { error, wsUrl: this.wsUrl })
        }
    }

    private handleMessage(payloadJson: string): void {
        try {
            const message = JSON.parse(payloadJson) as AsyncMessageBase
            switch (message.type) {
                case 'ping':
                    sendWsMessageBase(this.webSocket, {
                        type: 'pong',
                        //data: { clientId: ... },
                    })
                    break
                case 'pong':
                    break
                case 'reload-client':
                    window.location.reload()
                    break
                case 'notify-client':
                    showNotification({
                        message: message.data.message,
                        messageType: message.data.level,
                    })
                    break
                default: {
                    publishMessageBase({
                        type: 'ws-message',
                        message,
                    })
                }
            }
        } catch (error: unknown) {
            reportClientError('ws message handling failed', error)
        }
    }

    private setupPinging(): void {
        if (!this.pingIntervalHandle) {
            this.pingIntervalHandle = setInterval(() => {
                if (this.webSocket?.readyState === WebSocket.OPEN) {
                    if (!this.clientIdSent || Math.random() < 0.05) {
                        sendWsMessageBase(this.webSocket, {
                            type: 'ping',
                            data: { clientId: getClientId() },
                        })
                        this.clientIdSent = true
                    } else {
                        sendWsMessageBase(this.webSocket, { type: 'ping' })
                    }
                }
            }, pingIntervalMs) as unknown as number
        }
    }

    private setupReconnecting(): void {
        if (!this.readyStateCheckIntervalHandle) {
            this.readyStateCheckIntervalHandle = setInterval(() => {
                // ReadyState stuff
                if (this.webSocket !== undefined) {
                    this.setReadyState(this.webSocket.readyState)
                }
                // Reconnect stuff
                if (
                    this.webSocket === undefined ||
                    this.webSocket.readyState === WebSocket.CLOSED
                ) {
                    const ts = new Date().getTime()
                    if (this.nextReconnect < ts) {
                        this.nextReconnect =
                            ts + (navigator.onLine ? reconnectDelayMs : 20 * reconnectDelayMs)
                        this.reconnect()
                    }
                }
            }, readyStateCheckIntervalMs) as unknown as number
        }
    }

    // sendMessage(message: RemoveClientUuid<FrontendMsg>): void {
    //     if (this.webSocket !== undefined && this.webSocket.readyState === WebSocket.OPEN) {
    //         const messageWithClientUuid = {
    //             ...message,
    //             sessionUuid: clientConfig.SESSION_UUID,
    //         }
    //         try {
    //             logger.debug(`ws: sending message "${message.type}"`)
    //             this.webSocket.send(JSON.stringify(messageWithClientUuid))
    //         } catch (error: unknown) {
    //             reportClientError('ws: message sending failed', error)
    //         }
    //     } else {
    //         logger.info('ws: message was not sent because there is no connection')
    //     }
    // }
}

export function sendWsMessageBase<T extends { type: string } = AsyncMessageBase>(
    webSocket: WebSocket | undefined,
    message: T
): void {
    if (webSocket !== undefined && webSocket.readyState === WebSocket.OPEN) {
        try {
            logger.debug(`ws: sending message "${message.type}"`)
            webSocket.send(JSON.stringify(message))
        } catch (error: unknown) {
            reportClientError('ws: message sending failed', error)
        }
    } else {
        logger.info('ws: message was not sent because there is no connection')
    }
}

// type RemoveSessionUuid<T> = {
//     [P in keyof T as P extends 'sessionUuid' ? never : P]: T[P]
// }

// let wsClient: WebSocketClient | undefined = undefined
// export function getWsClient(
//     serverMessageHandler: ServerMessageHandler,
//     readyStateChangeHandler: ReadyStateChangeHandler
// ): WebSocketClient {
//     if (wsClient === undefined) {
//         wsClient = new WebSocketClient(
//             clientConfig.WEB_SOCKET_URL,
//             serverMessageHandler,
//             readyStateChangeHandler
//         )
//     }
//     return wsClient
// }
