import React from 'react'
import { Save as SaveIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'

import { AsyncButtonProps, AsyncButton, AsyncIconButton } from './AsyncButton.js'
import { CancelButton } from './CancelButton.js'

export type SaveButtonProps = Omit<AsyncButtonProps, 'children'> & {
    text?: string
    onlyIcon?: boolean
    noIcon?: boolean
}

export const SaveButton: React.FC<SaveButtonProps> = props => {
    const { text, onlyIcon, noIcon, ...btnProps } = props
    if (onlyIcon) {
        return (
            <AsyncIconButton {...btnProps}>
                <SaveIcon fontSize={btnProps.size ?? 'small'} />
            </AsyncIconButton>
        )
    }
    if (!btnProps.startIcon && !btnProps.endIcon && !noIcon) {
        btnProps.startIcon = <SaveIcon fontSize={btnProps.size ?? 'small'} />
    }
    return (
        <AsyncButton variant="contained" color="primary" {...btnProps}>
            {text || 'Save'}
        </AsyncButton>
    )
}

interface SaveCancelButtonsProps extends SaveButtonProps {
    onCancel: () => void
}
export const SaveCancelButton: React.FC<SaveCancelButtonsProps> = props => {
    const { onCancel, size, onlyIcon, ...saveButtonProps } = props

    return (
        <Stack direction="row" alignItems="center" spacing={onlyIcon ? 0.5 : 1}>
            <CancelButton size={size} onlyIcon={onlyIcon} onCancel={onCancel} />
            <SaveButton size={size} onlyIcon={onlyIcon} {...saveButtonProps} />
        </Stack>
    )
}
