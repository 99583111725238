import React, { ReactNode, useCallback, useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, CSSObject, Typography } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

import { useStateLS } from '../hooks/misc.js'
import { getFromLocalStorage, setToLocalStorage } from '../util/localstorage.js'

const lsKeyPrefix = 'info-box'

interface InfoBoxState {
    open: boolean
    viewCount: number
}

interface CloseableInfoBoxProps {
    id: string
    title: string
    children: ReactNode
    maxViewsBeforeAutoClose?: number // use 0 to set initially closed
    sx?: CSSObject
}
export function InfoBox(props: CloseableInfoBoxProps) {
    const { id, title, children, maxViewsBeforeAutoClose, sx } = props
    const lsKey = `${lsKeyPrefix}-${id}`

    const [state, setState] = useStateLS<InfoBoxState>(lsKey, {
        open: false,
        viewCount: 0,
    })

    useEffect(() => {
        const state = getFromLocalStorage<InfoBoxState>(lsKey) ?? { open: true, viewCount: 0 }
        const updatedState: InfoBoxState = { ...state, viewCount: state.viewCount + 1 }
        if (state.open) {
            if (maxViewsBeforeAutoClose && updatedState.viewCount >= maxViewsBeforeAutoClose) {
                updatedState.open = false
            }
        }
        setToLocalStorage<InfoBoxState>(lsKey, updatedState)
    }, [lsKey, maxViewsBeforeAutoClose])

    const onChangeHandler = useCallback(
        (_event: React.SyntheticEvent, isExpanded: boolean) => {
            setState(v => ({ ...v, open: isExpanded }))
        },
        [setState]
    )

    return (
        <Accordion expanded={state.open} onChange={onChangeHandler} sx={sx}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    )
}
