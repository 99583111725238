import _ from 'lodash'

import { runningInBrowser } from '@a10base/common/index.js'
import type { BaseJwtData, ServerData } from '@a10base/common/types/index.js'

export const serverData: ServerData = (
    runningInBrowser ? _.get(window, 'A10', {}) : {}
) as ServerData

export function getServerData<JWT extends BaseJwtData>(): ServerData<JWT> {
    return serverData as ServerData<JWT>
}
