import { A10Env } from './types/index.js'

export function getPublicUrl(opts: {
    project: string
    env: A10Env
    publicBucketCdn: string
    key: string
}): string {
    return `${opts.publicBucketCdn}/${addProjectPrefixToS3Key(opts)}`
}

export function getPublicThumbnailUrl(opts: {
    project: string
    env: A10Env
    fileUuid: string
    publicBucketCdn: string
}): string {
    return getPublicUrl({
        ...opts,
        key: getPublicThumbnailS3Key(opts.fileUuid),
    })
}

export function getPublicThumbnailS3Key(fileUuid: string): string {
    return `thumbnails/${fileUuid}.jpg`
}

export function addProjectPrefixToS3Key(opts: {
    project: string
    env: A10Env
    key: string
}): string {
    const envPart = opts.env === 'local' ? 'dev' : opts.env
    const projectKeyPrefix = `${opts.project}/${envPart}`
    return opts.key.startsWith(projectKeyPrefix) ? opts.key : `${projectKeyPrefix}/${opts.key}`
}
