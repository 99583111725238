import { UserPresenceMessage } from '@a10yll/common/index.js'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PresenceState {
    presence: { [clientId: string]: UserPresenceMessage['data'] | undefined }
}

export const initialPresenceState: PresenceState = {
    presence: {},
}

const presenceSlice = createSlice({
    name: 'presence',
    initialState: initialPresenceState,
    reducers: {
        setPresence(state, action: PayloadAction<UserPresenceMessage['data']>) {
            if (action.payload.clientId) {
                state.presence[action.payload.clientId] = action.payload
            }
        },
        refreshPresences(state) {
            // Update sligthly statuses so that components using them updates
            const delta = Math.random() <= 0.5 ? -1 : 1
            for (const userId in state.presence) {
                const presence = state.presence[userId]
                if (presence && presence.ts > 0) {
                    presence.ts += delta
                }
            }
        },
    },
})

export const presenceActions = presenceSlice.actions
export const presenceReducer = presenceSlice.reducer
