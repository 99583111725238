import React from 'react'
import { Add as AddIcon } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'

interface CreateNewButtonProps
    extends Pick<ButtonProps, 'sx' | 'children' | 'variant' | 'color' | 'size' | 'disabled'> {
    onClick: () => void
    plusIcon?: boolean
    mr?: number | string
    ml?: number | string
}

export const CreateNewButton: React.FC<CreateNewButtonProps> = props => {
    const { plusIcon, children, variant, onClick, color, mr, ml, sx, disabled } = props
    const size = props.size ?? 'small'
    return (
        <Button
            sx={{ mr, ml, ...sx }}
            variant={variant}
            color={color}
            size={size}
            startIcon={plusIcon ? <AddIcon fontSize={size} /> : undefined}
            onClick={onClick}
            disabled={disabled}
        >
            {children ?? 'Create new'}
        </Button>
    )
}
