// NOTE: This file is generated from src/backend/api/express/routes/auth.ts

import type * as yup from '@a10yll/common/yup/index.js'
import { objectToUrlParamsStr } from '@a10base/common/misc.js'
import type { JwtResponse } from '@a10base/common/types/index.js'
import type { EmailLoginResponse, JwtData } from '@a10yll/common/types/index.js'
import type { EmailLoginJwtResponse } from '@a10yll/common/types/index.js'
import * as api from '@a10base/frontend/util/api-util.js'

export async function getJwt(): Promise<JwtResponse<JwtData>> {
    return await api.get(`/api/auth/jwt`)
}

export async function emailLogin(data: yup.EmailLogin): Promise<EmailLoginResponse> {
    return await api.post(`/api/auth/email-login`, data)
}

export async function emailLoginMfaVerify(data: yup.EmailLoginMfa): Promise<EmailLoginJwtResponse> {
    return await api.post(`/api/auth/email-login-mfa-verify`, data)
}

export async function logout(): Promise<void> {
    return await api.post(`/api/auth/logout`)
}

export async function setPassword(
    data: yup.SetPassword,
    query: { hash?: string }
): Promise<JwtResponse<JwtData>> {
    return await api.post(`/api/auth/set-password?${objectToUrlParamsStr(query)}`, data)
}
