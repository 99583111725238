import { createTRPCClient, httpLink } from '@trpc/client'

import type { TrpcApiBase } from '../../backend/api/trpc/api.js'
import { getDefaultHeaders, fetchA10 } from './api-util.js'

function createClient() {
    return createTRPCClient<TrpcApiBase>({
        links: [
            httpLink({
                url: '/api/trpc-base',
                headers: getDefaultHeaders(),
                fetch: fetchA10,
            }),
        ],
    })
}

export type TrpcClientBase = ReturnType<typeof createClient>

export const trpcBase = createClient()

// let trpcClient: TrpcClient | undefined
// export function trpc(): TrpcClient {
//     if (!trpcClient) {
//         // Do this in actual projects:
//         //   trpcClient = createClient()
//         //   setValue(window, 'trpcClient', trpcClient)

//         // This is only for base project:
//         trpcClient = getValue(window, 'trpcClient') as TrpcClient
//     }
//     return trpcClient
// }
