import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type FlashNotificationType = 'success' | 'info' | 'warning' | 'error'

export interface FlashNotification {
    id: number
    type: FlashNotificationType
    message: string
    details?: string
}

export interface NotificationState {
    notifications: FlashNotification[]
}

export const initialNotificationState: NotificationState = {
    notifications: [],
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialNotificationState,
    reducers: {
        addNotification(state, action: PayloadAction<FlashNotification>) {
            state.notifications = state.notifications
                .filter(n => n.message !== action.payload.message)
                .concat([action.payload])
            if (state.notifications.length > 5) {
                state.notifications = state.notifications.slice(state.notifications.length - 5)
            }
        },
        removeNotification(state, action: PayloadAction<number>) {
            state.notifications = state.notifications.filter(n => n.id !== action.payload)
        },
    },
})

export const notificationActions = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer

// export const addSuccessNofitication =
//     (message: string, details?: string): AppThunk =>
//     dispatch => {
//         const id = getNextNotificationId()
//         const notification: FlashNotification = { id, type: 'success', message, details }
//         logger.info('notification', notification)
//         dispatch(addNotification(notification))
//         setTimeout(() => dispatch(removeNotification(id)), 5000)
//     }

// export const addInfoNofitication =
//     (message: string, details?: string): AppThunk =>
//     dispatch => {
//         const id = getNextNotificationId()
//         const notification: FlashNotification = { id, type: 'info', message, details }
//         logger.info('notification', notification)
//         dispatch(addNotification(notification))
//         setTimeout(() => dispatch(removeNotification(id)), 20000)
//     }

// export const addWarningNofitication =
//     (message: string, details?: string): AppThunk =>
//     dispatch => {
//         const id = getNextNotificationId()
//         const notification: FlashNotification = { id, type: 'warning', message, details }
//         logger.warn('notification', notification)
//         dispatch(addNotification(notification))
//         setTimeout(() => dispatch(removeNotification(id)), 20000)
//     }
