import { serverData, WebSocketClient } from '@a10base/frontend/util/index.js'
import { MyUserPresenceChanged, subscribeToMessage } from './message-bus/index.js'
import { sendWsMessage } from './util/index.js'
import { UserPresenceMessage } from '@a10yll/common/index.js'
import { unixTime } from '@a10base/common/index.js'

export function setupWsClient() {
    const wsClient = new WebSocketClient(serverData.config.WEB_SOCKET_URL)
    wsClient.connect()
    subscribeToMessage('my-user-presence-changed', (message: MyUserPresenceChanged) => {
        const presenceMessage: UserPresenceMessage = {
            type: 'user-presence',
            data: {
                ts: message.active ? unixTime() : 0,
            },
        }
        sendWsMessage(wsClient.webSocket, presenceMessage)
    })
}
