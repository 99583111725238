
export interface RGB {
    r: number
    g: number
    b: number
}
export function parseColorStringToRGB(colorString: string, defaultRgb?: RGB): RGB {
    let m = /rgb\(\s*(\d+)[\s,]*(\d+)[\s,]*(\d+)\s*\)/i.exec(colorString)
    if (m) {
        return { r: Number(m[1]) || 0, g: Number(m[2]) || 0, b: Number(m[3]) || 0 }
    }
    m = /#([\da-f]{2})([\da-f]{2})([\da-f]{2})/i.exec(colorString)
    if (m) {
        return { r: Number('0x'+m[1]) || 0, g: Number('0x'+m[2]) || 0, b: Number('0x'+m[3]) || 0 }
    }
    m = /#([\da-f])([\da-f])([\da-f])/i.exec(colorString)
    if (m) {
        return { r: Number('0x'+m[1]+m[1]) || 0, g: Number('0x'+m[2]+m[2]) || 0, b: Number('0x'+m[3]+m[3]) || 0 }
    }
    return defaultRgb || { r: 0, g: 0, b: 0 }
}
export function formatRGBToColorString(rgb: RGB): string {
    return `rgb(${rgb.r},${rgb.g},${rgb.b})`
}
export function rgbEquals(v1: RGB | undefined, v2: RGB | undefined): boolean {
    if (!v1 || !v2) {
        return false
    }
    return v1.r === v2.r && v1.g === v2.g && v1.b === v2.b
}

// Samples: https://casesandberg.github.io/react-color/
export const basicColors: RGB[] = ['#FFFFFF', '#ABB8C3', '#000000', '#795548', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#EB144C', '#F78DA7', '#9900EF'].map(v => parseColorStringToRGB(v))
