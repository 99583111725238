import {
    publishMessageBase,
    subscribeToMessageBase,
    unsubscribeFromMessageBase,
} from '@a10base/frontend/message-bus/message-bus.js'
import { MessageBusMessage } from './messages.js'

export const subscribeToMessage = subscribeToMessageBase<MessageBusMessage>

export const unsubscribeFromMessage = unsubscribeFromMessageBase<MessageBusMessage>

export const publishMessage = publishMessageBase<MessageBusMessage>
