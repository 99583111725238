import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MobileAppContext } from '@a10base/frontend/types/index.js'

export enum BreakpointThresholds {
    SM = 544,
    MD = 768,
    LG = 992,
    XL = 1200,
}
/*
MUI has following breakpoints:
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
Should we use these instead of our own?
*/

export interface Breakpoints {
    // If breakpoint is true then width is larger than equal than that breakpoint value
    sm: boolean
    md: boolean
    lg: boolean
    xl: boolean
}

function getBreakpoints(width: number): Breakpoints {
    return {
        sm: width >= Number(BreakpointThresholds.SM),
        md: width >= Number(BreakpointThresholds.MD),
        lg: width >= Number(BreakpointThresholds.LG),
        xl: width >= Number(BreakpointThresholds.XL),
    }
}

export interface ContextState {
    online: boolean
    wsReadyState: number | undefined // 0 = CONNECTING, 1 = OPEN, 2 = CLOSING, 3 = CLOSED
    windowWidth: number
    windowHeight: number
    breakpoints: Breakpoints
    mobileMenuOpen: boolean
    desktopMenuOpen: boolean
    mobileAppContext: MobileAppContext
    visible: boolean // This is a combination of 'mobileAppState' and 'tabHidden' (depending if this is running on mobile app or not)
}

export const initialContextState: ContextState = {
    online: !!navigator.onLine,
    wsReadyState: undefined,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    breakpoints: getBreakpoints(window.innerWidth),
    mobileMenuOpen: false,
    desktopMenuOpen: true,
    mobileAppContext: {
        isFileUploadSupported: true,
        isSharingSupported: true,
        isNotificationsAllowed: undefined,
        version: '1.0.0',
        osName: null,
        osVersion: null,
    },
    visible: true,
}

const contextSlice = createSlice({
    name: 'context',
    initialState: initialContextState,
    reducers: {
        setOnline(state, action: PayloadAction<boolean>) {
            state.online = action.payload
        },
        setVisible(state, action: PayloadAction<boolean>) {
            state.visible = action.payload
        },
        // setTabHidden(state, action: PayloadAction<boolean>) {
        //     const tabHidden = action.payload
        //     if (!serverData.config.RUNS_IN_MOBILE_APP) {
        //         state.visible = !tabHidden
        //     }
        // },
        setWsReadyState(state, action: PayloadAction<number>) {
            state.wsReadyState = action.payload
        },
        setWindowSize(state, action: PayloadAction<{ width: number; height: number }>) {
            state.windowWidth = action.payload.width
            state.windowHeight = action.payload.height
            state.breakpoints = getBreakpoints(action.payload.width)
        },
        setMobileMenuOpen(state, action: PayloadAction<boolean>) {
            state.mobileMenuOpen = action.payload
        },
        setDesktopMenuOpen(state, action: PayloadAction<boolean>) {
            state.desktopMenuOpen = action.payload
        },
        setMobileAppContext(state, action: PayloadAction<MobileAppContext>) {
            state.mobileAppContext = action.payload
        },
        // setMobileAppState(state, action: PayloadAction<MobileAppState>) {
        //     const appState = action.payload
        //     if (serverData.config.RUNS_IN_MOBILE_APP) {
        //         state.visible = appState === 'active'
        //     }
        // },
    },
})

export const contextReducer = contextSlice.reducer

export const contextActions = contextSlice.actions
