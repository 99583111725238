import * as yup from 'yup'
import { id, nullableString, objectStrict } from './common.js'

export const createHtmlPage = yup.object({
    path: yup.string().trim().max(128),
    is_wrapper: yup.boolean().required(),
})
export type CreateHtmlPage = yup.Asserts<typeof createHtmlPage>

export const pathObj = objectStrict({
    path: yup
        .string()
        .trim()
        .max(128)
        .matches(/^[\w_/-]*$/i)
        .required(),
})

export const updateHtmlPageData = objectStrict({
    path: yup
        .string()
        .trim()
        .max(128)
        .matches(/^[\w_/-]*$/i)
        .optional(),
    description: yup.string().trim().max(512),
    wrapper_path: nullableString.max(128).optional(),
}).defined()
export type UpdateHtmlPageData = yup.Asserts<typeof updateHtmlPageData>
export const updateHtmlPage = objectStrict({
    id: id,
    data: updateHtmlPageData,
}).defined()

export const renderHtmlPage = objectStrict({
    lang: yup.string().length(2).required(),
}).defined()
