interface ScoringInputOptions {
    creditScore: SelectOption[]
    revenue: SelectOption[]
    // companyAge: SelectOption[]
    loanAmount: SelectOption[]
    interest: SelectOption[]
    paybackTime: SelectOption[]
}
interface SelectOption<T = number> {
    value: T
    label: string
}

export const scoringOptions: ScoringInputOptions = {
    creditScore: [
        { value: 0, label: 'En tiedä' },
        { value: 7, label: 'AAA' },
        { value: 6, label: 'AA+' },
        { value: 5, label: 'AA' },
        { value: 4, label: 'A+' },
        { value: 3, label: 'A' },
        { value: 2, label: 'B' },
        { value: 1, label: 'C' },
    ],
    revenue: [
        { value: 500_000, label: 'alle 0,5 M €' },
        { value: 1_000_000, label: '0.5 - 1 M €' },
        { value: 2_000_000, label: '1 - 2 M €' },
        { value: 5_000_000, label: '2 - 5 M €' },
        { value: 10_000_000, label: '5 - 10 M €' },
        { value: 11_000_000, label: 'yli 10 M €' },
    ],
    // companyAge: [
    //     { value: 0, label: 'alle 6 kk' },
    //     { value: 1, label: '6 - 12 kk' },
    //     { value: 3, label: '1 - 3 vuotta' },
    //     { value: 5, label: '3 - 5 vuotta' },
    //     { value: 10, label: '5 - 10 vuotta' },
    //     { value: 11, label: 'yli 10 vuotta' },
    // ],
    loanAmount: [
        { value: 10_000, label: '10 000 €' },
        { value: 15_000, label: '15 000 €' },
        { value: 20_000, label: '20 000 €' },
        { value: 30_000, label: '30 000 €' },
        { value: 50_000, label: '50 000 €' },
        { value: 75_000, label: '75 000 €' },
        { value: 100_000, label: '100 000 €' },
        { value: 150_000, label: '150 000 €' },
        { value: 200_000, label: '200 000 €' },
        { value: 300_000, label: '300 000 €' },
        { value: 500_000, label: '500 000 €' },
        { value: 750_000, label: '750 000 €' },
        { value: 1_000_000, label: '1 000 000 €' },
        { value: 1_500_000, label: '1 500 000 €' },
        { value: 2_000_000, label: '2 000 000 €' },
        { value: 3_000_000, label: '3 000 000 €' },
    ],
    interest: [
        { value: 10, label: '5 - 10 %' },
        { value: 20, label: '10 - 20 %' },
        { value: 30, label: '20 - 30 %' },
    ],
    paybackTime: [
        { value: 1, label: '1 vuosi' },
        { value: 2, label: '2 vuotta' },
        { value: 3, label: '3 vuotta' },
        { value: 4, label: '4 vuotta' },
        { value: 5, label: '5 vuotta' },
    ],
}

export const scoreOptions: SelectOption[] = [
    { value: 0, label: 'No chances (0/4)' },
    { value: 1, label: 'Small chances (1/4)' },
    { value: 2, label: 'Reasonable chances (2/4)' },
    { value: 3, label: 'Good chances (3/4)' },
    { value: 4, label: 'Very good chances (4/4)' },
]
