import _ from 'lodash'

import { nop, setValue, unixTime } from '@a10base/common/misc.js'
import { isA10Admin } from '@a10base/common/permissions.js'

import type { MobileToWebMessage } from './types/mobile-app.js'
import { logger } from './util/client-logger.js'
import { reportClientError } from './util/client-error-reporter.js'
import {
    JwtUpdated,
    publishMessageBase,
    subscribeToMessageBase,
    UrlChanged,
} from './message-bus/index.js'
import { trpcBase } from './util/trpc.js'
import { hist, navigateTo } from './util/history.js'
import { setPathAfterLogin } from './util/path-after-login.js'
import { serverData } from './util/server-data.js'
import { parseSearchParams } from './util/url.js'
import { sendMessageToMobileApp } from './util/mobile-app.js'

// Set debug mode in non-production and for a10 personnel
if (serverData.config.A10_ENV !== 'prod' || isA10Admin(serverData.jwt)) {
    logger.setDebugMode(true)
}

// Show debug console layer
// const debugConsoleElem = document.getElementById('debug-console')
// if (debugConsoleElem) {
//     if (localStorage.getItem('DEBUG_CONSOLE') === 'true') {
//         debugConsoleElem.style.display = 'block'
//     } else {
//         debugConsoleElem.style.display = 'none'
//     }
// }

export function setupEventListeners(): void {
    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onunhandledrejection
        reportClientError('event: "unhandledrejection"', event.reason)
    })

    // Setup mics event handlers

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/orientationchange_event
    window.addEventListener('orientationchange', e => {
        logger.debug('event: "onorientationchange"', { event: e })
    })
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/resize_event
    window.addEventListener(
        'resize',
        _.throttle(
            () =>
                publishMessageBase({
                    type: 'window-resized',
                    width: window.innerWidth,
                    height: window.innerHeight,
                }),
            1000,
            { trailing: true }
        )
    )
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/offline_event
    window.addEventListener(
        'offline',
        _.throttle(
            () => publishMessageBase({ type: 'online-status-changed', online: navigator.onLine }),
            1000,
            {
                trailing: true,
            }
        )
    )
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/online_event
    window.addEventListener(
        'online',
        _.throttle(
            () => publishMessageBase({ type: 'online-status-changed', online: navigator.onLine }),
            10000,
            {
                trailing: true,
            }
        )
    )
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    // NOTE: This shouldn't be used anymore. Use "visibilitychange" instead.
    // window.addEventListener('beforeunload', () => {
    //     logger.info('event: "beforeunload"')
    //     // It's not good idea to do stuff here. It will sometimes freeze browser
    // })

    // Handle browser tab visibility changes.
    // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
    document.addEventListener(
        'visibilitychange',
        _.throttle(
            () =>
                publishMessageBase({
                    type: 'visibility-changed',
                    visible: document.visibilityState === 'visible',
                }),
            1000,
            { trailing: true }
        )
    )
}

export function setupDevToolsOpenDetector() {
    let w = window.outerWidth - window.innerWidth
    let h = window.outerHeight - window.innerHeight
    function devToolsOpenDetector() {
        const newH = window.outerHeight - window.innerHeight
        const dh = h - newH
        h = newH
        const newW = window.outerWidth - window.innerWidth
        const dw = w - newW
        w = newW
        const onlyOneDimension = dh === 0 || dw === 0
        const bigChange = Math.max(dh, dw) > 250
        if (bigChange && onlyOneDimension) {
            trpcBase.misc.foobar.mutate().catch(nop)
            window.removeEventListener('resize', devToolsOpenDetector)
        }
    }
    window.addEventListener('resize', devToolsOpenDetector)
}

export function setupMobileAppCommunication() {
    // Mobile app --> web app messages
    setValue(window, 'onMessageFromA10MobileApp', function (message: MobileToWebMessage) {
        try {
            publishMessageBase({ type: 'mobile-app-message', message })
        } catch (error) {
            reportClientError(
                `Failed to handle message from MobileApp: "${JSON.stringify(message)}"`,
                error
            )
        }
    })

    // Web app --> mobile app messages
    subscribeToMessageBase('jwt-updated', (message: JwtUpdated) => {
        sendMessageToMobileApp({
            type: 'jwt-changed',
            jwtToken: message.token,
            pid: message.jwt?.pid,
        })
    })
    subscribeToMessageBase('url-changed', (message: UrlChanged) => {
        sendMessageToMobileApp({
            type: 'url-path-changed',
            path: message.pathname,
        })
    })
}

export function setupUserPresenceMonitor() {
    const activityPublishingIntervalSec = 60
    let activityDetected = false
    let isActive = false
    let activityPublishedAt = 0

    function setActive() {
        activityDetected = true
        const shouldPublishActive =
            !isActive || unixTime() - activityPublishedAt >= activityPublishingIntervalSec
        if (shouldPublishActive) {
            isActive = true
            activityPublishedAt = unixTime()
            publishMessageBase({ type: 'my-user-presence-changed', active: true })
        }
    }

    document.addEventListener('touchstart', setActive)
    document.addEventListener('keyup', setActive)
    document.addEventListener('mousemove', setActive)
    // document.addEventListener('scroll', setActive)
    // document.addEventListener('click', setActive)

    function publishInactivity() {
        const shouldPublishInactive =
            isActive &&
            !activityDetected &&
            unixTime() - activityPublishedAt >= activityPublishingIntervalSec &&
            document.visibilityState !== 'visible'

        if (shouldPublishInactive) {
            isActive = false
            activityPublishedAt = unixTime()
            publishMessageBase({ type: 'my-user-presence-changed', active: false })
        }
        activityDetected = false
    }
    setInterval(publishInactivity, activityPublishingIntervalSec * 1000)
    setActive()
}

export function setupUrlChangeListener() {
    // window.addEventListener('popstate', event => {
    //     publishMessage({
    //         type: 'url-changed',
    //         historyState: event.state,
    //         url: window.location.href,
    //         pathname: window.location.pathname,
    //         search: window.location.search,
    //     })
    // })
    let prevPathname = location.pathname
    let prevSearchParams = parseSearchParams(location.search)
    hist.listen(({ location, action }) => {
        const pathname = location.pathname
        const searchParams = parseSearchParams(location.search)
        publishMessageBase<UrlChanged>({
            type: 'url-changed',
            historyState: location.state,
            pathname,
            search: location.search,
            action,
            searchParams,
            prevPathname,
            prevSearchParams,
        })
        prevPathname = location.pathname
        prevSearchParams = searchParams
    })
}

export function setupLoginRedirect() {
    subscribeToMessageBase('jwt-updated', (message: JwtUpdated) => {
        if (message.jwt === undefined) {
            if (message.oldJwt !== undefined) {
                setPathAfterLogin(location.pathname + location.search)
                navigateTo('/login')
            }
        }
        // Tää sotkee 'set-password' sivun
        // else {
        //     if (message.oldJwt === undefined || location.pathname === '/login') {
        //         const redirectUrl = getPathAfterLogin()
        //         clearPathAfterLogin()
        //         navigateTo(redirectUrl)
        //     }
        // }
    })
}
