import * as yup from 'yup'

export function objectStrict<S extends yup.ObjectShape>(schema: S) {
    return yup.object(schema).noUnknown(true)
}

export const id = yup.number().integer().min(0).required()
export const idOpt = yup.number().integer().min(0)
export const ids = yup.array(id)
export const nonNegInt = yup.number().integer().min(0)

export const dateString = yup.string().matches(/^\d{4}-\d{2}-\d{2}$/)

export const isoDateTimeString = yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}/)
    .max(36)

export const phone = yup
    .string()
    .max(30)
    .matches(/^\+?[\d ()-]*$/)

export const idObj = objectStrict({
    id: id,
})

export const nullableString = yup
    .string()
    .nullable()
    .transform((value, originalValue) => {
        if (originalValue === '') {
            return null
        }
        return value
    })
