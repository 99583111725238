/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, Button, TextField, Stack, Paper, Grow } from '@mui/material'
import { Email as EmailIcon } from '@mui/icons-material'

import { useAsyncFn1Param } from '@a10base/frontend/hooks/async-function.js'
import { isEnter, navigateTo, serverData } from '@a10base/frontend/util/index.js'
import { AsyncButton, FormInputError, Logo } from '@a10base/frontend/components/index.js'
import { NoAccountOption } from './LoginPage.js'
import { useFormData, useValidatedAsyncFn } from '@a10base/frontend/hooks/index.js'
import { trpc } from '../../../util/index.js'
import { yup } from '@a10yll/common/index.js'

interface ForgottenPasswordPageProps {
    hideSignupLink?: boolean
}

export function ForgottenPasswordPage({ hideSignupLink }: ForgottenPasswordPageProps) {
    const [emailSent, setEmailSent] = useState<boolean>(false)
    const emailFromUrl = useSelector<any>(state => state.url.searchParams.first['email']) as
        | string
        | undefined

    return (
        <Stack direction="column" alignItems="center" p={2}>
            <Stack direction="column">
                <Logo size="4rem" style={{ marginBottom: '1rem' }} />
                <Paper
                    elevation={5}
                    sx={theme => ({
                        width: '350px',
                        minWidth: '350px',
                        p: 2,
                        [theme.breakpoints.up('sm')]: {
                            width: '450px',
                            minWidth: '450px',
                            p: 6,
                        },
                    })}
                >
                    <ForgottenPasswordForm
                        email={emailFromUrl}
                        emailSent={emailSent}
                        onEmailSent={() => setEmailSent(true)}
                    />
                </Paper>
                {!hideSignupLink && !emailSent && <NoAccountOption sx={{ mt: 2 }} />}
            </Stack>
        </Stack>
    )
}

interface ForgottenPasswordFormProps {
    email?: string
    emailSent: boolean
    onEmailSent: () => void
}
function ForgottenPasswordForm({ email, emailSent, onEmailSent }: ForgottenPasswordFormProps) {
    const { values, setValue, validate, errors } = useFormData(yup.forgottenPassword, {
        email: email || '',
    })
    const reset = useAsyncFn1Param(trpc.auth.forgottenPassword.mutate, values, onEmailSent)
    const validateAndReset = useValidatedAsyncFn(reset, validate)

    return (
        <Stack alignItems="flex-start" direction="column" width="100%">
            {!emailSent && (
                <>
                    <Typography component="h1" variant="h5" mb={2}>
                        Reset your password
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mb={2}>
                        Enter your email address and we will send you a link to reset your password.
                    </Typography>
                    <TextField
                        value={values.email}
                        onChange={e => setValue('email', e.target.value)}
                        onKeyUp={e => isEnter(e) && values.email && validateAndReset.callFn()}
                        type="email"
                        margin="normal"
                        required
                        fullWidth
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <FormInputError errorMsg={errors?.get('email')} />
                    <AsyncButton
                        {...validateAndReset}
                        fullWidth
                        size="large"
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                    >
                        Continue
                    </AsyncButton>

                    <Box sx={{ mt: 2 }}>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => navigateTo('/login')}
                            size="small"
                        >
                            Return to log in
                        </Button>
                    </Box>
                </>
            )}
            {emailSent && (
                <>
                    <Grow in={true} timeout={1000}>
                        <EmailIcon color="primary" sx={{ mb: 2 }} fontSize="large" />
                    </Grow>
                    <Typography variant="body1" mb={2} fontWeight={600}>
                        We have sent you an email with a link to reset your password.
                    </Typography>

                    <Typography variant="body2" mb={2}>
                        Email delivery can take a few minutes. If you don't receive an email, please
                        check your spam folder.
                    </Typography>

                    <Typography variant="body2">
                        If you have any problems logging in, please contact our customer service at{' '}
                        {serverData.config.SUPPORT_EMAIL}
                    </Typography>

                    <Button variant="text" onClick={() => navigateTo('/')}>
                        Return to home
                    </Button>
                </>
            )}
        </Stack>
    )
}
