import React, { useCallback } from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'

interface CopyToClipboardButtonProps extends Pick<IconButtonProps, 'sx' | 'size' | 'disabled'> {
    textToCopy: string | undefined | null
    onCopied?: (text: string) => void
    mr?: number | string
    ml?: number | string
}
export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = props => {
    const { textToCopy, onCopied, sx, disabled, mr, ml } = props
    const size = props.size ?? 'small'
    const copyToClipboard = useCallback(() => {
        if (textToCopy) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    if (onCopied) {
                        onCopied(textToCopy)
                    }
                })
                .catch(() => {
                    // do nothing
                })
        }
    }, [onCopied, textToCopy])

    if (textToCopy === undefined) {
        return null
    }

    return (
        <Tooltip title="Copy to clipboard">
            <IconButton
                onClick={copyToClipboard}
                size={size}
                sx={{ mr, ml, ...sx }}
                disabled={disabled}
            >
                <ContentCopy fontSize={size} />
            </IconButton>
        </Tooltip>
    )
}
