import React from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'

import { goBack, hist } from '../../util/history.js'

export function BackButton(props: Pick<IconButtonProps, 'size' | 'sx'>) {
    const historyStackEmpty = hist.location.key === 'default'
    if (historyStackEmpty) {
        return null
    }
    const size = props.size ?? 'small'
    return (
        <Tooltip title="Back to previous view">
            <IconButton onClick={goBack} color="inherit" size={size} sx={props.sx}>
                <ArrowBackIos fontSize={size} />
            </IconButton>
        </Tooltip>
    )
}
