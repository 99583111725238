import type { ScoringInput } from '../../yup/scoring.js'
import type { CreditScore, LoanChance } from '../../types/scoring.js'
import { scoringOptions } from './options.js'
import _ from 'lodash'

export const emptyScoringInput: ScoringInput = {
    credit_score: -1,
    revenue: -1,
    //company_age: scoringOptions.companyAge[0].value,
    loan_amount: -1,
    collateral: 0,
    interest: 10,
    payback_time: 3,
}

export const sampleScoringInput: ScoringInput = {
    credit_score: scoringOptions.creditScore[0].value as CreditScore,
    revenue: scoringOptions.revenue[0].value,
    // company_age: scoringOptions.companyAge[0].value,
    loan_amount: scoringOptions.loanAmount[0].value,
    collateral: 0,
    interest: scoringOptions.interest[0].value,
    payback_time: scoringOptions.paybackTime[0].value,
}

export function randomScoringInput(): ScoringInput {
    return {
        credit_score: _.shuffle(scoringOptions.creditScore)[0].value as CreditScore,
        revenue: _.shuffle(scoringOptions.revenue)[0].value,
        // company_age: scoringOptions.companyAge[0].value,
        loan_amount: _.shuffle(scoringOptions.loanAmount)[0].value,
        collateral: Math.random() < 0.5 ? 0 : 1,
        interest: _.shuffle(scoringOptions.interest)[0].value,
        payback_time: _.shuffle(scoringOptions.paybackTime)[0].value,
    }
}

export type ScoringFunction = (input: ScoringInput) => LoanChance

export function createScoringFunction(code: string): ScoringFunction {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const scoringFn = new Function(
        'input',
        `
        ${code}
        const fixedInput = {
            ...input,
            // Default to AA (5) if credit_score is 0 (unknown)
            credit_score: input.credit_score === 0 ? 5 : input.credit_score,
        }
        let chances = calculateChances(fixedInput)
        chances = Math.round(chances)
        chances = Math.min(4, Math.max(0, chances))
        return chances
    `
    )
    return scoringFn as ScoringFunction
}

export function validateScoringFunction(scoringFn: ScoringFunction): void {
    // This will throw if the function is invalid
    scoringFn(sampleScoringInput)
}

export function calculateScore(code: string, input: ScoringInput): number {
    const fn = createScoringFunction(code)
    return fn(input) // 0 = no chance, 1 = small chance, 2 = reasonable chance, 3 = good chance, 4 = very good chance
}
