import * as yup from 'yup'
import { id, objectStrict } from './common.js'

export const updateHtmlDoc = objectStrict({
    id: id,
    data: objectStrict({
        less: yup.string().max(10000).optional(),
        js: yup.string().max(10000).optional(),
    }),
}).defined()
export type UpdateHtmlDoc = yup.Asserts<typeof updateHtmlDoc>
