/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react'
// We need to use these raw redux hooks so that this file is not dependent on the (base) store
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { first } from '@a10base/common/index.js'
import { FlashNotification, notificationActions } from '@a10base/frontend/redux/reducers/index.js'

export function FlashNotificationViewer() {
    const dispatch = useDispatch<any>()
    const notifications = useSelector<any>(
        state => state.notification.notifications
    ) as FlashNotification[]

    const removeNotification = useCallback(
        (id: number) => {
            dispatch(notificationActions.removeNotification(id))
        },
        [dispatch]
    )

    const mostCriticalNotification = useMemo(() => {
        const error = notifications.find(n => n.type === 'error')
        if (error) {
            return error
        }
        const warning = notifications.find(n => n.type === 'warning')
        if (warning) {
            return warning
        }
        const success = notifications.find(n => n.type === 'success')
        if (success) {
            return success
        }
        return first(notifications)
    }, [notifications])

    const onRemove = useCallback(() => {
        if (mostCriticalNotification) {
            removeNotification(mostCriticalNotification.id)
        }
    }, [removeNotification, mostCriticalNotification])

    const handleClose = useCallback(
        (_event: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway' || mostCriticalNotification === undefined) {
                return
            }
            removeNotification(mostCriticalNotification.id)
        },
        [removeNotification, mostCriticalNotification]
    )

    if (mostCriticalNotification === undefined) {
        return null
    }
    return (
        <Snackbar open={true} onClose={handleClose}>
            <Alert
                onClose={onRemove}
                severity={mostCriticalNotification.type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                <AlertTitle>{mostCriticalNotification.message}</AlertTitle>
                {mostCriticalNotification.details}
            </Alert>
        </Snackbar>
    )
}
