import { createTRPCClient, httpLink } from '@trpc/client'

import { getDefaultHeaders, fetchA10 } from '@a10base/frontend/util/api-util.js'

import type { TrpcApi } from '../../backend/api/trpc/api.js'

function createClient() {
    return createTRPCClient<TrpcApi>({
        links: [
            httpLink({
                url: '/api/trpc',
                headers: getDefaultHeaders(),
                fetch: fetchA10,
            }),
        ],
    })
}

export type TrpcClient = ReturnType<typeof createClient>

export const trpc = createClient()

// let trpcClient: TrpcClient | undefined
// export function trpc(): TrpcClient {
//     if (!trpcClient) {
//         trpcClient = createClient()
//         setValue(window, 'trpcClient', trpcClient)
//     }
//     return trpcClient
// }
