import { logger } from '@a10base/frontend/util/client-logger.js'
import { getStrFromLocalStorage, setStrToLocalStorage } from './localstorage.js'
import { navigateTo } from './history.js'

const pathAfterLoginKey = 'pathAfterLogin'

export function getPathAfterLogin(): string {
    return getStrFromLocalStorage(pathAfterLoginKey) ?? '/'
}

export function setPathAfterLogin(path: string): void {
    let normalizedPath = normalizePath(path)
    if (normalizedPath.startsWith('/login') || normalizedPath.startsWith('/set-password')) {
        normalizedPath = '/'
    }
    logger.debug('Setting path after login', { normalizedPath, path })
    return setStrToLocalStorage(pathAfterLoginKey, normalizedPath)
}

function normalizePath(path: string): string {
    if (path.startsWith('http')) {
        const url = new URL(path)
        return url.pathname + url.search
    }
    return path
}

export function clearPathAfterLogin(): void {
    logger.debug('Clearing path after login')
    return setStrToLocalStorage(pathAfterLoginKey, undefined)
}

export function navigateToPathAfterLogin(): void {
    const path = getPathAfterLogin()
    clearPathAfterLogin()
    navigateTo(path)
}
