import React, { ErrorInfo, useEffect } from 'react'

import { reportClientError } from '../util/client-error-reporter.js'

interface ErrorBoundaryState {
    hasError: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
    constructor(props: unknown) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        reportClientError('ErrorBoundary', { error, errorInfo })
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return <SomethingWentWrongPage />
        }
        return this.props.children as React.ReactNode
    }
}

// NOTE: this is not wrapped in Redux <Provider>...</Provider> so don't use anything special here (dispatch, ....).
export const SomethingWentWrongPage: React.FC = () => {
    // Auto-reload page after 2 mins
    useEffect(() => {
        const handle = setTimeout(() => window.location.assign('/'), 2 * 60 * 1000)
        return () => {
            clearTimeout(handle)
        }
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <h2 style={{ marginBottom: '20px' }}>
                Something went wrong{' '}
                <span role="img" aria-label="sad">
                    😢
                </span>{' '}
            </h2>
            <p style={{ marginBottom: '20px' }}>
                We won&apos;t sleep until the problem is fixed.{' '}
                <button
                    style={{
                        background: 'none',
                        color: 'blue',
                        border: 'none',
                        padding: 0,
                        font: 'inherit',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() => window.location.assign('/')}
                >
                    Return to home
                </button>
            </p>
            <img
                src="https://a10-public.ams3.cdn.digitaloceanspaces.com/coder.jpg"
                alt="coder"
                style={{ width: 400 }}
            />
        </div>
    )
}
