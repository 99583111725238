import React from 'react'
import { Tooltip } from '@mui/material'

export const UnauthorizedValuePlaceholder = React.memo(function unauthorizedValuePlaceholder() {
    return (
        <Tooltip title="Information is missing or you are not authorized to see it.">
            <span className="unauthorized-value-placeholder">
                &bull; &bull; &bull; &bull; &bull;
            </span>
        </Tooltip>
    )
})
