import React from 'react'
import { IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

interface DialogCloseButtonProps {
    onClose: () => void
}
export const DialogCloseButton: React.FC<DialogCloseButtonProps> = props => {
    return (
        <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey',
            }}
        >
            <CloseIcon />
        </IconButton>
    )
}
