import * as yup from 'yup'
import { id, objectStrict } from './common.js'

export const createLanguage = objectStrict({
    code: yup.string().length(2).required(),
    native_name: yup.string().max(64).required(),
    english_name: yup.string().max(64).required(),
}).defined()
export type CreateLanguage = yup.Asserts<typeof createLanguage>

export const updateLanguage = objectStrict({
    id: id,
    data: objectStrict({
        native_name: yup.string().max(64).required(),
        english_name: yup.string().max(64).required(),
    }),
}).defined()
