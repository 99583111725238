import * as yup from 'yup'
import { id, nullableString, objectStrict } from './common.js'

export const createEmailTemplate = objectStrict({
    name: yup.string().trim().min(3).max(128).required(),
    is_wrapper: yup.boolean().required(),
}).defined()
export type CreateEmailTemplate = yup.Asserts<typeof createEmailTemplate>

export const updateEmailTemplateData = objectStrict({
    name: yup.string().trim().min(3).max(128).optional(),
    from: nullableString.max(128).optional(),
    description: yup.string().trim().max(512),
    wrapper_name: nullableString.max(128).optional(),
    hb_sample_data_json: yup
        .string()
        .max(4000)
        .optional()
        .test('json-parsable', 'Not valid json', v => {
            if (v === undefined) return true
            try {
                JSON.parse(v)
                return true
            } catch {
                return false
            }
        }),
}).defined()

export const updateEmailTemplate = objectStrict({
    id: id,
    data: updateEmailTemplateData,
}).defined()
export type UpdateEmailTemplate = yup.Asserts<typeof updateEmailTemplate>

export const sendTestEmail = objectStrict({
    name: yup.string().max(256).required(),
    to: yup.string().email().max(128).required(),
    lang: yup.string().length(2).required(),
    data: yup.object(),
}).defined()

export const renderEmailTemplate = objectStrict({
    name: yup.string().max(256).required(),
    lang: yup.string().length(2).required(),
    data: yup.object(),
}).defined()

export const nameObj = objectStrict({
    name: yup.string().trim().max(128).required(),
})
