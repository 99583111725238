import React, { useCallback, useRef } from 'react'

import { PopupMenuCustom, PopupMenuRef } from '@a10base/frontend/components/PopupMenu.js'
import { Divider, ListItemIcon, MenuItem, Stack, Typography } from '@mui/material'
import {
    Logout as LogoutIcon,
    AccountCircle as ProfileMenuIcon,
    Launch as AppIcon,
} from '@mui/icons-material'
import { Person } from '@a10yll/common/index.js'
import { truncate } from '@a10base/common/misc.js'
import * as api from '../../../api-generated/index.js'
import { showNotification } from '@a10base/frontend/util/flash-notifications.js'
import { navigateTo } from '@a10base/frontend/util/history.js'
import { useAsyncFn } from '@a10base/frontend/hooks/async-function.js'
import { Spinner } from '@a10base/frontend/components/Spinner.js'
import { APP_BAR_HEIGHT } from '@a10base/frontend/components/AppSkeleton.js'
import { serverData } from '@a10base/frontend/util/server-data.js'
import { jwtStore } from '../../../util/index.js'
import { useMe } from '../hooks/index.js'

export function ProfileMenu() {
    const menuRef = useRef<PopupMenuRef | null>(null)
    const me = useMe()

    const gotoApp = useCallback(() => {
        location.href = serverData.config.APP_URL
    }, [])

    const afterLogout = useCallback(() => {
        jwtStore.clearJwt()
        showNotification({ messageType: 'success', message: 'Logged out' })
        navigateTo('/login')
        menuRef.current?.close()
    }, [])
    const logoutAction = useAsyncFn(api.logout, afterLogout)

    return (
        <PopupMenuCustom
            ref={menuRef}
            buttonContent={<ProfileButtonContent person={me?.person} />}
            buttonSx={{
                height: APP_BAR_HEIGHT,
                padding: '10px',
                '&:hover': {
                    backgroundColor: 'lightgrey',
                },
            }}
        >
            <MenuItem onClick={gotoApp} sx={{ mb: 2 }}>
                <ListItemIcon>
                    <AppIcon fontSize="medium" />
                </ListItemIcon>
                Goto Yrityslainalaskuri
            </MenuItem>
            <Divider />
            <MenuItem onClick={logoutAction.callFn} disabled={logoutAction.processing}>
                <ListItemIcon>
                    <LogoutIcon fontSize="medium" />
                </ListItemIcon>
                Logout
                <Spinner spinning={logoutAction.processing} />
            </MenuItem>
        </PopupMenuCustom>
    )
}

interface ProfileButtonProps {
    person: Person | undefined
}
function ProfileButtonContent({ person }: ProfileButtonProps) {
    if (!person) {
        return <ProfileMenuIcon />
    }
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <ProfileMenuIcon fontSize="large" />
            <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="body1">{truncate(person?.first_name, 30)}</Typography>
                <Typography variant="body2">{truncate(person.email, 30)}</Typography>
            </Stack>
        </Stack>
    )
}
