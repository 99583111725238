import * as yup from 'yup'
import { id, objectStrict } from './common.js'

export const createCurrency = objectStrict({
    code: yup.string().length(3).required(),
    name: yup.string().max(64).required(),
    one_dollar_equals: yup.number().positive().required(),
}).defined()
export type CreateCurrency = yup.Asserts<typeof createCurrency>

export const updateCurrency = objectStrict({
    id: id,
    data: objectStrict({
        code: yup.string().length(3).required(),
        name: yup.string().max(64).required(),
        one_dollar_equals: yup.number().positive().required(),
    }),
}).defined()
export type UpdateCurrency = yup.Asserts<typeof updateCurrency>
