import { serverData } from './server-data.js'

export const userLocale = navigator.language
const dtf = new Intl.DateTimeFormat(serverData.config.CLIENT_LANG)
export const userTimeZone = dtf.resolvedOptions().timeZone
const rtf = new Intl.RelativeTimeFormat(serverData.config.CLIENT_LANG, { style: 'short' })

const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: userTimeZone,
})

const longDateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    weekday: 'short',
    timeZone: userTimeZone,
})

const dateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: userTimeZone,
})

const longDateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    weekday: 'short',
    timeZone: userTimeZone,
})

export function formatDateDifference(d1: Date | string, d2: Date | string): string {
    if (typeof d1 === 'string') {
        d1 = new Date(d1)
    }
    if (typeof d2 === 'string') {
        d2 = new Date(d2)
    }
    const diffInMs = d1.getTime() - d2.getTime()
    const diffInSeconds = Math.floor(diffInMs / 1000)
    const diffInMinutes = Math.floor(diffInSeconds / 60)
    const diffInHours = Math.floor(diffInMinutes / 60)
    const diffInDays = Math.floor(diffInHours / 24)

    if (Math.abs(diffInSeconds) < 60) {
        return rtf.format(diffInSeconds, 'second')
    } else if (Math.abs(diffInMinutes) < 60) {
        return rtf.format(diffInMinutes, 'minute')
    } else if (Math.abs(diffInHours) < 24) {
        return rtf.format(diffInHours, 'hour')
    } else if (Math.abs(diffInDays) < 7) {
        return rtf.format(diffInDays, 'day')
    } else {
        return d2.toLocaleDateString(userLocale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        })
    }
}

export function formatDate(
    date: string | number | Date | null | undefined,
    includeDayName?: boolean
): string {
    if (date === null || date === undefined) {
        return ''
    }
    if (typeof date === 'string' || typeof date === 'number') {
        date = new Date(date)
    }
    return includeDayName ? longDateFormatter.format(date) : dateFormatter.format(date)
}

export function formatDateTime(
    date: string | number | Date | null | undefined,
    includeDayName?: boolean
): string {
    if (date === null || date === undefined) {
        return ''
    }
    if (typeof date === 'string' || typeof date === 'number') {
        date = new Date(date)
    }
    return includeDayName ? longDateTimeFormatter.format(date) : dateTimeFormatter.format(date)
}

export function formatDuration(date: string | Date, baseDate?: string | Date): string {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    if (baseDate === undefined) {
        baseDate = new Date()
    }
    if (typeof baseDate === 'string') {
        baseDate = new Date(baseDate)
    }
    const totalSeconds = Math.abs(Math.round((baseDate.getTime() - date.getTime()) / 1000))
    const seconds = totalSeconds % 60
    const totalMinutes = Math.floor(totalSeconds / 60)
    const minutes = totalMinutes % 60
    const totalHours = Math.floor(totalSeconds / 3600)
    return `${String(totalHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}
