import { runningInBrowser } from '@a10base/common/running-in-browser.js'
import { logger } from './client-logger.js'

export function setStrToLocalStorage(key: string, value: string | undefined): void {
    if (runningInBrowser && window.localStorage) {
        if (value === undefined) {
            logger.debug('Removing from local storage', { key })
            window.localStorage.removeItem(key)
        } else {
            logger.debug('Setting to local storage', { key, value })
            window.localStorage.setItem(key, value)
        }
    }
}

export function setToLocalStorage<T = unknown>(key: string, value: T): void {
    try {
        setStrToLocalStorage(key, JSON.stringify(value))
    } catch (error) {
        logger.warn('Failed to set JSON to local storage', error)
    }
}

export function getStrFromLocalStorage(key: string): string | undefined {
    if (runningInBrowser && window.localStorage) {
        const value = window.localStorage.getItem(key)
        if (value !== null) {
            return value
        }
    }
    return undefined
}

export function getFromLocalStorage<T>(key: string): T | undefined {
    const json = getStrFromLocalStorage(key)
    try {
        return json ? JSON.parse(json) : undefined
    } catch (error) {
        logger.warn('Failed to parse JSON from local storage', error)
        return undefined
    }
}

export function getLocalStorageKeys(prefix = ''): string[] {
    const keys: string[] = []
    if (runningInBrowser && window.localStorage) {
        for (let i = 0, len = window.localStorage.length; i < len; i += 1) {
            const key = window.localStorage.key(i)
            if (key !== null && (prefix.length === 0 || key.startsWith(prefix))) {
                keys.push(key)
            }
        }
    }
    return keys
}

export function clearLocalStorage(): void {
    if (runningInBrowser && window.localStorage) {
        logger.debug('Clearing local storage')
        window.localStorage.clear()
    }
}
