import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'

import { UseAsyncFn1 } from '../hooks/index.js'
import { isEnter } from '../util/misc.js'
import { SaveCancelButton } from './button/index.js'

interface InputModalProps extends UseAsyncFn1<string> {
    saveButtonText?: string
    title?: string
    size?: 'xs' | 'sm' | 'md'
    label?: string
    initialValue?: string
    required?: boolean
    minLength?: number
    maxLength?: number
    normalizeInput?: (value: string) => string
    onCancel: () => void
}
export function InputModal(props: InputModalProps) {
    const {
        required,
        title,
        label,
        initialValue,
        minLength,
        maxLength,
        saveButtonText,
        size,
        onCancel,
        callFn,
        normalizeInput,
        processing,
    } = props
    const [value, setValue] = useState<string>(initialValue || '')
    const inputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const inputValid =
        !required ||
        (value.trim().length >= (minLength || 0) && value.trim().length <= (maxLength || 1000))

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (isEnter(e) && inputValid) {
            callFn(value)
        }
    }

    const onSave = () => {
        if (inputValid) {
            callFn(value)
        }
    }

    return (
        <Dialog title={title} onClose={onCancel} open={true} maxWidth={size} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    label={label}
                    value={value}
                    required={required}
                    onChange={e =>
                        setValue(normalizeInput ? normalizeInput(e.target.value) : e.target.value)
                    }
                    inputRef={inputRef}
                    onKeyDown={onKeyDown}
                    fullWidth
                    sx={{ my: 2 }}
                    autoFocus
                />
            </DialogContent>
            <DialogActions>
                <SaveCancelButton
                    onCancel={onCancel}
                    disabled={!inputValid || processing}
                    processing={processing}
                    callFn={onSave}
                    text={saveButtonText}
                />
            </DialogActions>
        </Dialog>
    )
}
