import React, { useMemo } from 'react'

import { logger } from '../util/client-logger.js'
import { Button, Stack, Typography } from '@mui/material'
import { navigateTo } from '../util/history.js'
import { ArrowBackIos } from '@mui/icons-material'

export interface RouteDefinition {
    pathTemplate: string // e.g. '/users/:int/favorite-foods/:string'
    render: (match: PathMatch) => React.ReactNode
    Wrapper?: React.FC<{ children: React.ReactNode }>
    noWrapper?: boolean
}

interface RouterProps {
    pathname: string
    routes: RouteDefinition[]
    notFound?: React.ReactNode
    DefaultWrapper?: React.FC<{ children: React.ReactNode }>
}

export const Router: React.FC<RouterProps> = ({ pathname, routes, notFound, DefaultWrapper }) => {
    const parsedTemplates = useMemo(() => {
        logger.debug('Parsing route templates')
        return routes.map(route => parsePath(route.pathTemplate))
    }, [routes])

    const [route, match] = useMemo(() => {
        const parsedPath = parsePath(pathname)
        for (let i = 0; i < routes.length; i++) {
            const match = matchPath(parsedPath, parsedTemplates[i])
            if (match) {
                logger.debug('Found matching route', {
                    pathname,
                    pathTemplate: routes[i].pathTemplate,
                })
                return [routes[i], match]
            }
        }
        logger.debug('Cound not found matching route for path', { pathname })
        return [undefined, undefined]
    }, [pathname, parsedTemplates, routes])

    if (route) {
        const Wrapper = route.noWrapper ? undefined : (route.Wrapper ?? DefaultWrapper)
        if (Wrapper) {
            return <Wrapper>{route.render(match)}</Wrapper>
        } else {
            return route.render(match)
        }
    } else {
        return notFound ?? <RouteNotFound />
    }
}

class PathMatch {
    constructor(private variables: string[]) {}
    getString(index = 0): string {
        return this.variables[index]
    }
    getInt(index = 0): number {
        return parseInt(this.variables[index], 10)
    }
}

function parsePath(path: string): string[] {
    return path.split('/').filter(Boolean)
}
function matchPath(pathParts: string[], templateParts: string[]): PathMatch | undefined {
    if (pathParts.length !== templateParts.length) {
        return undefined
    }
    const variables: string[] = []
    for (let i = 0; i < pathParts.length; i++) {
        if (templateParts[i].startsWith(':')) {
            variables.push(pathParts[i])
        } else if (templateParts[i] !== pathParts[i]) {
            return undefined
        }
    }
    return new PathMatch(variables)
}

export function RouteNotFound() {
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={3} mt={6}>
            <Typography variant="h5">Page not found 😔</Typography>
            <Button variant="text" onClick={() => navigateTo('/')} startIcon={<ArrowBackIos />}>
                Go back
            </Button>
        </Stack>
    )
}
