import React, { ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'

interface PageTitleRowProps {
    title?: string
    subtitle?: string
    children?: ReactNode
}
export function PageTitleRow({ title, subtitle, children }: PageTitleRowProps) {
    return (
        <Stack direction="column" spacing={0} alignItems="flex-start" mb={2}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h5" mb={0}>
                    {title}
                </Typography>
                {Array.isArray(children)
                    ? children.map((v, i) => <React.Fragment key={i}>{v}</React.Fragment>)
                    : children}
            </Stack>
            {subtitle && (
                <Typography variant="body2" color="grey" mt={0}>
                    {subtitle}
                </Typography>
            )}
        </Stack>
    )
}
