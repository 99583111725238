import React from 'react'
import { Stack, Typography } from '@mui/material'

import { CompanyDataSmall, getIdOrThrow } from '@a10base/common/index.js'
import { AsyncAutoComplete } from '@a10base/frontend/components/AutoComplete.js'
import { getCompanySuggestions } from '../util/puulaaki.js'

interface CompanyFinderProps {
    selected: CompanyDataSmall | null
    countries?: string[]
    forms?: string[]
    onlyActive?: boolean
    disabled?: boolean
    label?: string
    placeholder?: string
    loading?: boolean
    onChange: (company: CompanyDataSmall | null) => void
}
export function CompanyFinder({
    selected,
    countries,
    forms,
    onlyActive,
    disabled,
    label,
    placeholder,
    loading,
    onChange,
}: CompanyFinderProps) {
    const search = async (searchText: string) => {
        if (!searchText) {
            return []
        }
        return await getCompanySuggestions(searchText, { countries, forms, onlyActive, limit: 10 })
    }

    return (
        <AsyncAutoComplete
            value={selected}
            loading={loading}
            disabled={disabled}
            label={label ?? 'Company'}
            placeholder={placeholder}
            onChange={onChange}
            search={search}
            itemLabel={companyName}
            renderItem={renderCompanyRow}
            itemId={getIdOrThrow}
        />
    )
}

function companyName(company: CompanyDataSmall): string {
    return `${company.name} (${company.business_id})`
}

function renderCompanyRow(company: CompanyDataSmall) {
    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body1">{company.name}</Typography>
            <Typography variant="body2" color="grey">
                {company.business_id}
            </Typography>
            {company.name2 && (
                <Typography variant="body2" color="grey">
                    {company.name2}
                </Typography>
            )}
        </Stack>
    )
}
