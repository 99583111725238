/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react'
import * as yup from 'yup'
import _ from 'lodash'

import { UseAsyncFn } from './async-function.js'
import { showNotification } from '../util/flash-notifications.js'

interface UseFormData<T extends object> {
    values: T
    errors: Map<keyof T, string> | null
    dirty: boolean
    setValue: (key: keyof T, value: any) => void
    validate: () => Map<keyof T, string> | null
    clearErrors: () => void
    clearDirty: () => void
}

export function useFormData<T extends object>(
    schema: yup.ObjectSchema<T>,
    initialValues: T // This can contain extra fields that are not in the schema
): UseFormData<T> {
    const [values, setValues] = useState<T>(() => pickSchemaFields(initialValues, schema))
    const initialValuesRef = useRef<T>(initialValues)
    initialValuesRef.current = initialValues
    const [errors, setErrors] = useState<Map<keyof T, string> | null>(null)
    const [dirty, setDirty] = useState<boolean>(false)
    const autovalidate = useRef<boolean>(false)
    const valuesRef = useRef<T>(values)

    const validate = useCallback(() => {
        autovalidate.current = true
        try {
            schema.validateSync(valuesRef.current, { abortEarly: false })
            setErrors(null)
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const newErrors = new Map<keyof T, string>()
                error.inner.forEach(err => {
                    if (err.path) {
                        newErrors.set(err.path as keyof T, err.message)
                    }
                })
                setErrors(newErrors.size > 0 ? newErrors : null)
                return newErrors.size > 0 ? newErrors : null
            }
        }
        return null
    }, [schema])

    const setValue = useCallback(
        (key: keyof T, value: any, skipValidation?: boolean) => {
            const newValues = { ...valuesRef.current, [key]: value }
            valuesRef.current = newValues
            if (autovalidate.current && !skipValidation) {
                validate()
            }
            if (dirty) {
                const isNotDirty =
                    newValues[key] === initialValuesRef.current[key] &&
                    _.isEqual(newValues, pickSchemaFields(initialValuesRef.current, schema))
                if (isNotDirty) {
                    setDirty(false)
                }
            } else {
                const isDirty =
                    newValues[key] !== initialValuesRef.current[key] ||
                    !_.isEqual(newValues, pickSchemaFields(initialValuesRef.current, schema))
                if (isDirty) {
                    setDirty(true)
                }
            }
            setValues(newValues)
        },
        [dirty, schema, validate]
    )

    const clearErrors = useCallback(() => {
        autovalidate.current = false
        setErrors(null)
    }, [])

    const clearDirty = useCallback(() => {
        setDirty(false)
    }, [])

    return {
        values,
        errors,
        dirty,
        setValue,
        validate,
        clearErrors,
        clearDirty,
    }
}

function pickSchemaFields<T extends object>(obj: T, schema: yup.ObjectSchema<T>) {
    return _.pick(obj, Object.keys(schema.fields)) as any
}

export function useValidatedAsyncFn(
    asyncFn: UseAsyncFn,
    validate: () => Map<string, string> | null,
    showWarningNotification?: boolean
): UseAsyncFn {
    const callFn = useCallback(() => {
        const errors = validate()
        if (errors) {
            const [key, value] = [...errors.entries()][0]
            if (showWarningNotification) {
                showNotification({
                    messageType: 'warning',
                    message: 'Invalid data. Check your input.',
                    details: `${key}: ${value}`,
                })
            }
        } else {
            if (!asyncFn.processing) {
                asyncFn.callFn()
            }
        }
    }, [asyncFn, validate, showWarningNotification])
    return { callFn, processing: asyncFn.processing }
}
