// Taken from: https://brandoncantello.medium.com/using-history-to-navigate-your-react-app-from-outside-a-component-40ea74ba4402
import { createBrowserHistory } from 'history'

import { logger } from './client-logger.js'

export const hist = createBrowserHistory()

interface NavigateToOptions {
    replace?: boolean
}

export function navigateTo(path: string | number, options?: NavigateToOptions): void {
    logger.debug('Navigating to', { path, options })
    let absolutePath = String(path)
    if (typeof path === 'number' || !path.startsWith('/')) {
        absolutePath = `${hist.location.pathname}/${path}`
    }
    if (options?.replace) {
        hist.replace(absolutePath)
    } else {
        hist.push(absolutePath)
    }
}

export function goBack(): void {
    const historyStackEmpty = hist.location.key === 'default'
    // https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
    if (historyStackEmpty) {
        navigateTo('/')
    } else {
        hist.back()
    }
}
